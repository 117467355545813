
import { useEffect, useState } from "react";
import { baseUrl, errorNotify, getFromCookie } from "./Helper";
import errImg from "../assets/product-default-img/dummy.png";
import bannerImg from "../assets/product-default-img/banner3.png";

// added
const useData = () => {
  const [domain, setDomain] = useState("");
  const [apiKey, setApiKey] = useState("");

  const [pageName, setPageName] = useState("");
  const [compare, setCompare] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  //token
  const [custommerToken, setCustommerToken] = useState("");
  const [secondaryToken, setSecondaryToken] = useState("");
  const [refreshAll, setRefreshAll] = useState(false);

  const [addresses, setAddresses] = useState([]);
  const [productDetails, setProductDetails] = useState();
  const [darkMode, setDarkMode] = useState(false);
  // address states
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState(null);
  const [divisions, setDivisions] = useState([]);
  const [divisionId, setDivisionId] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState(null);
  const [subDistricts, setSubDistricts] = useState([]);
  const [subDisId, setSubDisId] = useState(null);
  // const [unions, setUnions] = useState([]);
  const [setting, setSetting] = useState({});

  const selectedDivisions = divisions?.filter(
    (div) => +div.country_id === +countryId
  );
  const selectedDistricts = districts?.filter(
    (dis) => +dis.division_id === +divisionId
  );
  const selectedSubDis = subDistricts?.filter(
    (subDis) => +(subDis.district_id === +districtId)
  );
  // const selectedUnions = unions?.filter((uni) => +uni.upazila_id === +subDisId);

  // filter products state
  const [filteredProducts, setFilteredProducts] = useState();
  const [categoryFilteredProducts, setCategoryFilteredProducts] = useState([]);
  const [viewAllFlashSell, setViewAllFlashSell] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [featureCat, setFeatureCat] = useState(0);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState("");
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [productName, setProductName] = useState("");

  //compare
  const [compareProducts, setCompareProducts] = useState([]);
  // products
  const [productReviews, setProductReviews] = useState([]);

  // error image function
  const showDummyImage = ({ currentTarget }) => (currentTarget.src = errImg);
  const showDummyBanner = ({ currentTarget }) =>
    (currentTarget.src = bannerImg);

  // frontend configuration
  useEffect(() => {
    const fav = document.getElementById("favIcon");
    document.title = setting?.name ? setting?.name : "Selopia";
    fav.href = `${baseUrl.img}${setting?.favicon}`;
  }, [setting?.favicon, setting?.name]);

  useEffect(() => {
    if (!baseUrl?.token) {
      return;
    }
    const getSettings = async () => {
      const res = await fetch(`${baseUrl.url}/general-setting`, {
        method: "GET",
        headers: {
          Authorization: baseUrl?.token,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setSetting(resData?.data);
      }
    };
    getSettings().then();
  }, []);

  // address url fetch start-------------------------------------------------------------------------------
  useEffect(() => {
    if (!baseUrl.token) {
      return;
    }
    if (!pageName || pageName !== "address") {
      return;
    }

    const getCountry = async () => {
      const res = await fetch(`${baseUrl.url}/asset/country-list`, {
        method: "GET",
        headers: {
          Authorization: baseUrl.token,
        },
      });
      const countryData = await res.json();
      if (res.status === 200) {
        setCountries(countryData.data);
      }
    };
    getCountry();
  }, [pageName]);

  useEffect(() => {
    if (!baseUrl.token) {
      return;
    }
    if (!pageName || pageName !== "address" || !countryId) {
      return;
    }
    const getDivision = async () => {
      const res = await fetch(
        `${baseUrl.url}/asset/division-list?country_id=${countryId}`,
        {
          method: "GET",
          headers: {
            Authorization: baseUrl.token,
          },
        }
      );
      const divisionData = await res.json();
      if (res.status === 200) {
        setDivisions(divisionData.data);
      }
    };
    getDivision();
  }, [pageName, countryId]);
  useEffect(() => {
    setDistricts([]);
    if (!baseUrl.token) {
      return;
    }
    if (!pageName || pageName !== "address" || !divisionId) {
      return;
    }

    const getDistrict = async () => {
      const res = await fetch(
        `${baseUrl.url}/asset/district-list?division_id=${divisionId}`,
        {
          method: "GET",
          headers: {
            Authorization: baseUrl.token,
          },
        }
      );
      const districtData = await res.json();
      if (res.status === 200) {
        setDistricts(districtData.data);
      }
    };
    getDistrict();
  }, [pageName, divisionId]);
  useEffect(() => {
    setSubDistricts([]);
    if (!baseUrl.token) {
      return;
    }
    if(!districtId){
      return
    }
    if (!pageName || pageName !== "address") {
      return;
    }
    const getSubDistrict = async () => {
      const res = await fetch(
        `${baseUrl.url}/asset/sub-district-list?district_id=${districtId}`,
        {
          method: "GET",
          headers: {
            Authorization: baseUrl.token,
          },
        }
      );
      if (res.status === 200) {
        const subDistrictData = await res.json();
        setSubDistricts(subDistrictData.data);
      }
    };
    getSubDistrict();
  }, [pageName, districtId, divisionId]);
  // useEffect(() => {
  //   setUnions([]);
  //   if (!baseUrl.token) {
  //     return;
  //   }
  //   if(!subDisId){
  //     return
  //   }
  //   if (!pageName || pageName !== "address") {
  //     return;
  //   }
  //   const getUnion = async () => {
  //     const res = await fetch(
  //       `${baseUrl.url}/asset/union-list?sub_district_id=${subDisId}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: baseUrl.token,
  //         },
  //       }
  //     );
  //     const unionData = await res.json();
  //     if (res.status === 200) {
  //       setUnions(unionData.data);
  //     }
  //   };
  //   getUnion();
  // }, [pageName, subDisId]);
  // address url fetch end---------------------------------------------------------------------------------

  // user address details
  const [updateAddress, setUpdateAddress] = useState(false);
  useEffect(() => {
    if (!custommerToken) {
      return;
    }
    const getUserDetails = async () => {
      const res = await fetch(`${baseUrl.url}/user/address-list`, {
        headers: {
          Authorization: `bearer ${custommerToken}`,
        },
      });
   
      if (res.status === 200) {
        const userData = await res.json();
        setAddresses(userData.addresses);
      } else {
        setAddresses([]);
      }
    };
    getUserDetails();
  }, [custommerToken, updateAddress]);
  const [userLoader, setUserLoader] = useState(true);

  useEffect(() => {

    const refresh = async () => {
      try {
        const login = await getFromCookie("isLogin");
        if (login === undefined || +login === 0 || login === "" || +login !== 1) {
          setUserLoader(false)
          return;
        }
        const res = await fetch(`${baseUrl.url}/user/refresh`, {
          method: "GET",
          credentials: "include",
          headers: {
            "content-type": "application/json",
          },
        });
        const resData = await res.json();
        if (resData.status !== false) {
          setCustommerToken(resData.customer_access_token);
        } else {
          setCustommerToken("");
          setUserLoader(false);
        }
        setUserLoader(false);
      } catch (error) {
        console.error(error);
        setUserLoader(false);
      } finally {
        setUserLoader(false);
      }
    };
    refresh();
  }, [setCustommerToken,setUserLoader]);

  const [customerInfo, setCustommerInfo] = useState({});
  const [update, setUpdate] = useState(false);
  useEffect(() => {
    if (!custommerToken) {
      return;
    }

    const getCustommerInfo = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/user/me`, {
          method: "GET",
          headers: {
            Authorization: `bearer ${custommerToken}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setCustommerInfo(resData.data);
        }
      } catch (error) {
        errorNotify(error)
      }
    }
    
    getCustommerInfo();
  }, [custommerToken, update,setUpdate]);
  return {
    customerInfo,
    userLoader,
    setUserLoader,
    secondaryToken,
    setCustommerToken,
    custommerToken,
    setUpdate,
    update,
    addresses,
    setAddresses,
    countries,
    setCountryId,
    setDivisionId,
    setDistrictId,
    setSubDisId,
    subDisId,
    // selectedUnions,
    selectedDivisions,
    selectedDistricts,
    selectedSubDis,
    setting,
    filteredProducts,
    setFilteredProducts,
    categoryFilteredProducts,
    setCategoryFilteredProducts,
    userInfo,
    setUserInfo,
    showDummyImage,
    viewAllFlashSell,
    setViewAllFlashSell,
    featureCat,
    setFeatureCat,
    discount,
    setDiscount,
    categoryId,
    setCategoryId,
    subCategoryId,
    setSubCategoryId,
    setPageName,
    productReviews,
    setProductReviews,
    refreshAll,
    setRefreshAll,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    productName,
    setProductName,
    updateAddress,
    setUpdateAddress,
    divisions,
    districts,
    subDistricts,
    // unions,
    showDummyBanner,
    compareProducts,
    setCompareProducts,
    productDetails,
    setProductDetails,
    compare,
    setSecondaryToken,
    setCompare,
    darkMode, 
    setDarkMode,
    domain,
    setDomain,
    apiKey,
    setApiKey
  };
};

export default useData;
