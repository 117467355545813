import React from "react";
import ProductCard6 from "../../Cards/ProductCard6/ProductCard6";
import SectionHeading5 from "../../SectionHeadings/SectionHeading5/SectionHeading5";

const DiscountProduct6 = ({ data,flash }) => {
  const data2 = {
    subheading: "Here is our",
    heading: "Savings Galore",
    paragraph: "Discover Our Discounted Treasures!",
  };
  return (
    <section className="py-8 lg:py-16">
      {data?.length > 0 && (
        <div className="container">
          <SectionHeading5 data={data2} />
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-3">
            {data?.length > 0 &&
              data
                ?.slice(0, 8)
                ?.map((product, i) => <ProductCard6 key={i} data={product} flash={product.is_on_sale===1 ? flash : null}/>)}
          </div>
        </div>
      )}
    </section>
  );
};

export default DiscountProduct6;
