import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl, errorNotify, successNotify } from "../../../Hooks/Helper";
import { AiOutlineMail } from "react-icons/ai";
import Button from "../../Button/Button";
const Subscription = ({data}) => {
  const [email, setEmail] = useState("");
  const backgroundStyle = {
    backgroundImage: data?.newsletter_image ? `url(${baseUrl.img}/${data?.newsletter_image})` :"url('https://placehold.co/1344x307')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const handlesubs = async () => {
    if (email.length < 7 || !email.includes("@")) {
      errorNotify("Email is required");
      return;
    }
    const formData = new FormData();
    formData.append("email", email);
    const res = await fetch(`${baseUrl.url}/subscribe`, {
      method: "POST",
      headers: {
        Authorization: baseUrl.token,
      },
      body: formData,
    });
    const resData = await res.json();
    if (resData.status) {
      successNotify("Email subscribed successfully");
      setEmail("")
    } else {
      errorNotify(resData?.errors[0]);
    }
  };

  return (
    <div className="py-8 lg:py-16">
      <ToastContainer
        position="bottom-right"
        autoClose={50000}
        hideProgressBar={false}
        className="shadow-none"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="container">
        <div className=" w-full h-[307px] lg:h-[307px]  flex justify-center items-center bg-slate-100 dark:bg-gray-800" style={backgroundStyle}>
          <div>
            <div>
              <h1 className="sm:text-3xl text-xl font-bold text-black  mb-2 text-center">
              Never <span className="mx-1 text-yellow-500">Miss </span> a Beat
                
              </h1>
              <p className="text-center text-sm font-medium text-black  ">
                 
              Subscribe to Our Newsletter for Exclusive Updates
              </p>
            </div>

            <div
             
              className="sm:w-[543px] w-[300px] h-[54px] flex mt-8"
            >
              <div className="flex-1 bg-white pl-4 flex space-x-2 items-center h-full focus-within:text-yellow text-black">
                <span>
                  <AiOutlineMail size={20} />
                </span>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  value={email}
                  className="w-full h-full focus:outline-none text-sm placeholder:text-xs placeholder:text-black text-black font-normal tracking-wider"
                  placeholder="Type Your Email Address"
                />
              </div>
              {/* <button
                onClick={handlesubs}
                type="button"
                className="sm:w-[158px] w-[80px]  h-full bg-yellow-500 text-sm font-bold"
              >
                Subscribe
              </button> */}
              <Button  size={"md"} title={"Subscribe"} func={handlesubs}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
