import React from "react";
import useStore from "../../../Hooks/useStore";
import { Link } from "react-router-dom";
import { GrLinkedinOption } from "react-icons/gr";
import { BsInstagram, BsPinterest } from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";


const Footer5 = () => {
  const { setting } = useStore().data;
  const {
    facebook,
    instagram,
    twitter,
    youtube,
    pinterest,
    linkedin,
    name,
  } = setting;
  const newTabWithLink = (link) => {
    window.open(link);
  };
  return (
    <div>
      <div className="py-8 lg:py-16">
        <div className="container">
          <nav className="flex justify-center flex-wrap space-x-5 text-gray-500  font-normal">
            <Link to={"/"} className="hover:text-orange-900">
              Home
            </Link>
            <Link to={"/products"} className="hover:text-orange-900">
              Products
            </Link>
            <Link to={"/faq"} className="hover:text-orange-900">
           Faq
            </Link>
            <Link to={"/contact"} className="hover:text-orange-900">
              Contact us
            </Link>
            <Link to={"/about"} className="hover:text-orange-900">
              About Us
            </Link>
          </nav>
          <span className="flex justify-center text-sm text-slate-400">
          Your Fashionable Desires, Our Priority: We're Always Ready to Serve Your Unique Style Choices!
          </span>
        </div>
      </div>
      <div className="py-8 lg:py-16 bg-[#252525] dark:bg-gray-950">
        <div className="container">
          <div className="flex  flex-wrap items-center justify-between px-2 mx-6">
            <div className="">
              <p className="text-sm text-slate-300">
                Copyright &copy;2023{" "}
                <span className="">{name}</span>.All Rights
                Reservered.Powered by <span className="text-red-400">Selopia</span>.
              </p>
            </div>

            <div className="flex gap-3 cursor-pointer text-slate-300 mt-3 lg:mt-0">
              <FaFacebookF
                size={20}
                onClick={() => newTabWithLink(facebook)}
                className="hover:text-red-400"
              />

              <BsInstagram
                size={20}
                onClick={() => newTabWithLink(instagram)}
                className="hover:text-red-400"
              />

              <FiTwitter
                size={20}
                onClick={() => newTabWithLink(twitter)}
                className="hover:text-red-400"
              />

              <AiOutlineYoutube
                size={20}
                onClick={() => newTabWithLink(youtube)}
                className="hover:text-red-400"
              />

              <GrLinkedinOption
                size={20}
                onClick={() => newTabWithLink(linkedin)}
                className="hover:text-red-400"
              />

              <BsPinterest
                size={20}
                onClick={() => newTabWithLink(pinterest)}
                className="hover:text-red-400"
              />
            </div>

            <div className=" mt-3 lg:mt-0">
              <img
                src="https://demo2.wpopal.com/ekommart/wp-content/uploads/2020/02/payment.png"
                className="w-full h-full"
                alt="payment"
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Footer5;
