import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { baseUrl, errorNotify, successNotify } from "../../../Hooks/Helper";
import useStore from "../../../Hooks/useStore";
import { IoCallOutline } from "react-icons/io5";
import Button from "../../Button/Button";
const Subscription2 = () => {
  const [email, setEmail] = useState("");
  const { setting } = useStore().data;
  const handlesubs = async () => {
    if (email.length < 7 || !email.includes("@")) {
      errorNotify("Email is required");
      return;
    }
    const formData = new FormData();
    formData.append("email", email);
    const res = await fetch(`${baseUrl.url}/subscribe`, {
      method: "POST",
      headers: {
        Authorization: baseUrl.token,
      },
      body: formData,
    });
    const resData = await res.json();
    if (resData.status) {
      successNotify("Email subscribed successfully");
      setEmail("");
    } else {
      errorNotify(resData?.errors[0]);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={50000}
        hideProgressBar={false}
        className="shadow-none"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="bg-slate-100 dark:bg-slate-800 py-4 lg:py-16">
        <div className="container mx-auto">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-7">
              <h3 className="font-bold text-xl lg:text-2xl dark:text-gray-200">
              The Newsletter Nexus
              </h3>
              <div className="mt-3 grid grid-cols-12 w-full lg:w-6/12">
                <div className=" bg-white dark:bg-gray-900 pl-4  flex justify-center items-center h-full focus-within:text-yellow text-black col-span-9">
                  <input
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="focus:outline-none text-base placeholder:text-xs  text-gray-500 font-normal  bg-transparent "
                    placeholder="Type Your Email Address"
                  />
                </div>
                {/* <button
                  type="button"
                  className="px-4 py-4 bg-black text-white text-xs font-bold flex justify-center items-center col-span-3"
                  onClick={handlesubs}
                >
                  Subscribe
                </button> */}
                  <Button  size={"md"} title={"Subscribe"} func={handlesubs} classes={'col-span-3'}/>
              </div>
              <span className="font-normal text-xs text-slate-700 dark:text-slate-100">
                [Your Gateway to Our Latest News and Events]
              </span>
            </div>
            <div className="col-span-12 lg:col-span-5">
              <h3 className="font-bold text-lg dark:text-white">Need help ?</h3>
              <h3 className="font-bold text-lg dark:text-white flex items-center">
                <IoCallOutline className="mr-2"/> {setting?.phone}
              </h3>
              <span className="font-normal text-sm text-slate-700 dark:text-slate-100">
                We are available 8.00 am -7.00pm
              </span>
              <div className="flex gap-3 mt-3">
                <img
                  alt="google play store"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1200px-Google_Play_Store_badge_EN.svg.png"
                  className="w-30 h-10"
                />
                <img
                  alt="google play store"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1200px-Google_Play_Store_badge_EN.svg.png"
                  className="w-30 h-10"
                />
              </div>
              <span className="font-normal text-xs text-slate-700 dark:text-slate-100">
                Shopping App:Try our View in Your Room feature manage registers
                and save payment info
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription2;
