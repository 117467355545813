import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/bundle";
import { baseUrl } from '../../../Hooks/Helper';
import SectionHeading3 from '../../SectionHeadings/SectionHeading3/SectionHeading3';
const Sponsor3 = ({data}) => {
  return (
    <section className="py-8 lg:py-16">
    <div className="container">
    <SectionHeading3 title={'Meet Our Valued Sponsors'} text={'Partners in Success'} />
      <div className='mt-3'>
        <Swiper
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
           autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
        loop={true}
        modules={[ Autoplay]}
        >
          {data?.length > 0 ? (
              data.map((sponsor, i) => (
                <SwiperSlide key={i}>
                  <div className=" h-[126px] w-full">
                    <img
                      src={
                        sponsor?.image
                          ? `${baseUrl.img}/${sponsor.image}`
                          : "https://placehold.co/210x126"
                      }
                      alt="slider"
                      className="w-auto h-full mx-auto"
                    />
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <div className=" h-[126px] w-full">
                  <img
                    src="https://placehold.co/210x126"
                    alt="placeholder"
                    className="w-auto h-full mx-auto"
                  />
                </div>
              </SwiperSlide>
            )}
        </Swiper>
      </div>
    </div>
  </section>
  )
}

export default Sponsor3