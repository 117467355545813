import React from "react";
import { baseUrl } from "../../Hooks/Helper";
import ProductCard from "../Cards/ProductCard/ProductCard";
import SideImage from "../SideImage/SideImage";
const PopularProducts = ({ data, site_banners,flash }) => {
  const text =
    " A Trendsetter that Exceeds Expectations. Join the Ranks of Delighted Customers Who Have Made It Their Ultimate Pick. Secure Yours Today and Elevate Your Experience to Unprecedented Excellence.";
  return (
    <section className="py-8 lg:py-16">
      {data?.length > 0 && (
        <div className="container">
          <div className="grid grid-cols-12 gap-4 mt-4">
            <SideImage
              image={
                site_banners?.popular_product_image1
                  ? `${baseUrl.img}/${site_banners?.popular_product_image1}`
                  : "https://placehold.co/243x306"
              }
              data={data}
              title={"Popular Product"}
              text={text}
              background={"bg-white dark:bg-gray-800"}
            />
            <div className="col-span-12 lg:col-span-9">
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-1 lg:gap-4">
                {data?.slice(0, 8).map((product, i) => (
                  <div key={i}>
                    <ProductCard product={product} flash={product.is_on_sale===1 ? flash : null} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PopularProducts;
