import React from 'react'
import ProductContainer2 from '../../ProductContainers/ProductContainer2/ProductContainer2'
import SectionHeading2 from '../../SectionHeadings/SectionHeading2/SectionHeading2'
// import ProductContainer2 from '../../ProductContainer2/ProductContainer2'

const PopularProducts2 = ({data,flash}) => {
    const heading = {
        heading:"Demanding Excellence",
        paragraph:"Discover What's Trending Now",
        show:data?.length >7 ?true :false
    }
  return (
    <section className='py-8 lg:py-16'>
        <div className="container">
            <SectionHeading2 data={heading}/>
        </div>
        <ProductContainer2 data={data} flash={flash}/>
    </section>
  )
}

export default PopularProducts2