import React from "react";
import SectionHeading4 from "../../SectionHeadings/SectionHeading4/SectionHeading4";
import ProductCard5 from "../../Cards/ProductCard5/ProductCard5";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

const FeatureProduct5 = ({ data,flash }) => {
  const navigate=useNavigate();
  const data2 = {
    subheading: "Here is our",
    heading: "Featured products",
    paragraph: "Products That Capture Hearts",
  };
  return (
    <section className="py-8 lg:py-16">
      <div className="container">
        <SectionHeading4 data={data2} />
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-3 ">
          {data?.length > 0 &&
            data?.slice(0,4)?.map((product, i) => (
              <ProductCard5 key={i} data={product} flash={product.is_on_sale===1 ? flash : null} />
            ))}
        </div>
        {
        data?.products?.length > 7 && <div className='text-center'>
        <button  onClick={()=>navigate("/products")} className="py-3 px-6 bg-gray-100 hover:bg-gray-200 transition-all duration-300"><span className='flex items-center'>View all <BsArrowRight className='ml-2'/></span></button>
    </div>
      }
      </div>
    </section>
  );
};

export default FeatureProduct5;
