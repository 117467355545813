import React from 'react';
import ProductContainer4 from '../../ProductContainers/ProductContainer4/ProductContainer4';
import SectionHeading3 from '../../SectionHeadings/SectionHeading3/SectionHeading3';

const NewArrival3 = ({data,flash}) => {

    return (
        <section className='py-8 lg:py-16'>
            <div className="container mb-3">
                <SectionHeading3 title={'New Horizons'} text={'Must-Have Products for the Modern Era'}/>
            </div>
            <ProductContainer4 data={data} flash={flash}/>
        </section>
    );
};

export default NewArrival3;