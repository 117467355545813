import React, { useEffect } from "react";
import CartContainer from "../../Components/PageContainers/CartContainer/CartContainer";
// import Subscription from "../../Components/Subscriptions/Subscription/Subscription";
import { useDispatch} from "react-redux";
// import {  useSelector } from "react-redux";
import { fetchHomeData } from "../../Redux/Slices/rootSlice/rootSlice";

const Cart = () => {
  const dispatch = useDispatch();
  // const { data} = useSelector((state) => state.home);
  useEffect(() => {
 
    dispatch(fetchHomeData());
    
  }, [dispatch]);
  return <>
  <CartContainer />
  {/* <Subscription data={data?.site_banners}/> */}
  </>;
};

export default Cart;
