import React from "react";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import "./FlashSale2.css";
import ProductCard2 from "../../ProductContainers/ProductContainer2/ProductCard2/ProductCard2";
import CoutDown from "../../CountDowns/CoutDown/CoutDown";
const FlashSale2 = ({ data, flash }) => {
  const heading = {
    heading: "Flashsale Products",
    paragraph: "Unmasking Irresistible Deals",
    show:data?.length >7 ?true :false
  };
  const endDate = new Date(flash?.end_date);
  return (
    <section className="py-8 lg:py-16">
      {data?.length > 0 && (
        <div className="container">
          <SectionHeading2 data={heading} />
          <div className="w-full lg:w-3/12 mt-4 mb-2">
            <CoutDown endDate={endDate} />
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
            {data?.slice(0, 8)?.map((product, i) => (
              <ProductCard2
                key={i}
                product={product}
                flash={flash ? flash : null}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default FlashSale2;
