import React from "react";
import ProductCard4 from "../../Cards/ProductCard4/ProductCard4";
import SectionHeading from "../../SectionHeadings/SectionHeading/SectionHeading";

const DiscountProducts4 = ({ data,flash }) => {
  const data2 = {
    heading: "Discount Delights",
    paragraph: "Elevate Your Shopping Experience.",
    show:data?.length >7 ?true :false
  };
  return (
    <section className="py-8 lg:py-16">
      {data?.length > 0 && (
        <div className="container">
          <SectionHeading data={data2} />
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-3">
            {data?.slice(0, 10)?.map((item, i) => (
              <ProductCard4 key={i} data={item} flash={item.is_on_sale===1 ? flash : null} />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default DiscountProducts4;
