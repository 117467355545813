import React from "react";
import useStore from "../../../Hooks/useStore";
import { baseUrl } from "../../../Hooks/Helper";
import { Link } from "react-router-dom";
import { GrLinkedinOption } from "react-icons/gr";
import { BsInstagram, BsPinterest } from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";

const Footer4 = () => {
  const { setting,darkMode } = useStore().data;
  const {
    facebook,
    instagram,
    twitter,
    youtube,
    pinterest,
    linkedin,
    name,
    logo,
    dark_logo
  } = setting;
  const newTabWithLink = (link) => {
    window.open(link);
  };
  return (
    <div className="py-8 lg:py-16">
      <footer className="flex flex-col justify-center">
        <Link
          to={"/"}
          className="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white"
        >
         <div  className="lg:w-[192px] lg:h-[106px]">
                        {dark_logo || logo ? (
                          <img
                            className={"w-auto h-full"}
                            src={
                              darkMode
                                ? `${baseUrl.img}${dark_logo}`
                                : `${baseUrl.img}${logo}`
                            }
                            alt={"website logo"}
                          />
                        ) : (
                          <img
                            className={"w-4/4 lg:w-48 object-cover"}
                            src="https://placehold.co/192x40"
                            alt={"website logo"}
                          />
                        )}
                      </div >
                    
        </Link>
        <nav className="flex justify-center flex-wrap space-x-5 text-gray-500 font-normal dark:text-white">
          <Link to={"/"} className="hover:text-red-400">
            Home
          </Link>
          <Link to={"/products"} className="hover:text-red-400">
            Products
          </Link>
          <Link to={"/faq"} className="hover:text-red-400">
            Faq
          </Link>
          <Link to={"/contact"} className="hover:text-red-400">
            Contact us
          </Link>
        </nav>

        <div className="text-center mb-2 cursor-pointer">
          <h5 className="dark:text-white">Follow us</h5>
          <div className="flex items-center justify-center mt-1">
            <div className="flex">
              <button
                style={{ height: "25px", width: "25px" }}
                onClick={() => newTabWithLink(facebook)}
                className="footer_icon_hover rounded-full mx-2 border-0 bg-blue-600 text-white flex items-center justify-center"
              >
                <FaFacebookF size={12} />
              </button>
              <button
                style={{ height: "25px", width: "25px" }}
                onClick={() => newTabWithLink(instagram)}
                className="footer_icon_hover rounded-full mx-2 border-0 bg-red-600 text-white flex items-center justify-center"
              >
                <BsInstagram size={12} />
              </button>
              <button
                style={{ height: "25px", width: "25px" }}
                onClick={() => newTabWithLink(twitter)}
                className="footer_icon_hover rounded-full mx-2 border-0 bg-cyan-600 text-white flex items-center justify-center"
              >
                <FiTwitter size={12} />
              </button>
              <button
                style={{ height: "25px", width: "25px" }}
                onClick={() => newTabWithLink(youtube)}
                className="footer_icon_hover rounded-full mx-2 border-0 bg-red-600 text-white flex items-center justify-center"
              >
                <AiOutlineYoutube size={12} />
              </button>
              <button
                style={{ height: "25px", width: "25px" }}
                onClick={() => newTabWithLink(linkedin)}
                className="footer_icon_hover rounded-full mx-2 border-0 bg-blue-600 text-white flex items-center justify-center"
              >
                <GrLinkedinOption size={12} />
              </button>
              <button
                style={{ height: "25px", width: "25px" }}
                onClick={() => newTabWithLink(pinterest)}
                className="footer_icon_hover rounded-full mx-2 border-0 bg-red-600 text-white flex items-center justify-center"
              >
                <BsPinterest size={12} />
              </button>
            </div>
          </div>
        </div>
        <p className="text-center text-gray-700 font-normal text-sm p-2 dark:text-white">
        Copyright 2023 © <span className="">{name}</span> All rights reserved. Powered by <span className="text-red-400">Selopia</span>.
        </p>
      </footer>
    </div>
  );
};

export default Footer4;
