import React, { useEffect, useState } from "react";
import useStore from "../../Hooks/useStore";
import { AiFillStar } from "react-icons/ai";
import parse from "html-react-parser";
import { baseUrl, convertedDate } from "../../Hooks/Helper";
import { BsReplyAll, BsShieldFillCheck } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
const ProductDetailsInfo = () => {
  const [selectedTab, setSelectedTab] = useState("Additional info");
  const { productDetails, showDummyImage } = useStore().data;
  const [productReviews, setProductReview] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    setting,

    darkMode,
  } = useStore().data;

  useEffect(() => {
    const getReviews = async () => {
      const res = await fetch(`${baseUrl.url}/product-reviews/${id}`, {
        method: "GET",
        headers: {
          Authorization: baseUrl.token,
        },
      });

      if (res.ok && res.status !== 204) {
        const resData = await res.json();
        setProductReview(resData.data);
      }
    };
    getReviews();
  }, [id]);

  return (
    <div>
      <div className="tab-btn-container">
        {["Additional info", `Reviews`].map((heading, i) => {
          return (
            <button
              key={i}
              onClick={() => setSelectedTab(heading)}
              className={`py-1 px-2 mr-2 rounded ${
                selectedTab === heading
                  ? "font-bold text-red-400"
                  : "text-slate-800 dark:text-white"
              }`}
            >
              {heading} {i === 1 && `(${productReviews?.data?.length || 0})`}
            </button>
          );
        })}
      </div>
      <div className="tab-body-holder">
        {selectedTab === "Additional info" && (
          <div className="">
            <div className="p-4">
              <div className="mt-2">
                <h2 className="text-lg font-bold dark:text-white border-b">
                  Details
                </h2>
                <p className="dark:text-slate-200 text-sm my-3">
                  {productDetails?.short_description}
                </p>
              </div>
              <div className="mt-4">
                <h2 className="text-lg font-bold dark:text-white border-b">
                  Description
                </h2>
                <p className="dark:text-white text-sm">
                  {parse(`${productDetails?.description}`)}
                </p>
              </div>
            </div>
          </div>
        )}
        {selectedTab === "Reviews" && (
          <div>
            {productReviews?.data?.length > 0 ? (
              <div className="p-4">
                {productReviews?.data?.map((reviews, i) => {
                  const {
                    review,
                    rating,
                    created_at,
                    images,
                    order_item,
                    reply_from_merchant,
                  } = reviews;
                  const date = convertedDate(created_at);
                  return (
                    <div key={i} className="py-2">
                      <div className="flex items-center">
                        <div className="flex items-center">
                          <div className="w-12 h-12 rounded-full shadow overflow-hidden">
                            <img
                              onError={({ currentTarget }) =>
                                showDummyImage({ currentTarget })
                              }
                              src={`${baseUrl.img}${order_item?.order?.user?.profile?.image}`}
                              alt="review user"
                              className="w-full h-full"
                            />
                          </div>
                          <div className="ml-2">
                            <span className="fw-bold font-semibold dark:text-white">
                              {order_item?.order?.user?.name}
                            </span>
                            <span className="text-xs flex items-center dark:text-white">
                              <BsShieldFillCheck
                                className="text-green-500 mr-1"
                                size={15}
                              />{" "}
                              Verified user
                            </span>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <span className="flex my-1">
                            {Array.from({ length: 5 }).map((_, i) => (
                              <AiFillStar
                                size={15}
                                key={i}
                                className={
                                  i >= Math.round(rating)
                                    ? "text-black"
                                    : "text-yellow-500"
                                }
                              />
                            ))}
                          </span>
                          <i className="text-sm text-slate-500 dark:text-white">
                            {date}
                          </i>
                        </div>
                      </div>
                      <div>
                        <p className="w-8/12 text-sm dark:text-slate-200">
                          {review}
                        </p>
                        {images?.length > 0 && (
                          <div className="w-40 grid grid-cols-3 gap-2 my-4">
                            {images?.map((img, i) => (
                              <img
                                key={i}
                                className="h-12 bg-slate-100 rounded cursor-pointer border"
                                src={`${baseUrl.img}${img?.image}`}
                                alt=""
                              />
                            ))}
                          </div>
                        )}
                      </div>
                      {reply_from_merchant?.length > 0 && (
                        <div className="w-full lg:w-8/12 bg-gray-100 p-2 mt-3 shape dark:bg-slate-800">
                          <div className="flex items-center mb-4">
                            <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center shadow overflow-hidden">
                              <div
                                onClick={() => {
                                  navigate("/");
                                }}
                                className=" w-16 h-16"
                              >
                                {setting?.dark_logo || setting?.logo ? (
                                  <img
                                    className={" h-full w-auto"}
                                    src={
                                      darkMode
                                        ? `${baseUrl.img}${setting?.dark_logo}`
                                        : `${baseUrl.img}${setting?.logo}`
                                    }
                                    alt={"website logo"}
                                  />
                                ) : (
                                  <img
                                    className={" h-full w-auto"}
                                    src="https://placehold.co/160x64"
                                    alt={"website logo"}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="ml-2">
                              <span className="fw-bold font-semibold dark:text-white">
                                Admin
                              </span>
                              <span className="text-xs flex items-center dark:text-white">
                                <BsReplyAll
                                  className="mr-1 text-red-400"
                                  size={15}
                                />{" "}
                                replied by{" "}
                                <span className="text-red-400 ml-1 font-bold">
                                  {setting?.name}
                                </span>
                              </span>
                            </div>
                          </div>
                          <p className="text-sm dark:text-white">
                            {reply_from_merchant}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className=" p-5 m-2">
                <h5 className="m-0 font2 font-style1 dark:text-white">
                  No review added yet
                </h5>
                <p className="font3 secondary-color dark:text-white">
                  Purchase this product to add review
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsInfo;
