import React, { useEffect, useState } from "react";
import { TfiLayoutColumn3, TfiLayoutGrid4 } from "react-icons/tfi";
import { RxCross1 } from "react-icons/rx";
import { BsGrid3X3Gap, BsListColumnsReverse } from "react-icons/bs";
import { FcFilledFilter } from "react-icons/fc";
import { IoIosRadioButtonOn, IoIosSearch } from "react-icons/io";
import Paginate from "../../Paginate/Paginate";
import { baseUrl, sorting } from "../../../Hooks/Helper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllcategories } from "../../../Redux/Slices/category/categoriesSlice";
import {
  setLoaderAll,
  setPaginate,
} from "../../../Redux/Slices/Product/allProductsSlice";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import AllProductLoader from "../../Loaders/AllProductLoader/AllProductLoader";
import useStore from "../../../Hooks/useStore";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import BreadCumb from "../../BreadCumb/BreadCumb";
import { IoGridOutline, IoRadioButtonOff } from "react-icons/io5";
import SiteBanner from "./SiteBanner";
const AllProductContainer = ({ flash}) => {
  const {
    filteredProducts,
    setFilteredProducts,
    viewAllFlashSell,
    featureCat,
    discount,
    categoryId,
    setCategoryId,
    subCategoryId,
    setSubCategoryId,
    refreshAll,
    setRefreshAll,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    productName,
    setProductName,
  } = useStore().data;
  const dispatch = useDispatch();

  const allCategories = useSelector((state) => state?.categories?.categories);
  const { paginate, loading } = useSelector((state) => state?.allProducts);
  

  useEffect(() => {
    dispatch(fetchAllcategories());
    
  }, [dispatch]);

  const [grid, setGrid] = useState();
  const [filterOption, setFilterOption] = useState(false);
  const [subCategory, setSubCategory] = useState([]);
  const [sortBby, setShortBy] = useState("");
  const [handleMinPrice, setHandleMinPrice] = useState(null);
  const [handleMaxPrice, setHandleMaxPrice] = useState(null);
  const filter = () => {
    setMinPrice(handleMinPrice);
    setMaxPrice(handleMaxPrice);
  };

  useEffect(() => {
    const fetchFilterProducts = async () => {
      try {
        dispatch(setLoaderAll(true));
        let url = `${baseUrl.url}/product-filter?per_page=20`;
        if (
          categoryId ||
          sortBby ||
          viewAllFlashSell > 0 ||
          featureCat > 0 ||
          discount > 0 ||
          maxPrice > 0 ||
          minPrice > 0 ||
          productName.length > 0
        ) {
          if (productName) {
            url += `&search=${productName}`;
          }
          if (categoryId) {
            url += `&category_id=${categoryId}`;
          }
          if (subCategoryId) {
            url += `&sub_category_id=${subCategoryId}`;
          }
          if (minPrice && maxPrice) {
            url += `&min_price=${minPrice}`;
          }
          if (minPrice && maxPrice) {
            url += `&max_price=${maxPrice}`;
          }
          if (sortBby) {
            url += `&sort_by=${sortBby}`;
          }
          if (viewAllFlashSell) {
            url += `&flash_sell=${viewAllFlashSell}`;
          }
          if (featureCat) {
            url += `&is_featured=${featureCat}`;
          }
          if (discount) {
            url += `&discount_product=${discount}`;
          }
        }

        const res = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: baseUrl.token,
          },
        });

        if (res.ok) {
          if (res.status === 200) {
            const resData = await res.json();
            setFilteredProducts(resData.data.data);
            dispatch(setPaginate(resData.data.links));
          } else {
            setFilteredProducts([]);
            dispatch(setPaginate([]));
          }
        } else {
          setFilteredProducts([]);
          dispatch(setPaginate([]));
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoaderAll(false));
      }
    };
    fetchFilterProducts();
  }, [
    categoryId,
    setFilteredProducts,
    subCategoryId,
    sortBby,
    viewAllFlashSell,
    featureCat,
    discount,
    minPrice,
    maxPrice,
    productName,
    dispatch,
  ]);

  useEffect(() => {
    if (!categoryId) {
      return;
    }
    const getSubCategoryByCateId = async () => {
      try {
        const res = await fetch(
          `${baseUrl.url}/sub-category-list/${categoryId}`,
          {
            method: "GET",
            headers: {
              Authorization: baseUrl.token,
            },
          }
        );
        if (res.ok) {
          if (res.status === 200) {
            const resData = await res.json();
            setSubCategory(resData.data);
          } else {
            setSubCategory([]);
          }
        } else {
          console.error("Error:", res.status);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSubCategoryByCateId();
  }, [categoryId, setFilteredProducts, subCategoryId]);

  const pageData = async (url, pageNum) => {
    
    if (!url || !pageNum) {
      return;
    }
    dispatch(setLoaderAll(true));
    setFilteredProducts([]);
    if (categoryId || sortBby) {
      const res = await fetch(
        `${
          baseUrl.url
        }/product-filter?name=${""}&category_id=${categoryId}&sub_category_id=${subCategoryId}&brand_id[]=${""}&min_price=${""}&max_price=${""}&sort_by=${sortBby}&page=${pageNum}`,
        {
          method: "GET",
          headers: {
            Authorization: baseUrl.token,
          },
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setFilteredProducts(resData?.data?.data);
        dispatch(setPaginate(resData?.data?.links));

        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else {
      const res = await fetch(`${url}`, {
        method: "GET",
        headers: {
          Authorization: baseUrl.token,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setFilteredProducts(resData.data.data);
        dispatch(setPaginate(resData?.data?.links));
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        console.error("Error:", res.status);
      }
      dispatch(setLoaderAll(false));
    }
    dispatch(setLoaderAll(false));
  };

  let content = <AllProductLoader />;
  if (loading && !filteredProducts?.length) {
    content = <AllProductLoader />;
  } else if (filteredProducts?.length > 0 && !loading) {
    content = (
      <div
        className={`grid ${
          grid ? `grid-cols-${grid}` : "grid-cols-2  lg:grid-cols-4"
        } gap-4`}
      >
        {filteredProducts?.map((product, i) => (
          <div key={i}>
            <ProductCard product={product} flash={product.is_on_sale===1 ? flash : null} />
          </div>
        ))}
      </div>
    );
  } else if (!loading && filteredProducts?.length === 0) {
    content = (
      <div className="m-0 m-lg-5 p-5 flex items-center justify-center h-96">
        <div>
          <IoIosSearch className="ml-10 dark:text-white" size={30} />
          <h4 className="font2 font-style1 m-0 dark:text-white font-medium">
            No product found
          </h4>
          <p className="text-xs secondary-color dark:text-white">
            Search something else
          </p>
        </div>
      </div>
    );
  }

  const navigationData = [
    {
      page: "Home",
      route: "/",
    },
    {
      page: "Products",
      route: "/products",
    },
    {
      page: "All",
      route: "#",
    },
  ];

  return (
    <div className="pt-6">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="container">
        <SectionHeading2 data={{ heading: "All Products", prargraph: null }} />
        <BreadCumb data={navigationData} />
        <div className="bg-transparent lg:bg-white lg:dark:bg-gray-900">
          <SiteBanner />

          <div className="lg:flex lg:gap-4">
            <div
              className={`fixed lg:relative top-0 z-10 bg-gray-800 lg:bg-white dark:bg-gray-800 h-screen lg:h-fit w-10/12 lg:w-3/12 p-10 lg:p-4 ${
                filterOption ? "left-0" : "-left-full lg:left-0"
              }`}
            >
              <div>
                <div
                  onClick={() => setFilterOption(false)}
                  className="sm:block md:hidden text-end absolute top-0 right-0 p-4"
                >
                  <RxCross1 size={20} className="text-red-400 " />
                </div>
                <div className="pb-3">
                  <h6 className="text-slate-300 lg:text-slate-700 font-bold dark:text-white">
                    All Categories
                  </h6>
                  <div className="py-1">
                    <button
                      // onClick={() => {
                      //   setRefreshAll(!refreshAll);
                      //   setCategoryId(null);
                      //   setSubCategoryId("");
                      //   setSubCategory([]);
                      //   setProductName("");
                      // }}
                      className={`rounded-full m-1 bg-transparent flex items-center text-sm ${
                        !categoryId
                          ? "text-red-400 border-0"
                          : "border-0 text-white lg:text-black dark:text-white "
                      }`}
                    >
                      {!categoryId ? (
                        // <BsCheckSquareFill className="mr-1" />
                        <IoIosRadioButtonOn className="mr-1" size="20" />
                      ) : (
                        <IoRadioButtonOff
                          onClick={() => {
                            setCategoryId("");
                            setSubCategory([]);
                            setSubCategoryId("");
                            setHandleMinPrice("");
                            setHandleMaxPrice("");
                            setShortBy("");
                            dispatch(setPaginate(paginate));
                            setProductName("");
                            setRefreshAll(!refreshAll);
                            setFilterOption(false);
                          }}
                          className="mr-1"
                          size="20"
                        />
                      )}
                      All Products
                    </button>
                    {allCategories?.data?.map((cat, i) => (
                      <div key={i}>
                        <button
                          key={i}
                          onClick={() => {
                            setCategoryId(cat?.id);
                            setSubCategoryId("");
                            dispatch(setPaginate(paginate));
                            setFilterOption(false);
                          }}
                          className={`rounded-full m-1 bg-transparent flex items-center text-sm ${
                            categoryId === cat.id
                              ? "text-red-400 border-0"
                              : "border-0 lg:text-black text-white dark:text-white"
                          }`}
                        >
                          {categoryId === cat.id ? (
                            <IoIosRadioButtonOn className="mr-1" size="20" />
                          ) : (
                            <IoRadioButtonOff className="mr-1" size="20" />
                          )}
                          {cat?.name}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                {subCategory?.length > 0 && (
                  <div className="pb-3">
                    <h6 className="text-slate-300 lg:text-slate-700 font-bold dark:text-white">
                      Sub Categories
                    </h6>
                    <div className="py-1">
                      {subCategory?.map((subcat, i) => (
                        <button
                          onClick={() => {
                            setSubCategoryId(subcat?.id);
                            setFilterOption(false);
                          }}
                          key={i}
                          className={`flex items-center  border-0 m-1 text-sm ${
                            subCategoryId === subcat.id
                              ? "text-red-400"
                              : "text-white lg:text-black dark:text-white"
                          }`}
                        >
                          {subCategoryId === subcat.id ? (
                            <IoIosRadioButtonOn className="mr-1" size="20" />
                          ) : (
                            <IoRadioButtonOff className="mr-1" size="20" />
                          )}
                          {subcat?.name}
                        </button>
                      ))}
                    </div>
                  </div>
                )}

                <div className="pb-5">
                  <h6 className="text-slate-300 lg:text-slate-700 font-bold dark:text-white">
                    Price Range
                  </h6>
                  <div>
                    <div className="flex gap-2 pt-2">
                      <div className="">
                        <input
                          placeholder="Min"
                          onChange={(e) => setHandleMinPrice(e.target.value)}
                          value={handleMinPrice}
                          type="number"
                          name=""
                          className=" dark:text-white  bg-transparent border border-1 w-20 p-1 focus:outline-none"
                          min={0}
                          id=""
                        />
                      </div>
                      <div className="">
                        <input
                          placeholder="Max"
                          onChange={(e) => setHandleMaxPrice(e.target.value)}
                          type="number"
                          name=""
                          className=" dark:text-white  bg-transparent border border-1 w-20 p-1 focus:outline-none"
                          min={0}
                          id=""
                        />
                      </div>
                      <div className="">
                        <button
                          onClick={() => {
                            filter();
                            setFilterOption(false);
                          }}
                          className="text-sm py-2 px-3 bg-red-400"
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <h6 className="text-slate-300 lg:text-slate-700 font-bold dark:text-white">
                    Sort by price
                  </h6>
                  <div className="py-2">
                    {sorting.map((item, i) => (
                      <button
                        key={i}
                        onClick={() => {
                          setShortBy(item.value);
                          setFilterOption(false);
                        }}
                        className={`flex items-center  border-0 m-1 bg-transparent text-sm ${
                          sortBby === item.value
                            ? "text-red-400"
                            : "text-white lg:text-black dark:text-white"
                        }`}
                      >
                        {sortBby === item.value ? (
                          <IoIosRadioButtonOn className="mr-1" size="20" />
                        ) : (
                          <IoRadioButtonOff className="mr-1" size="20" />
                        )}
                        {item.sortName}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full sm:w-full mx-auto lg:w-9/12">
              <div className="flex items-center justify-between mt-2 rounded p-3">
                <div className="flex items-center">
                  <BsListColumnsReverse
                    onClick={() => setGrid(1)}
                    className="mr-2 dark:text-white cursor-pointer block lg:hidden"
                  />
                  <IoGridOutline
                    size={18}
                    onClick={() => setGrid(2)}
                    className="mr-2 dark:text-white cursor-pointer"
                  />
                  <TfiLayoutColumn3
                    onClick={() => setGrid(3)}
                    className="mr-2 dark:text-white cursor-pointer hidden lg:block"
                  />
                  <BsGrid3X3Gap
                    size={18}
                    onClick={() => setGrid(4)}
                    className="mr-2 dark:text-white cursor-pointer hidden lg:block"
                  />
                  <TfiLayoutGrid4
                    size={18}
                    onClick={() => setGrid(5)}
                    className="mr-2 dark:text-white cursor-pointer hidden lg:block"
                  />
                </div>
                <div>
                  <button
                    onClick={() => setFilterOption(true)}
                    className="border-0 bg-transparent mr-3 flex lg:hidden dark:text-white"
                  >
                    <FcFilledFilter size={20} className="mr-2" />
                    Filter
                  </button>
                </div>
              </div>
              <div className="p-0 lg:p-3 mb-5">
                {content}
                {filteredProducts?.length > 0 && (
                  <div className="flex justify-center">
                    <Paginate paginateFunction={pageData} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProductContainer;
