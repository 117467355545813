import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
import useStore from "../../../Hooks/useStore";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../Hooks/Helper";
const Category = ({ categories }) => {
  const navigate = useNavigate();
  const { showDummyImage, setCategoryId } = useStore().data;

  const goPage = (id) => {
    navigate("/products");
    setCategoryId(id);
  };
  const data = {
    heading: "Top Categories",
    paragraph: "Most purchases are made here by customers.",
  };

  return (
    <section className="py-8 lg:py-16">
      <div className="container">
        <SectionHeading2 data={data} />
        <div className="relative mt-3">
          <Swiper
            slidesPerView={3}
            spaceBetween={0}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 0,
              },
              1280: {
                slidesPerView: 9,
                spaceBetween: 0,
              },
            }}
            className="slider-category"
            modules={[Autoplay]}
          >
            {categories?.map((item, i) => (
              <SwiperSlide key={i}>
                <div
                  onClick={() => goPage(item?.id)}
                  className="bg-white dark:bg-gray-900 p-2"
                >
                  <div className="h-16 w-16 mx-auto overflow-hidden">
                    {
                      item?.image?  <img
                      onError={({ currentTarget }) =>
                        showDummyImage({ currentTarget })
                      }
                      src={
                        `${baseUrl.img}/${item?.image}`
                      }
                      alt=""
                      className="w-full h-full"
                    /> :  <img
                    onError={({ currentTarget }) =>
                      showDummyImage({ currentTarget })
                    }
                    src={
                    
                      `https://placehold.co/64x64`
                    }
                    alt=""
                    className="w-full h-full"
                  />
                    }
                   
                  </div>
                  <div className="px-2 text-center pb-2">
                    <div className="single-category-container">
                      <h6 className="dark:text-white whitespace-nowrap text-sm">{item?.name}</h6>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            slidesPerView={3}
            spaceBetween={0}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 0,
              },
              1280: {
                slidesPerView: 9,
                spaceBetween: 0,
              },
            }}
            className="slider-category"
            modules={[Autoplay]}
          >
            {categories
              ?.slice(0, categories?.length)
              .reverse()
              ?.map((item, i) => (
                <SwiperSlide key={i}>
                  <div
                    onClick={() => goPage(item?.id)}
                    className="bg-white dark:bg-gray-900"
                  >
                    <div className="h-16 w-16 mx-auto overflow-hidden">
                      <img
                        onError={({ currentTarget }) =>
                          showDummyImage({ currentTarget })
                        }
                        src={
                          `${baseUrl.img}/${item?.image}` ||
                          `https://placehold.co/64x64`
                        }
                        alt=""
                        className="w-full h-full"
                      />
                    </div>
                    <div className="px-2 text-center pb-2">
                      <div className="single-category-container">
                        <h6 className="dark:text-white whitespace-nowrap text-sm">{item?.name}</h6>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Category;