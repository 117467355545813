import React, { useEffect } from 'react';
import ProductDetailsBanner from '../../Components/ProductDetailsBanner/ProductDetailsBanner';
import Brands from '../../Components/Brands/Brands'
import { useParams } from 'react-router-dom';
import SimillarProducts from '../../Components/SimillarProducts/SimillarProducts';

const ProductDetails = () => {
    const {id} = useParams()
    useEffect(()=>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          })
    },[id])

    return (
        <>
            <ProductDetailsBanner/>
            <SimillarProducts/>
            <Brands/>
          
        </>
    );
};

export default ProductDetails;