import React from "react";
import { baseUrl } from "../../../Hooks/Helper";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import { useNavigate } from "react-router-dom";
import useStore from "../../../Hooks/useStore";
const Category2 = ({ categories }) => {
  const data = {
    heading: "Top Categories",
    paragraph: "Choose a category to explore quickly",
  };
  const { setCategoryId } = useStore().data;
  const navigate = useNavigate();
  const goPage = (id) => {
    navigate("/products");
    setCategoryId(id);
  };

  return (
    <section className="my-8 lg:my-16">
      <div className="container">
        <SectionHeading2 data={data} />
        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5  lg:grid-cols-7 xl:grid-cols-9 gap-4 mt-3">
          {categories?.map((item, i) => (
            <div className="text-center" key={i} onClick={() => goPage(item?.id)}>
              <div className="w-16 h-16 mx-auto overflow-hidden">
                {item?.image ? (
                  <img
                    src={`${baseUrl?.img}/${item?.image}`}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <img
                    src={`https://placehold.co/64x64`}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
              <span className="dark:text-white text-sm whitespace-nowrap">{item?.name}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Category2;