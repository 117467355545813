import React from 'react'
import { baseUrl } from '../../../Hooks/Helper'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css/navigation";
import "swiper/css/bundle";
import SectionHeading2 from '../../SectionHeadings/SectionHeading2/SectionHeading2';
const Sponsor2 = ({data}) => {
    const data2 = {
        heading:'Sponsors Spotlight',
        paragraph:'Recognizing Our Key Contributors'
    }
  return (
    <section className="my-2 lg:my-16">
    <div className="container">
    <SectionHeading2 data={data2}/>

      <div>
        <Swiper
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}
           autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
        loop={true}
        modules={[ Autoplay]}
        >
          {data?.length > 0 ? (
              data.map((sponsor, i) => (
                <SwiperSlide key={i}>
                  <div className=" h-[126px] w-full">
                    <img
                      src={
                        sponsor?.image
                          ? `${baseUrl.img}/${sponsor.image}`
                          : "https://placehold.co/210x126"
                      }
                      alt="slider"
                      className="w-auto h-full mx-auto"
                    />
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <div className=" h-[126px] w-full">
                  <img
                    src="https://placehold.co/210x126"
                    alt="placeholder"
                    className="w-auto h-full mx-auto"
                  />
                </div>
              </SwiperSlide>
            )}
        </Swiper>
      </div>

    </div>
  
  </section>
  )
}

export default Sponsor2