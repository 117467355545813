import React from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import { baseUrl, errorNotify, getFromCookie, saveInCookie, successNotify } from "../../Hooks/Helper";
import useStore from "../../Hooks/useStore";
import compareIMg from "../../assets/compare/nocompare.png";
// import { useDispatch } from "react-redux";
// import { setUpdate } from "../../Redux/Slices/Cart/cartListSlice";
import { ToastContainer } from "react-toastify";
import BreadCumb from "../../Components/BreadCumb/BreadCumb";
import SectionHeading2 from "../../Components/SectionHeadings/SectionHeading2/SectionHeading2";

const CompareProducts = () => {
  const { compare, custommerToken,setSecondaryToken,update,setUpdate, setCompare } =
    useStore().data;
    const addToCart = async (qty,combination_id) => {
      const user_unique_id = getFromCookie("user_unique_id");
      const formData = new FormData();
      formData.append("quantity", qty);
      formData.append(
        "product_combination_id",
        combination_id
      );
  
      let url = `${baseUrl.url}/cart-store`;
      if (custommerToken) {
        url = `${baseUrl.url}/user/cart-store`;
      }
      if (!custommerToken && user_unique_id !== undefined) {
        url = url + `?user_unique_id=${user_unique_id}`;
      }
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: custommerToken
            ? `bearer ${custommerToken}`
            : baseUrl.token,
        },
        body: formData,
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        successNotify("Successfully added to cart");
        if (resData.data.user_unique_id !== null) {
          saveInCookie("user_unique_id", resData.data.user_unique_id);
        }
        const token = getFromCookie("user_unique_id");
        setSecondaryToken(token);
        // removeProduct(index)
      } else {
        resData?.errors.map((err) => errorNotify(err));
      }
    };

  const removeProduct = (index) => {
    const updatedProducts = [...compare];
    updatedProducts.splice(index, 1);
    setCompare(updatedProducts);
  };
  const navigationData = [
    {
      page: "Home",
      route: "/",
    },
    {
      page: "Compare",
      route: "/compare",
    },
    {
      page: "Items",
      route: "/compare",
    },
  ];
  const data = {
    heading:"Compare Products",
    prargraph:null
  }
  const titles = ["Image", "Name", "Price", "Description","Total sold", "Action"];
  const parse = require('html-react-parser')

  return (
    <div className="py-8 lg:py-16">
      <div className="container">
        <SectionHeading2 data={data}/>
        <BreadCumb data={navigationData} />
        {compare.length > 0 ? (
          <div className="flex overflow-x-scroll lg:overflow-hidden">
            {compare?.map((item, i) => (
              <div key={i} className="flex items-center">
                {i === 0 && (
                  <div className="">
                    {titles?.map((title, j) => (
                      <div
                        key={j}
                        className={`flex justify-center bg-slate-100 items-center text-left border  my-2 w-36 lg:w-48 ${
                          title === "Image"
                            ? "h-52"
                            : `${title === "Description" ? "h-44" : "h-20"}`
                        }`}
                      >
                        <p className="text-lg font-semibold text-slate-700 dark:text-slate-800">
                          {title}
                        </p>
                      </div>
                    ))}
                  </div>
                )}

                <div>
                  <div className={`w-72 h-52 border-y border-r my-2`}>
                    <div className="p-3 w-full h-full">
                      <img
                        src={`${baseUrl.img}/${item?.thumbnail}`}
                        alt=""
                        className=" rounded w-full h-full"
                      />
                    </div>
                  </div>
                  <div className="w-72 h-20 border-r border-y flex items-center justify-center my-2">
                    <p className="p-3 font-medium dark:text-white">{item?.name}</p>
                  </div>
                  <div className="w-72 h-20 border-r border-y  my-2 flex items-center justify-center">
                    <p className="p-3 text-xl font-medium price-color dark:text-white">
                    ৳ {item?.price}
                    </p>
                  </div>

                  <div className="w-72 h-44 border-r border-y  my-2">
                    <p className="p-3 dark:text-white font-medium">{parse(`${item.description?.slice(0,50)}`)}</p>
                   
                  </div>
                  <div className="w-72 h-20 border-r border-y  my-2 flex items-center justify-center">
                    <p className="p-3 text-xl font-medium price-color dark:text-white">
                      {item?.sold_count}
                    </p>
                  </div>
                  
                  <div className="w-72 h-20 border-r border-t border-b my-2 flex items-center px-3">
                    <div key={i} className=" flex items-center w-full ">
                      <button
                        onClick={() => addToCart(item.quantity, item.product_combination_id)}
                        className="py-1 px-1  mb-1 text-xs text-center w-full border rounded-full   hover:bg-red-400 flex items-center justify-center dark:text-white"
                      >
                        <BsPlus size={15} /> Add to Cart
                      </button>
                      <span className="border-l-2 p-1 h-8 ml-2 flex justify-center border-slate-400"></span>
                      <button
                        onClick={() => removeProduct(i)}
                        className="py-1 px-1  mb-1 text-xs text-center w-full border rounded-full  hover:bg-red-500 hover:text-white flex gap-1 items-center justify-center dark:text-white"
                      >
                        <AiOutlineMinus size={12} />
                        Remove Compare
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-5 m-2 text-center col-span-12">
            <h5 className="m-0 dark:text-white">Compare List is empty</h5>
            <p className="m-0 dark:text-white">No items added in Compare List</p>
            <div style={{ width: "200px" }} className="mx-auto">
              <img src={compareIMg} alt="" className="w-full" />
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default CompareProducts;
