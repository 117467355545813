import "./Home.css";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Category from "../../Components/Categories/Category/Category";
import Category2 from "../../Components/Categories/Category2/Category2";
import Subscription from "../../Components/Subscriptions/Subscription/Subscription";
import Subscription2 from "../../Components/Subscriptions/Subscription2/Subscription2";
import Slider2 from "../../Components/Sliders/Slider2/Slider2";
import Slider3 from "../../Components/Sliders/Slider3/Slider3";
import Slider4 from "../../Components/Sliders/Slider4/Slider4";
import Sponsors from "../../Components/Sponsors/Sponsors";
import FeatureProduct from "../../Components/FeatureProducts/FeatureProduct/FeatureProduct";
import DiscountProduct from "../../Components/DiscountProducts/DiscountProduct/DiscountProduct";
import DiscountProducts2 from "../../Components/DiscountProducts/DiscountProducts2/DiscountProducts2";
import DiscountProducts3 from "../../Components/DiscountProducts/DiscountProducts3/DiscountProducts3";
import FlashSale from "../../Components/FlashSales/FlashSale/FlashSale";
import FlashSale2 from "../../Components/FlashSales/FlashSale2/FlashSale2";
import FlashSale3 from "../../Components/FlashSales/FlashSale3/FlashSale3";
import FlashSale4 from "../../Components/FlashSales/FlashSale4/FlashSale4";
import FeatureProduct2 from "../../Components/FeatureProducts/FeatureProduct2/FeatureProduct2";
import FeatureBanner2 from "../../Components/FeatureBanners/FeatureBanner2/FeatureBanner2";
import FeatureBanner from "../../Components/FeatureBanners/FeatureBanner/FeatureBanner";
import FeatureProducts3 from "../../Components/FeatureProducts/FeatureProducts3/FeatureProducts3";
import NewArrival from "../../Components/NewArrivals/NewArrival/NewArrival";
import NewArrival2 from "../../Components/NewArrivals/NewArrival2/NewArrival2";
import NewArrival3 from "../../Components/NewArrivals/NewArrival3/NewArrival3";
import PopularProducts from "../../Components/PopularProducts/PopularProducts";
import Loader2 from "../../Components/Loaders/Loader2/Loader2";
import Slider5 from "../../Components/Sliders/Slider5/Slider5";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchHomeData} from "../../Redux/Slices/rootSlice/rootSlice";
import {fetchThemeData} from "../../Redux/Slices/theme/themeSlice";
import NoData from "../NoData/NoData";
import useStore from "../../Hooks/useStore";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import {baseUrl, errorNotify, successNotify} from "../../Hooks/Helper";
import Category3 from "../../Components/Categories/Category3/Category3";
import FeatureProduct4 from "../../Components/FeatureProducts/FeatureProduct4/FeatureProduct4";
import NewArrival4 from "../../Components/NewArrivals/NewArrival4/NewArrival4";
import DiscountProducts4 from "../../Components/DiscountProducts/DiscountProducts4/DiscountProducts4";
import PopularProduct4 from "../../Components/PopularProducts/PopularProduct4/PopularProduct4";
import Category4 from "../../Components/Categories/Category4/Category4";
import Sponsor2 from "../../Components/Sponsors/Sponsors2/Sponsor2";
import Category5 from "../../Components/Categories/Category5/Category5";
import FeatureProduct5 from "../../Components/FeatureProducts/FeatureProduct5/FeatureProduct5";
import NewArrival5 from "../../Components/NewArrivals/NewArrival5/NewArrival5";
import DiscountProduct5 from "../../Components/DiscountProducts/DiscountProduct5/DiscountProduct5";
import PopularProducts5 from "../../Components/PopularProducts/PopularProducts5/PopularProducts5";
import FlashSale5 from "../../Components/FlashSales/FlashSale5/FlashSale5";
import Sponsor3 from "../../Components/Sponsors/Sponsor3/Sponsor3";
import PopularProducts2 from "../../Components/PopularProducts/PopularProducts2/PopularProducts2";
import PopularProducts3 from "../../Components/PopularProducts/PopularProducts3/PopularProducts3";
import FeatureProduct6 from "../../Components/FeatureProducts/FeatureProduct6/FeatureProduct6";
import FlashSale6 from "../../Components/FlashSales/FlashSale6/FlashSale6";
import NewArrival6 from "../../Components/NewArrivals/NewArrival6/NewArrival6";
import DiscountProduct6 from "../../Components/DiscountProducts/DiscountProduct6/DiscountProduct6";
import PopularProduct6 from "../../Components/PopularProducts/PopularProduct6/PopularProduct6";
import Category6 from "../../Components/Categories/Category6/Category6";
import Sponsor4 from "../../Components/Sponsors/Sponsor4/Sponsor4";
import PopularProduct7 from "../../Components/PopularProducts/PopularProduct7/PopularProduct7";
import NewArrival7 from "../../Components/NewArrivals/NewArrival7/NewArrival7";
import FlashSale7 from "../../Components/FlashSales/FlashSale7/FlashSale7";
import FeatureProduct7 from "../../Components/FeatureProducts/FeatureProduct7/FeatureProduct7";
import DiscountProduct7 from "../../Components/DiscountProducts/DiscountProduct7/DiscountProduct7";
import FlashSale8 from "../../Components/FlashSales/FlashSale8/FlashSale8";
import FeatureProduct8 from "../../Components/FeatureProducts/FeatureProduct8/FeatureProduct8";
import NewArrival8 from "../../Components/NewArrivals/NewArrival8/NewArrival8";
import DiscountProduct8 from "../../Components/DiscountProducts/DiscountProduct8/DiscountProduct8";
import PopularProduct8 from "../../Components/PopularProducts/PopularProduct8/PopularProduct8";
import {useLocation} from "react-router-dom";
import FeatureProduct9 from "../../Components/FeatureProducts/FeatureProduct9/FeatureProduct9";
import NewArrival9 from "../../Components/NewArrivals/NewArrival9/NewArrival9";
import DiscountProduct9 from "../../Components/DiscountProducts/DiscountProduct9/DiscountProduct9";
import PopularProduct9 from "../../Components/PopularProducts/PopularProduct9/PopularProduct9";
import FlashSale9 from "../../Components/FlashSales/FlashSale9/FlashSale9";
import Category7 from "../../Components/Categories/Category7/Category7";
import Sponsor5 from "../../Components/Sponsors/Sponsor5/Sponsor5";
import Category8 from "../../Components/Categories/Category8/Category8";
import FeatureProduct10 from "../../Components/FeatureProducts/FeatureProduct10/FeatureProduct10";
import NewArrival10 from "../../Components/NewArrivals/NewArrival10/NewArrival10";
import DiscountProduct10 from "../../Components/DiscountProducts/DiscountProduct10/DiscountProduct10";
import PopularProduct10 from "../../Components/PopularProducts/PopularProduct10/PopularProduct10";
import FlashSale10 from "../../Components/FlashSales/FlashSale10/FlashSale10";
import Sponsor6 from "../../Components/Sponsors/Sponsor6/Sponsor6";
import Category9 from "../../Components/Categories/Category9/Category9";
import Sponsor7 from "../../Components/Sponsors/Sponsor7/Sponsor7";
import Sponsor8 from "../../Components/Sponsors/Sponsor8/Sponsor8";
import Sponsor9 from "../../Components/Sponsors/Sponsor9/Sponsor9";
import Category10 from "../../Components/Categories/Category10/Category10";
import Sponsor10 from "../../Components/Sponsors/Sponsor10/Sponsor10";
import Slider6 from "../../Components/Sliders/Slider6/Slider6";
import Subscription3 from "../../Components/Subscriptions/Subscription3/Subscription3";
import Slider7 from "../../Components/Sliders/Slider7/Slider7";
import Slider8 from "../../Components/Sliders/Slider8/Slider8";
import Subscription4 from "../../Components/Subscriptions/Subscription4/Subscription4";
import Subscription5 from "../../Components/Subscriptions/Subscription5/Subscription5";

const Home = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const {custommerToken, update, setUpdate} = useStore().data;
    const {cartPopUp, setCartPopUp} = useStore().store;
    const {data, loading, err: homeErr} = useSelector((state) => state.home);
    const [open, setOpen] = useState(false);
    const {themeData, themeLoader, err} = useSelector((state) => state.theme);
    const [wishData, setWishData] = useState();
    const [referWishModal, setReferWishModal] = useState(false);
    const wish_list_token = queryParams.get("wish_list_token");
    const wish_list_id = queryParams.get("wish_list_id");

    useEffect(() => {
        if (!wish_list_token && !wish_list_id) {
            return;
        }
        const getWishData = async () => {
            try {
                const res = await fetch(
                    `${baseUrl.url}/wish-list?wishlist_token=${wish_list_token}&wishlist_id=${wish_list_id}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: baseUrl.token,
                        },
                    }
                );
                const resData = await res.json();
                if (res.ok && res.status !== 204) {
                    setWishData(resData?.data);
                    setReferWishModal(true);
                }
            } catch (error) {
                errorNotify(error);
            }
        };
        getWishData();
    }, [wish_list_id, wish_list_token]);

    useEffect(() => {
        if (cartPopUp) {
            setOpen(true);
        }
        dispatch(fetchHomeData());
        dispatch(fetchThemeData());
    }, [dispatch, cartPopUp]);

    const mergeCart = async (status) => {
        const res = await fetch(`${baseUrl.url}/user/add-cart-to-user`, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-type": "Application/json",
                Authorization: `bearer ${custommerToken}`,
            },
            body: JSON.stringify({status: status}),
        });
        if (res.ok && res.status !== 204) {
            successNotify("Cart merged successfully");
            setCartPopUp(false);
            setUpdate(!update);
            setOpen(!open);
        } else {
            const resData = await res.json();
            errorNotify(resData?.errors[0]);
        }
    };

    // if (loading === false && themeLoader === false && themeData?.length === 0) {
    //   return (
    //     <NoData
    //       status={500}
    //       err={"Internal server error"}
    //       errDetails={"It may seems, no data available for this route."}
    //     />
    //   );
    // }
    // if (themeLoader || loading) {
    //   return <Loader2 />;
    // }

    if (themeLoader || loading) {
        return <Loader2/>;
    }

    if (homeErr || err) {
        return (
            <NoData
                status={500}
                err={"Internal server error"}
                errDetails={"It may seems, no data available for this route."}
            />
        );
    }

    const handleReferWishToCart = async () => {
        try {
            const res = await fetch(
                `${baseUrl.url}${custommerToken && `/user`}/add-cart-from-wishlist`,
                {
                    method: "POST",
                    headers: {
                        "Content-type": "Application/json",
                        Authorization: custommerToken
                            ? `Bearer ${custommerToken}`
                            : baseUrl.token,
                    },
                    body: JSON.stringify({wishlist_secret_key: wish_list_token}),
                }
            );
            if (res.ok && res.status !== 204) {
                successNotify("Products added to cart successfully");
                setUpdate(!update);
                setReferWishModal(!referWishModal);
            } else {
                const resData = await res.json();
                errorNotify(resData?.errors[0]);
            }
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div className="main-home relative">
            <Rodal
                visible={referWishModal}
                onClose={() => setReferWishModal(!referWishModal)}
                customStyles={{
                    width: window.innerWidth < 768 ? "90%" : "50%",
                    height: "auto",
                    bottom: "auto",
                    top: "40%",
                    transform: "translateY(-50%)",
                }}
            >
                <div>
                    <h1 className="text-xl font-semibold">
                        {" "}
                        <span className="text-sky-600">{wishData?.user?.name}</span> has
                        send you this wishlist
                    </h1>
                    <p>Title:{wishData?.title}</p>
                    <div>
                        <div className="my-2 overflow-scroll lg:overflow-hidden">
                            <table className="w-full table-auto">
                                <thead className="w-full ">
                                <tr className="bg-slate-100">
                                    <th className="text-left">
                      <span className="text-sm lg:text-base dark:text-white text-center ">
                        {" "}
                          Product
                      </span>
                                    </th>
                                    <th className="text-left">
                      <span className="text-sm lg:text-base dark:text-white text-center">
                        {" "}
                          Unit price
                      </span>
                                    </th>

                                    <th className=""></th>
                                </tr>
                                </thead>
                                <tbody className="w-full">
                                {wishData?.items?.map((item, i) => {
                                    return (
                                        <tr key={i} className="w-full">
                                            {/* <td></td> */}
                                            <td className="text-sm text-slate-700 flex-wrap lg:flex dark:text-white text-center">
                                                <img
                                                    className="w-12 h-12 rounded bg-slate-100 text-center"
                                                    src={`${baseUrl.img}${item?.product_combination.product?.thumbnail_image}`}
                                                    alt="cart"
                                                />
                                                <div>
                                                    <p className="text-base ml-2">
                                                        {item?.product_combination?.product?.name?.slice(
                                                            0,
                                                            70
                                                        )}
                                                    </p>
                                                    <p className="text-base ml-2 text-left">
                                                        {item?.product_combination?.attribute_values?.map(
                                                            (value, j) => (
                                                                <span key={j}>
                                    {value?.name}
                                                                    {j ===
                                                                    item?.product_combination?.attribute_values
                                                                        ?.length -
                                                                    1
                                                                        ? ``
                                                                        : "-"}
                                  </span>
                                                            )
                                                        )}
                                                    </p>
                                                </div>
                                            </td>
                                            <td className="text-left pl-2 dark:text-white">
                                                ৳ {item?.product_combination?.selling_price}
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2 mt-4">
                        <button
                            className="w-full border p-2 rounded"
                            onClick={() => setReferWishModal(!referWishModal)}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleReferWishToCart}
                            className="w-full rounded bg-yellow-500 border p-2 text-black"
                        >
                            Add To Cart
                        </button>
                    </div>
                </div>
            </Rodal>
            <Rodal
                visible={open}
                onClose={() => setOpen(!open)}
                customStyles={{
                    height: "auto",
                    bottom: "auto",
                    top: "40%",
                    transform: "translateY(-50%)",
                }}
            >
                <h3 className="text-lg border-b py-3">Cart message</h3>
                <p className="text-base mt-2 text-gray-700">
                    Hi, we found some items in your cart before login.So you can{" "}
                    <span className="underline underline-offset-1 text-green-500">
            shift
          </span>{" "}
                    your cart items to your profile.Would you like to add those? Or{" "}
                    <span className="underline underline-offset-1 text-red-500">
            skip
          </span>{" "}
                    shifting them?.
                </p>
                <div className="grid grid-cols-2 gap-2 mt-4">
                    <button
                        className="w-full border p-2 rounded"
                        onClick={() => {
                            mergeCart(0);
                            setOpen(!open);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => {
                            mergeCart(1);
                        }}
                        className="w-full bg-indigo-500 text-white p-2 rounded"
                    >
                        Confirm
                    </button>
                </div>
            </Rodal>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            {themeData?.map((section, i) => {
                return (
                    <div key={i}>
                        {section?.id === 2 && (
                            <>
                                {section?.is_active === 1 && (
                                    <>
                                        {section?.value === 1 ? (
                                            <Slider2
                                                banners={data?.banners}
                                                categories={data?.categories}
                                            />
                                        ) : section?.value === 2 ? (
                                            <Slider3 banners={data?.banners}/>
                                        ) : section?.value === 3 ? (
                                            <Slider4 banners={data?.banners}/>
                                        ) : section?.value === 4 ? (
                                            <Slider5 banners={data?.banners}/>
                                        ) : section?.value === 5 ? (
                                            <Slider6 banners={data?.banners}/>
                                        ) : section?.value === 6 ? (
                                            <Slider7 banners={data?.banners}/>
                                        ) : (
                                            <Slider8 banners={data?.banners}/>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {section?.id === 3 && (
                            <>
                                {section?.is_active === 1 && (
                                    <>
                                        {section?.value === 1 ? (
                                            <Category categories={data?.categories}/>
                                        ) : section?.value === 2 ? (
                                            <Category2 categories={data?.categories}/>
                                        ) : section?.value === 3 ? (
                                            <Category3 categories={data?.categories}/>
                                        ) : section?.value === 4 ? (
                                            <Category4 categories={data?.categories}/>
                                        ) : section?.value === 5 ? (
                                            <Category5 categories={data?.categories}/>
                                        ) : section?.value === 6 ? (
                                            <Category6 categories={data?.categories}/>
                                        ) : section?.value === 7 ? (
                                            <Category7 categories={data?.categories}/>
                                        ) : section?.value === 8 ? (
                                            <Category8 categories={data?.categories}/>
                                        ) : section?.value === 9 ? (
                                            <Category9 categories={data?.categories}/>
                                        ) : (
                                            <Category10 categories={data?.categories}/>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {section?.id === 4 && (
                            <>
                                {section?.is_active === 1 && (
                                    <>
                                        {section?.value === 1 ? (
                                            <FeatureProduct
                                                data={data?.featured_products}
                                                site_banners={data?.site_banners}
                                                flash={data?.flash_sale}

                                            />
                                        ) : section?.value === 2 ? (
                                            <FeatureProduct2 data={data?.featured_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 3 ? (
                                            <FeatureProducts3 data={data?.featured_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 4 ? (
                                            <FeatureProduct4 data={data?.featured_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 5 ? (
                                            <FeatureProduct5 data={data?.featured_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 6 ? (
                                            <FeatureProduct6 data={data?.featured_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 7 ? (
                                            <FeatureProduct7 data={data?.featured_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 8 ? (
                                            <FeatureProduct8 data={data?.featured_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 9 ? (
                                            <FeatureProduct9
                                                data={data?.featured_products}
                                                site_banners={data?.site_banners}
                                                flash={data?.flash_sale}
                                            />
                                        ) : (
                                            <FeatureProduct10 data={data?.featured_products} flash={data?.flash_sale}/>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {section?.id === 5 && (
                            <>
                                {section?.is_active === 1 && (
                                    <>
                                        {section?.value === 1 ? (
                                            <FlashSale
                                                data={data?.sale_products}
                                                flash={data?.flash_sale}
                                                site_banners={data?.site_banners}
                                            />
                                        ) : section?.value === 2 ? (
                                            <FlashSale2
                                                data={data?.sale_products}
                                                flash={data?.flash_sale}
                                            />
                                        ) : section?.value === 3 ? (
                                            <FlashSale3
                                                data={data?.sale_products}
                                                flash={data?.flash_sale}
                                            />
                                        ) : section?.value === 4 ? (
                                            <FlashSale4
                                                data={data?.sale_products}
                                                flash={data?.flash_sale}
                                            />
                                        ) : section?.value === 5 ? (
                                            <FlashSale5
                                                data={data?.sale_products}
                                                flash={data?.flash_sale}
                                            />
                                        ) : section?.value === 6 ? (
                                            <FlashSale6
                                                data={data?.sale_products}
                                                flash={data?.flash_sale}
                                            />
                                        ) : section?.value === 7 ? (
                                            <FlashSale7
                                                data={data?.sale_products}
                                                flash={data?.flash_sale}
                                            />
                                        ) : section?.value === 8 ? (
                                            <FlashSale8
                                                data={data?.sale_products}
                                                flash={data?.flash_sale}
                                            />
                                        ) : section?.value === 9 ? (
                                            <FlashSale9
                                                data={data?.sale_products}
                                                flash={data?.flash_sale}
                                                site_banners={data?.site_banners}
                                            />
                                        ) : (
                                            <FlashSale10
                                                data={data?.sale_products}
                                                flash={data?.flash_sale}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {section?.id === 6 && (
                            <>
                                {section?.is_active === 1 && (
                                    <>
                                        {section?.value === 1 ? (
                                            <FeatureBanner site_banners={data?.site_banners}/>
                                        ) : (
                                            <FeatureBanner2 site_banners={data?.site_banners}/>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {section?.id === 7 && (
                            <>
                                {section?.is_active === 1 && (
                                    <>
                                        {section?.value === 1 ? (
                                            <NewArrival
                                                data={data?.new_products}
                                                site_banners={data?.site_banners}
                                                flash={data?.flash_sale}
                                            />
                                        ) : section?.value === 2 ? (
                                            <NewArrival2 data={data?.new_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 3 ? (
                                            <NewArrival3 data={data?.new_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 4 ? (
                                            <NewArrival4 data={data?.new_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 5 ? (
                                            <NewArrival5 data={data?.new_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 6 ? (
                                            <NewArrival6 data={data?.new_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 7 ? (
                                            <NewArrival7 data={data?.new_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 8 ? (
                                            <NewArrival8 data={data?.new_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 9 ? (
                                            <NewArrival9
                                                data={data?.new_products}
                                                site_banners={data?.site_banners}
                                                flash={data?.flash_sale}
                                            />
                                        ) : (
                                            <NewArrival10 data={data?.new_products} flash={data?.flash_sale}/>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {section?.id === 8 && (
                            <>
                                {section?.is_active === 1 && (
                                    <>
                                        {section?.value === 1 ? (
                                            <DiscountProducts2
                                                data={data?.discount_products}
                                                site_banners={data?.site_banners}
                                                flash={data?.flash_sale}

                                            />
                                        ) : section?.value === 2 ? (
                                            <DiscountProduct data={data?.discount_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 3 ? (
                                            <DiscountProducts3 data={data?.discount_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 4 ? (
                                            <DiscountProducts4 data={data?.discount_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 5 ? (
                                            <DiscountProduct5 data={data?.discount_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 6 ? (
                                            <DiscountProduct6 data={data?.discount_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 7 ? (
                                            <DiscountProduct7 data={data?.discount_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 8 ? (
                                            <DiscountProduct8 data={data?.discount_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 9 ? (
                                            <DiscountProduct9
                                                data={data?.discount_products}
                                                site_banners={data?.site_banners}
                                                flash={data?.flash_sale}
                                            />
                                        ) : (
                                            <DiscountProduct10 data={data?.discount_products} flash={data?.flash_sale}/>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {section?.id === 10 && (
                            <>
                                {section?.is_active === 1 && (
                                    <>
                                        {section?.value === 1 ? (
                                            <PopularProducts
                                                data={data?.popular_products}
                                                site_banners={data?.site_banners}
                                                flash={data?.flash_sale}
                                            />
                                        ) : section?.value === 2 ? (
                                            <PopularProducts2 data={data?.popular_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 3 ? (
                                            <PopularProducts3 data={data?.popular_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 4 ? (
                                            <PopularProduct4 data={data?.popular_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 5 ? (
                                            <PopularProducts5 data={data?.popular_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 6 ? (
                                            <PopularProduct6 data={data?.popular_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 7 ? (
                                            <PopularProduct7 data={data?.popular_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 8 ? (
                                            <PopularProduct8 data={data?.popular_products} flash={data?.flash_sale}/>
                                        ) : section?.value === 9 ? (
                                            <PopularProduct9
                                                data={data?.popular_products}
                                                site_banners={data?.site_banners}
                                                flash={data?.flash_sale}
                                            />
                                        ) : (
                                            <PopularProduct10 data={data?.popular_products} flash={data?.flash_sale}/>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {section?.id === 9 && (
                            <>
                                {section?.is_active === 1 && (
                                    <>
                                        {section?.value === 1 ? (
                                            <Sponsors data={data?.sponsors}/>
                                        ) : section?.value === 2 ? (
                                            <Sponsor2 data={data?.sponsors}/>
                                        ) : section?.value === 3 ? (
                                            <Sponsor3 data={data?.sponsors}/>
                                        ) : section?.value === 4 ? (
                                            <Sponsor4 data={data?.sponsors}/>
                                        ) : section?.value === 5 ? (
                                            <Sponsor5 data={data?.sponsors}/>
                                        ) : section?.value === 6 ? (
                                            <Sponsor6 data={data?.sponsors}/>
                                        ) : section?.value === 7 ? (
                                            <Sponsor7 data={data?.sponsors}/>
                                        ) : section?.value === 8 ? (
                                            <Sponsor8 data={data?.sponsors}/>
                                        ) : section?.value === 9 ? (
                                            <Sponsor9 data={data?.sponsors}/>
                                        ) : (
                                            <Sponsor10 data={data?.sponsors}/>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {section?.id === 11 && (
                            <>
                                {section?.is_active === 1 && (
                                    <>
                                        {section?.value === 1 ? (
                                            <Subscription data={data?.site_banners}/>
                                        ) : section?.value === 2 ? (
                                            <Subscription2 data={data?.site_banners}/>
                                        ) : section?.value === 3 ? (
                                            <Subscription3 data={data?.site_banners}/>
                                        ) : section?.value === 4 ? (
                                            <Subscription4/>
                                        ) : (
                                            <Subscription5 data={data?.site_banners}/>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                )
            })}
        </div>
    );
};

export default Home;
