import React from "react";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import "./FlashSale.css";
import SideImage from "../../SideImage/SideImage";
import { baseUrl } from "../../../Hooks/Helper";
const FlashSale = ({ data, site_banners, flash }) => {
  const text =
    "Don't pass up our limited-time flash deal! Today, take advantage of the best product discounts and save a ton of money. Limited period and limited supply. Shop right away to improve your way of life!";
  const endDate = new Date(flash?.end_date);
  return (
    <section className="py-8 lg:py-16">
      {data?.length > 0 && (
        <div className="container">
          <div className="grid grid-cols-12 gap-4 mt-4">
            <SideImage
              image={
                site_banners?.flash_sale_image
                  ? `${baseUrl.img}/${site_banners?.flash_sale_image}`
                  : "https://placehold.co/243x306"
              }
              title={"Flashsale Products"}
              text={text}
              background={"bg-white dark:bg-gray-800"}
              data={{ endDate: endDate, data: data }}
            />
            <div className="col-span-12 lg:col-span-9">
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-4">
                {data?.length > 0 &&
                  data
                    ?.slice(0, 8)
                    ?.reverse()
                    ?.map((product, i) => (
                      <div key={i}>
                        <ProductCard
                          product={product}
                          flash={flash ? flash : null}
                        />
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default FlashSale;
