import React from "react";
import { Link } from "react-router-dom";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import useStore from "../../../Hooks/useStore";
import { baseUrl } from "../../../Hooks/Helper";
import "react-toastify/dist/ReactToastify.css";
const ProductCard = ({ product, flash,promo=true }) => {
  const { showDummyImage } = useStore().data;
  const {
    id,
    slug,
    name,
    thumbnail_image,
    inventories_sum_stock_quantity,
    category_name,
    product_review_rating,
    display_price,
    previous_display_price,
  } = product;
  const discount = Math.floor(((previous_display_price - display_price) / previous_display_price) * 100);
  const flashSalemoney = (display_price * flash?.discount) / 100;
  return (
    <div>
      <div className={`rounded-none lg:rounded-lg overflow-hidden text-dark max-h-[500px]`}>
        <div className="group relative">
          <Link
            to={`/details/${id}/${slug}`}
            
          >
            {
             
              thumbnail_image ?
              <div className={`bg-slate-100 dark:bg-slate-800 block rounded-lg overflow-hidden h-[180px] w-full`}>
                 <img
              loading="lazy"
              onError={({ currentTarget }) => showDummyImage({ currentTarget })}
              src={
                `${baseUrl.img}${thumbnail_image}`
           
              }
              alt=""
              className={`w-auto rounded group-hover:scale-105  transition-all duration-500 h-full mx-auto`}
            /> 
              </div>
             :<img
            
            src={
            
              "https://placehold.co/500x500"
            }
            alt=""
            className={`w-full rounded group-hover:scale-105  transition-all duration-500 h-full object-cover`}
          />
            }
            
          </Link>
     
          {
            promo  && <div>
                {flash?.discount > 0 ? (
            <div>
              <div className="text-center absolute top-2 left-1 h-10 w-10 rounded-full overflow-hidden">
              <span className="text-xs absolute font-bold top-3 rotate-45 flex items-center justify-center left-2 bottom-1  w-5 h-5  bg-rose-500  text-gray-200">
                  {flash?.discount}%
                </span>
                <img
                  src={require('./240_F_650948210_yYfw45gWWNHQZgIn1dApxsqr4MfgNy9i-removebg-preview.png')}
                  alt=""
                />
              </div>
            </div>
          ) : previous_display_price ? (
            <div>
              <div className="text-center absolute top-2 left-1 h-10 w-10 rounded-full overflow-hidden">
                <span className="text-xs absolute font-bold top-3 rotate-45 flex items-center justify-center left-2 bottom-1  w-5 h-5  bg-rose-500  text-gray-200">
                  {discount}%
                </span>
                <img
                   src={require('./240_F_650948210_yYfw45gWWNHQZgIn1dApxsqr4MfgNy9i-removebg-preview.png')}
                  alt=""
                  className=""
                />
                
              </div>
            </div>
          ) : (
            ""
          )}
            </div>
          }
       

          <div className="p-2">
            {category_name && (
              <p className="inline text-xs text-slate-700 dark:text-white">
                {category_name && category_name}
              </p>
            )}
            <Link to={`/details/${id}/${slug}`}>
              <h3 className="block text-sm leading-5 tracking-wider text-slate-700 dark:text-sky-500">
                {name?.length > 20 ? name?.slice(0, 20) + "..." : name}
              </h3>
            </Link>

            <p className="mb-0 flex items-center">
              {Array.from({ length: 5 }).map((_, i) => {
                if (i < parseInt(product_review_rating[0]?.avg_review_rating)) {
                  return <AiFillStar key={i} className="text-orange-500" />;
                }
                return <AiOutlineStar key={i} className="text-gray-400" />;
              })}
            </p>
            <div className="flex justify-between">
              {flash ? (
                <p className="text-gray-700 dark:text-white font-medium tracking-tight text-lg">
                  ৳ {display_price - flashSalemoney}
                </p>
              ) : (
                <p className="text-gray-700 dark:text-white font-medium tracking-tight text-lg">
                  ৳ {display_price}
                </p>
              )}

              {inventories_sum_stock_quantity > 0 ? (
                <p className="text-green-500 font-normal text-xs">In Stock</p>
              ) : (
                <p className="text-red-500 font-normal text-xs">Out of Stock</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
