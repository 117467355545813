import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import "swiper/css/bundle";
import useStore from "../../../Hooks/useStore";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../Hooks/Helper";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";

const Category8 = ({ categories }) => {
  const { showDummyImage, setCategoryId } = useStore().data;
  const navigate = useNavigate();
  const goPage = (id) => {
    navigate("/products");
    setCategoryId(id);
  };
  const data2 = {
    heading: "All Categories",
    paragraph: "Navigating Categories for Unique Finds",
  };
  return (
    <section className="py-8 lg:py-16">
      <div className="container">
        <SectionHeading2 data={data2} />
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 lg:col-span-12">
            <div className="p-3">
              <div>
                <Swiper
                  slidesPerView={7}
                  spaceBetween={10}
                  breakpoints={{
                    // "@0.00": {
                    //   slidesPerView: 3,
                    //   spaceBetween: 10,
                    // },
                    // "@0.75": {
                    //   slidesPerView: 4,
                    //   spaceBetween: 20,
                    // },
                    // "@1.00": {
                    //   slidesPerView: 4,
                    //   spaceBetween: 40,
                    // },
                    // "@1.50": {
                    //   slidesPerView: 7,
                    //   spaceBetween: 50,
                    // },

                    320: {
                      slidesPerView: 3,
                      spaceBetween: 0,
                    },
                    640: {
                      slidesPerView: 4,
                      spaceBetween: 0,
                    },
                    1024: {
                      slidesPerView: 5,
                      spaceBetween: 0,
                    },
                    1280: {
                      slidesPerView: 7,
                      spaceBetween: 0,
                    },
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {categories?.length > 0 &&
                    categories?.map((category, i) => {
                      const { id, name, image } = category;

                      return (
                        <SwiperSlide key={i}>
                          <div
                            className="text-decoration-none flash-sale2 text-center block"
                            onClick={() => goPage(id)}
                          >
                            <div className="h-32">
                              {image ? (
                                <img
                                  onError={({ currentTarget }) =>
                                    showDummyImage({ currentTarget })
                                  }
                                  src={`${baseUrl.img}/${image}`}
                                  alt=""
                                  className="w-full h-full"
                                />
                              ) : (
                                <img
                                  onError={({ currentTarget }) =>
                                    showDummyImage({ currentTarget })
                                  }
                                  src={require("../../../assets/product-default-img/dummy.png")}
                                  alt=""
                                  className="w-full h-full"
                                />
                              )}
                            </div>
                            <h6 className="text-sm leading-4 whitespace-nowrap dark:text-white">
                              {name}
                            </h6>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Category8;