import {toast} from "react-toastify";


// export const baseUrl = {
//   url: process.env.REACT_APP_BASE_URL,
//   img: process.env.REACT_APP_IMG_URL,
//   token: process.env.REACT_APP_TOKEN,
//   domain:process.env.REACT_APP_DOMAIN,
//   eCourier_base_url: 'https://staging.ecourier.com.bd/api',
//   eCourier_api_key: '34PK',
//   eCourier_api_secret: 'PGE5w',
//   eCourier_user_id: 'u6013'
// };


///HELPER.JS
function getCookie(name) {
    let nameEQ = name + "=";
    let cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
        let cookie = cookiesArray[i];
        while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length);
        if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length);
    }
    return null; // Return null if the cookie is not found
}


export const baseUrl = {
    url: `https://${getCookie('domain')}/api`,
    img: `https://${getCookie('domain')}`,
    token: getCookie("api_key"),
    domain: process.env.REACT_APP_DOMAIN,
    eCourier_base_url: 'https://backoffice.ecourier.com.bd/api',
    eCourier_api_key: 'YrWD',
    eCourier_api_secret: 'RI0UU',
    eCourier_user_id: 'u6013'
};


export const sliceWord = (word) => {
    if (word.length === 0) {
        return ["", ""];
    } else {
        const firstLetter = word[0];
        const restLetters = word.slice(1);
        return [firstLetter, restLetters];
    }
};
export const getFromCookie = (name) => {
    const items = document.cookie.split(";");
    return items.find((item) => item.includes(name))?.split("=")[1];
};

export const saveInCookie = (name, value) => {
    document.cookie = `${name}=${value}`;
};

export const removeToken = (arr) => {
    arr.map((token) => {
        return (document.cookie = `${token}=''`);
    });
};
export const convertedDate = (string) => {
    const date = new Date(string);
    return date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
};
export const footerMenu = [
    {
        id: 1,
        heading: "Information",
        links: [
            {
                linkName: "Home",
                route: "/",
            },

            {
                linkName: "Contact us",
                route: "/contact",
            },
            {
                linkName: "FAQ",
                route: "/faq",
            },
        ],
    },
    {
        id: 2,
        heading: "Customer service",
        links: [
            {
                linkName: "Search/filter product",
                route: "/products",
            },
            {
                linkName: "All products",
                route: "/products",
            },

            {
                linkName: "My cart",
                route: "/cart",
            },
            {
                linkName: "Orders",
                route: "/profile/order",
            },
        ],
    },
    {
        id: 3,
        heading: "My account",
        links: [
            {
                linkName: "Create an account",
                route: "/register",
            },
            {
                linkName: "Forgot password",
                route: "/login",
            },
            {
                linkName: "Profile",
                route: "/profile",
            },
        ],
    },
];
export const profileMenus = [
    {
        tabname: "Profile Informations",
        route: "/profile",
    },
    {
        tabname: "Shipping Address",
        route: "/profile/shipping",
    },
    {
        tabname: "All Orders",
        route: "/profile/order",
    },
    {
        tabname: "Purchase History",
        route: "/profile/history",
    },
];

export const sorting = [
    {
        sortName: "Low to high",
        value: "price_low_to_high",
    },
    {
        sortName: "High to low",
        value: "price_high_to_low",
    }
];

export const navItems = [
    {
        page: "Home",
        route: "/",
    },
    {
        page: "Products",
        route: "/products",
    },
    // {
    //   page: "About us",
    //   route: "/about-us",
    // },
    // {
    //   page: "Contact us",
    //   route: "/contact",
    // },
];
export const errorNotify = (msg) => {
    toast.error(msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
};
export const successNotify = (msg) => {
    toast.success(msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
};

// export const reviewbreakpoints = {
//     640: {
//         slidesPerView: 2,
//         spaceBetween: 10,
//     },
//     768: {
//         slidesPerView: 4,
//         spaceBetween: 10,
//     },
//     1024: {
//         slidesPerView: 3,
//         spaceBetween: 10,
//     },
// };

// export const reviews2 = [
//   {
//     id: 1,
//     name: "Samuel Doe",
//     designation: "Professor",
//     img: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
//     rating: 4,
//   },
//   {
//     id: 2,
//     name: "Luna Rosario",
//     designation: "Business man",
//     img: "https://media.istockphoto.com/photos/close-up-portrait-of-brunette-woman-picture-id1154642632?b=1&k=20&m=1154642632&s=612x612&w=0&h=StstyxyDdiJQgUSTlaWv2ITXSMb029KzXijHtsaTQEg=",
//     rating: 4,
//   },
//   {
//     id: 3,
//     name: "Hank Smith",
//     designation: "Jr.Software Engineer",
//     img: "https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=705",
//     rating: 4,
//   },
//   {
//     id: 4,
//     name: "Samuel Doe",
//     designation: "Professor",
//     img: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
//     rating: 4,
//   },
//   {
//     id: 5,
//     name: "Luna Rosario",
//     designation: "Business man",
//     img: "https://media.istockphoto.com/photos/close-up-portrait-of-brunette-woman-picture-id1154642632?b=1&k=20&m=1154642632&s=612x612&w=0&h=StstyxyDdiJQgUSTlaWv2ITXSMb029KzXijHtsaTQEg=",
//     rating: 4,
//   },
//   {
//     id: 6,
//     name: "Mark Otto",
//     designation: "CEO of Baraz",
//     img: "https://images.ctfassets.net/lh3zuq09vnm2/yBDals8aU8RWtb0xLnPkI/19b391bda8f43e16e64d40b55561e5cd/How_tracking_user_behavior_on_your_website_can_improve_customer_experience.png",
//     rating: 4,
//   },
// ];
export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
//
// export const getSecondaryToken = () => {
//     const tokens = document.cookie.split(";");
//     return tokens
//         .find((tok) => tok.includes("secondary_token="))
//         ?.split("=")[1];
// };
// export const getCustommerToken = () => {
//     const tokens = document.cookie.split(";");
//     return tokens
//         .find((tok) => tok.includes("customer_access_token="))
//         ?.split("=")[1] || "";
// };
//
//
// export const fetchType = (type, token, bodyData = null, json) => {
//     if (bodyData !== null) {
//         const {data, stringify, formData, property} = bodyData;
//         let body = {};
//         let headers = {
//             headers: {
//                 Authorization: `bearer ${token}`,
//             },
//         };
//         if (json) {
//             headers = {
//                 "content-type": "application/json",
//                 ...headers.headers,
//             };
//         }
//         if (stringify) {
//             body = {
//                 body: JSON.stringify(data),
//             };
//         }
//         if (formData) {
//             let formData = new FormData();
//             if (Array.isArray(data)) {
//                 for (let i = 0; i < data.length; i++) {
//                     const element = data[i];
//                     formData.append(property, element);
//                 }
//             } else {
//                 formData.append(property, data);
//             }
//             body = {
//                 body: formData,
//             };
//         }
//         return {
//             method: type,
//             ...headers,
//             ...body,
//         };
//     }
//
//     return {
//         method: type,
//         headers: {
//             Authorization: `bearer ${token}`,
//         },
//     };
// };

export const b64ToPng = (base64String) => {
    let data = base64String.replace(/^data:image\/\w+;base64,/, "");
    let binaryData = window.atob(data);
    let byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
    }
    let blob = new Blob([byteArray], {type: "image/png"});
    return URL.createObjectURL(blob)
};

export const bgs = [
    "bg-green-100",
    "bg-yellow-100",
    "bg-orange-100",
    "bg-red-100",
    "bg-sky-100",
    "bg-indigo-100",
    "bg-sky-100",
    "bg-red-100",
    "bg-orange-100",
    "bg-yellow-100",
    "bg-green-100",
    "bg-yellow-100",
    "bg-orange-100",
    "bg-red-100",
    "bg-sky-100",
    "bg-indigo-100",
    "bg-sky-100",
    "bg-red-100",
    "bg-orange-100",
    "bg-yellow-100",
    "bg-green-100",
    "bg-yellow-100",
    "bg-orange-100",
    "bg-red-100",
    "bg-sky-100",
    "bg-indigo-100",
    "bg-sky-100",
    "bg-red-100",
    "bg-orange-100",
    "bg-yellow-100",
    "bg-green-100",
    "bg-yellow-100",
    "bg-orange-100",
    "bg-red-100",
    "bg-sky-100",
    "bg-indigo-100",
    "bg-sky-100",
    "bg-red-100",
    "bg-orange-100",
    "bg-yellow-100",
    "bg-green-100",
    "bg-yellow-100",
    "bg-orange-100",
    "bg-red-100",
    "bg-sky-100",
    "bg-indigo-100",
    "bg-sky-100",
    "bg-red-100",
    "bg-orange-100",
    "bg-yellow-100",
    "bg-green-100",
    "bg-yellow-100",
    "bg-orange-100",
    "bg-red-100",
    "bg-sky-100",
    "bg-indigo-100",
    "bg-sky-100",
    "bg-red-100",
    "bg-orange-100",
    "bg-yellow-100",
    "bg-green-100",
    "bg-yellow-100",
    "bg-orange-100",
    "bg-red-100",
    "bg-sky-100",
    "bg-indigo-100",
    "bg-sky-100",
    "bg-red-100",
    "bg-orange-100",
    "bg-yellow-100",
    "bg-green-100",
    "bg-yellow-100",
    "bg-orange-100",
    "bg-red-100",
    "bg-sky-100",
    "bg-indigo-100",
    "bg-sky-100",
    "bg-red-100",
    "bg-orange-100",
    "bg-yellow-100",
    "bg-green-100",
];