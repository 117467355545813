import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css/navigation";
import "swiper/css/bundle";
import { baseUrl } from "../../../Hooks/Helper";
import SectionHeading4 from "../../SectionHeadings/SectionHeading4/SectionHeading4";
const Sponsor5 = ({ data }) => {
  const data2 = {
    subheading: "Building Together",
    heading: "The Role of Sponsors",
    paragraph: " in Our Journey",
  };
  return (
    <section className="py-8 lg:py-16">
      <div className="container">
        <SectionHeading4 data={data2} />
        <div className="mt-3">
          <Swiper
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
            autoplay={{
              delay: 5500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
          >
            {data?.length > 0 ? (
              data.map((sponsor, i) => (
                <SwiperSlide key={i}>
                  <div className=" h-[126px] w-full">
                    <img
                      src={
                        sponsor?.image
                          ? `${baseUrl.img}/${sponsor.image}`
                          : "https://placehold.co/210x126"
                      }
                      alt="slider"
                      className="w-auto h-full mx-auto"
                    />
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <div className=" h-[126px] w-full">
                  <img
                    src="https://placehold.co/210x126"
                    alt="placeholder"
                    className="w-auto h-full mx-auto"
                  />
                </div>
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Sponsor5;
