import React from "react";
import { baseUrl } from "../../../Hooks/Helper";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";

const Sponsor7 = ({ data }) => {
  const data2 = {
    heading: "Sponsorship Showcase",
    paragraph: "Highlighting Our Benefactors",
  };
  return (
    <section className="my-2 lg:my-16">
      <div className="container">
        <SectionHeading2 data={data2} />

        <div className="grid grid-cols-2 lg:grid-cols-6 md:grid-cols-4 items-center gap-1 mt-2">
          {data?.length > 0 ? (
            data.map((sponsor, i) => (
              <div
                className=" h-[126px] w-full border dark:border-gray-800"
                key={i}
              >
                <img
                  src={
                    sponsor?.image
                      ? `${baseUrl.img}/${sponsor.image}`
                      : "https://placehold.co/210x126"
                  }
                  alt="sponsors"
                  className="w-auto h-full mx-auto"
                />
              </div>
            ))
          ) : (
            <div className=" h-[126px] w-full border dark:border-gray-800">
              <img
                src="https://placehold.co/210x126"
                alt="placeholder"
                className="w-auto h-full mx-auto"
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Sponsor7;
