import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";


const initialState = {
    loading: false,
    err: null
}

export const fetchUpdateProfilePhoto = createAsyncThunk("updatePhoto/profilePhoto", async({custommerToken,formData}) =>{
    try {
        const res = await fetch(`${baseUrl.url}/user/avatar-update`, {
            method: "POST",
            headers: {
              Authorization: `bearer ${custommerToken}`,
            },
            body: formData,
        })
        const resData = await res.json();
        return resData
    } catch (error) {
        return error
    }
   
})

const profileSlice = createSlice({
    name: "profilePhotoUpdate",
    initialState,
    extraReducers:(builder =>{
        builder.addCase(fetchUpdateProfilePhoto.pending, state =>{
            state.loading = true
        }).addCase(fetchUpdateProfilePhoto.fulfilled, (state, action) =>{
            state.loading = false
            state.err = null
        }).addCase(fetchUpdateProfilePhoto.rejected, (state, action) =>{
            state.loading = false
            state.err = action.payload
        })
    })



})
export default  profileSlice.reducer;