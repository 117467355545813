import React from 'react';
import ProductCard2 from '../../ProductContainers/ProductContainer2/ProductCard2/ProductCard2';
import SectionHeading2 from '../../SectionHeadings/SectionHeading2/SectionHeading2';
const NewArrival2 = ({data,flash}) => {
    const data2 = {
        heading:"Fresh Picks",
        paragraph:"Exploring the Latest Arrivals in Style",
        show:data?.length >7 ?true :false
    }
    return (
        <section className='py-8 lg:py-16 bg-gray-100 dark:bg-gray-950'>
            <div className="container">
                <SectionHeading2 data={data2}/>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mt-3">
                    {
                        data?.slice(0,10)?.map((product,i)=><ProductCard2 key={i} product={product} flash={product.is_on_sale===1 ? flash : null}/>)
                    }
                </div>
            </div>
        </section>
    );
};

export default NewArrival2;