import React, { useEffect, useState } from 'react';
import { baseUrl, errorNotify, successNotify } from '../../../Hooks/Helper';
import useStore from '../../../Hooks/useStore';
import './OrderHistory.css'
import Button from '../../Button/Button';
const OrderHistory = () => {
    const {
        custommerToken,
        setUpdate,
        update,
      } = useStore().data;
    const [orderhistoryData, setOrderHistoryData] = useState();
      useEffect(() => {
    if (!custommerToken) {
      return;
    }
    const getWishListInfo = async () => {
      const res = await fetch(`${baseUrl.url}/user/order-list?delivery_status=delivered`, {
        method: "GET",
        headers: {
          Authorization: `bearer ${custommerToken}`,
        },
      });
      const resData = await res.json();
     
      if (res.ok) {
        setOrderHistoryData(resData.data);
      }
    };
    getWishListInfo();
  }, [custommerToken, update, setUpdate]);
    //DOWNLOAD BILL INVOICE
    const handleDownload = async (id) => {
        const res = await fetch(`${baseUrl.url}/user/order/invoice/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "text/csv",
            Authorization: `bearer ${custommerToken}`,
          },
        });
        const data = await res.blob();
        if (res.ok) {
          successNotify('Invoice Download Successfully');
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          errorNotify('Invoice Download Failed');
        }
      };
    return (
        <div className='order-history-section'>
          <h2 className="text-lg text-slate-500 dark:text-white tracking-wider">
            Order history
          </h2>
         
                  {
                    orderhistoryData?.length>0 ?<div className="overflow-scroll lg:overflow-hidden mt-6">
                    <table className="table-auto w-full">
                      <thead>
                        <tr style={{ borderBottom: "1px solid #dddddd" }}>
                          <th className="bg-slate-100 p-2 px-4 text-left">
                           Order ID
                          </th>
                          <th className="bg-slate-100 p-2 px-4 text-left">
                          Total amount
                          </th>
                          <th className="bg-slate-100 p-2 px-4 text-left">
                          Delivery Status
                          </th>
                          <th className="bg-slate-100 p-2 px-4 text-left">
                          Invoice
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderhistoryData?.map((order, i) => {
                          const { order_number,total_amount,delivery_status,id } = order;
                          return (
                            <tr key={i}>
                              <td className="p-2 px-4 text-left dark:text-white">
                               {order_number}
                              </td>
                              <td className="p-2 px-4 text-left dark:text-white">{total_amount}</td>
                              <td className="p-2 px-4 text-left  dark:text-white text-green-500">{delivery_status}</td>
                              <td className="p-2 px-4 text-left">
                                <Button size={"sm"} title={"Download"} func={() =>handleDownload(id)}/>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div> :    <div className='p-5 mt-8'>
                <div className='my-5 text-center dark:text-white'>
                    <h5>No item in order history</h5>
                </div>
            </div>
                  }
                  
        </div>
    );
};

export default OrderHistory;