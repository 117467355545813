import React from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../Hooks/Helper";
import useStore from "../../../Hooks/useStore";
import SectionHeading3 from "../../SectionHeadings/SectionHeading3/SectionHeading3";

const Category3 = ({ categories }) => {
  const navigate = useNavigate();
  const { showDummyImage, setCategoryId } = useStore().data;

  const goPage = (id) => {
    navigate("/products");
    setCategoryId(id);
  };

  return (
    <section className="py-8 lg:py-16">
      <div className="container">
        <SectionHeading3
          title={"All Categories"}
          text={"Browse every product by selecting a category."}
        />
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 lg:gap-4">
          {categories?.map((category, j) => {
            return (
              <div
                key={j}
                className={`bg-gray-100 dark:bg-gray-800 p-4 lg:p-4`}
              >
                <div
                  className=" border-double border-4 p-4 border-gray-300 dark:border-gray-600 rounded-xl cursor-pointer"
                  onClick={() => goPage(category?.id)}
                >
                  <div className="rounded-full w-16 lg:w-16 h-16 xl:w-16 xl:h-16 lg:h-16  mx-auto overflow-hidden border-1 border-gray-600 mb-4">
                    {category?.image ? (
                      <img
                        onError={({ currentTarget }) =>
                          showDummyImage({ currentTarget })
                        }
                        src={`${baseUrl.img}/${category?.image}`}
                        alt=""
                        className="w-full h-full"
                      />
                    ) : (
                      <img
                        onError={({ currentTarget }) =>
                          showDummyImage({ currentTarget })
                        }
                        src={require("../../../assets/product-default-img/dummy.png")}
                        alt=""
                        className="w-full h-full"
                      />
                    )}
                  </div>
                  <h4 className="text-center text-gray-700 dark:text-white  whitespace-nowrap text-sm">
                    {category.name}
                  </h4>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Category3;