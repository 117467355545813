import React, { useEffect } from 'react';
import SectionHeading2 from '../SectionHeadings/SectionHeading2/SectionHeading2';
import { useParams } from 'react-router-dom';
import useStore from '../../Hooks/useStore';
import { baseUrl } from '../../Hooks/Helper';
import ProductsContainer from '../ProductContainers/ProductsContainer/ProductsContainer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomeData } from '../../Redux/Slices/rootSlice/rootSlice';

const SimillarProducts = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.home);
    const {id} = useParams()
    const {
        filteredProducts,
        setFilteredProducts,
        productDetails,
        categoryId, 
        setCategoryId
      } = useStore().data;
 
    setCategoryId(productDetails?.category_id)

    useEffect(() => {
     
      dispatch(fetchHomeData());
   
    }, [dispatch]);

   
      useEffect(() => {
        if (categoryId) {
          const fetchFilterProducts = async () => {
            let url = `${baseUrl.url}/product-filter?per_page=15`
          
            if (categoryId) {
              url += `&category_id=${categoryId}`
              
            }
            const res = await fetch(
              url,
              {
                method: "GET",
                headers: {
                  Authorization: baseUrl.token,
                },
              }
            );
            const resData = await res.json();
            if (res.ok) {
              const filteredData = resData.data.data.filter(
                (product) => product.id !== Number(id)
              );
              setFilteredProducts(filteredData);
            }
          };
          fetchFilterProducts();
        } else {
          return;
        }
      }, [categoryId,setFilteredProducts,id]);
    const data2 = {
        heading:"Simillar products",
        paragraph:"You may also like this products"
    }
    return (
        <div className="my-8 lg:my-16">
            <div className="container">
                <SectionHeading2 data={data2}/>
            </div>
            <ProductsContainer data={filteredProducts} flash={data?.flash_sale}/>
        </div>
    );
};

export default SimillarProducts;