import React from "react";
import "./DiscountProducts.css";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import ProductContainer2 from "../../ProductContainers/ProductContainer2/ProductContainer2";
const DiscountProduct = ({ data,flash }) => {
  const heading = {
    heading: "Celebrate Savings",
    paragraph: " Dive into Our Discounted Delights!",
    show:data?.length >7 ?true :false
  };
  return (
    <section className="py-8 lg:py-16">
      {data?.length > 0 && (
        <div>
          <div className="container">
            <SectionHeading2 data={heading} />
          </div>
          <ProductContainer2 data={data} flash={flash}/>
        </div>
      )}
    </section>
  );
};

export default DiscountProduct;
