import React from 'react'

const SectionHeading4 = ({data}) => {
    const {heading,paragraph,subheading} = data
    return (
        <section className='text-center mb-6'>
            <p className='poppin text-4xl font-bold dark:text-white'>{subheading}</p>
            <h2 className='poppin text-3xl inline border-b-2 border-gray-700 font-semibold dark:text-white'>{heading}</h2>
            <p className='mt-4 poppin dark:text-white'>{paragraph}</p>
        </section>
    );
}

export default SectionHeading4