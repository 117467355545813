import React from "react";
import useStore from "../../../Hooks/useStore";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../Hooks/Helper";
const Category9 = ({ categories }) => {
  const { showDummyImage, setCategoryId } = useStore().data;
  const navigate = useNavigate();
  const goPage = (id) => {
    navigate("/products");
    setCategoryId(id);
  };

  return (
    <section className="py-8 lg:py-16">
      <div className="container">
        <div>
          <h1 className="text-2xl dark:text-white">
            <span className="text-black font-bold dark:text-white">
              The Pursuit
            </span>{" "}
            of Perfect Categories
          </h1>
        </div>
        <div className="border-t border-b mt-1 ">
          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-8 px-4 py-6 gap-4 flex-wrap ">
            {categories?.length > 0 &&
              categories?.map((cat, i) => (
                <div key={i} onClick={() => goPage(cat?.id)} className="">
                  {cat?.image ? (
                    <img
                      onError={({ currentTarget }) =>
                        showDummyImage({ currentTarget })
                      }
                      src={`${baseUrl.img}/${cat?.image}`}
                      alt="img"
                      style={{ objectFit: "cover" }}
                      className="max-h-[80px] w-auto mx-auto"
                    />
                  ) : (
                    <img
                      onError={({ currentTarget }) =>
                        showDummyImage({ currentTarget })
                      }
                      src={require("../../../assets/product-default-img/dummy.png")}
                      alt="img"
                      style={{ objectFit: "cover" }}
                      className="max-h-[80px] w-full"
                    />
                  )}

                  <span className="flex items-center justify-center whitespace-nowrap text-sm dark:text-white mt-1">
                    {cat?.name}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Category9;