import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";

const initialState = {
    customerOrderDetails: {},
    loading: false,
    err: null,
    updates: false,
}
export const fetchAllcustomerOrderDetails = createAsyncThunk("fetchcustomerOrderDetails/customerOrderDetails", async({id,custommerToken}) =>{
    const res = await fetch(`${baseUrl.url}/user/order-detail/${id}`, {
        method: 'GET',
        headers: {
            'Authorization':  `bearer ${custommerToken}`
        },
    })
    const resData = await res.json();
    return resData.data
    
})

const customerOrderDetailsSlice = createSlice({
    name: "customerOrderDetails",
    initialState,
    extraReducers:(builder =>{
        builder.addCase(fetchAllcustomerOrderDetails.pending, state =>{
            state.loading = true
        }).addCase(fetchAllcustomerOrderDetails.fulfilled, (state, action) =>{
            state.loading = false
            state.customerOrderDetails = action.payload
            state.err = null
        }).addCase(fetchAllcustomerOrderDetails.rejected, (state, action) =>{
            state.loading = false
            state.customerOrderDetails = {}
            state.err = action.payload
        })
    })



})
export default  customerOrderDetailsSlice.reducer;