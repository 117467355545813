import React, { useState } from "react";
import { BsEnvelopeOpen, BsGenderFemale } from "react-icons/bs";
import { IoIdCardOutline } from "react-icons/io5";
import { FaAddressCard } from "react-icons/fa";
import { FcUpload } from "react-icons/fc";
import { CiEdit } from "react-icons/ci";
import { GiClick } from "react-icons/gi";
import { BiLoader } from "react-icons/bi";

import { useDispatch } from "react-redux";
import { fetchUpdateProfilePhoto } from "../../../Redux/Slices/Profile/profileSlice";
import { useNavigate } from "react-router-dom";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { ToastContainer } from "react-toastify";
import {
  baseUrl,
  errorNotify,
  removeToken,
  successNotify,
} from "../../../Hooks/Helper";
import useStore from "../../../Hooks/useStore";
const ProfileBanner = () => {
  const {
    custommerToken,
    showDummyImage,
    setUpdate,
    update,
    customerInfo,
    setCustommerToken,
  } = useStore().data;
  const navigate = useNavigate();
  const [profileImg, setProfileImg] = useState(null);
  const [visibleVerifyCodeModal, setVisibleVerifyCodeModal] = useState(false);
  const [visibleShare, setVisibleShare] = useState(false);
  const [visiblePhotoModal, setVisiblePhotoModal] = useState(false);
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const userDeactived = async () => {
    if (custommerToken && custommerToken?.length > 20) {
      const res = await fetch(`${baseUrl.url}/user/delete-account`, {
        method: "DELETE",
        headers: {
          Authorization: `bearer ${custommerToken}`,
        },
      });
      if (res.ok) {
        setCustommerToken("");
        setUpdate(!update);
        navigate("/");
        successNotify("Your account is deactivate successfully");
      }
      removeToken(["isLogin", "customer_access_token", "remember_me_token"]);
    }
  };

  const logOut = async () => {
    const res = await fetch(`${baseUrl.url}/user/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: `bearer ${custommerToken}`,
      },
    });
    if (res.ok) {
      setCustommerToken("");
      setUpdate(!update);
    }
    removeToken(["isLogin", "customer_access_token", "remember_me_token"]);
    navigate("/login");
  };

  const updatePhoto = async () => {
    setIsLoading(true);
    if (!avatar) {
      errorNotify("Avatar is required");
    }
    const formData = new FormData();
    formData.append("avatar", avatar);

    const result = await dispatch(
      fetchUpdateProfilePhoto({ custommerToken, formData })
    );

    setIsLoading(false);

    if (result.payload.status) {
      setVisiblePhotoModal(false);
      const url = URL.createObjectURL(avatar);
      setProfileImg(url);
    }
  };

  const handleGetCode = async () => {
    try {
      setVisibleVerifyCodeModal(!visibleVerifyCodeModal);
      const res = await fetch(`${baseUrl.url}/user/send-verification-code`, {
        method: "GET",
        headers: {
          Authorization: `bearer ${custommerToken}`,
        },
      });
      if (res.ok) {
        successNotify("Verification code send your email successfully ");
      } else {
        const data = await res.json();
        errorNotify(data.errors[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmitCode = async (e) => {
    e.preventDefault();
    const code = e.target.code?.value;
    if (!code) {
      errorNotify("Email verification code is required");
    }
    try {
      const res = await fetch(`${baseUrl.url}/user/verify-email`, {
        method: "POST",
        headers: {
          "Content-type": "Application/json",
          Authorization: `bearer ${custommerToken}`,
        },
        body: JSON.stringify({ code: Number(code) }),
      });
      if (res.ok) {
        successNotify("Your email verified successfully");
        setUpdate(!update);
        setVisibleVerifyCodeModal(!visibleVerifyCodeModal);
      } else {
        const data = await res.json();
        errorNotify(data.errors[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Email verify modal */}
      <Rodal
        visible={visibleVerifyCodeModal}
        onClose={() => {
          setVisibleVerifyCodeModal(!visibleVerifyCodeModal);
        }}
        animation={"rotate"}
        customStyles={{
          minWidth: "350px",
          height: "auto",
          width: "35%",
          bottom: "auto",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <form onSubmit={handleSubmitCode}>
          <div className="p-2">
            <label>Verification Code</label>
            <input
              name="code"
              className="border border-gray-300 w-full p-2 focus:outline-none"
              placeholder="code"
            />
            <input
              type="submit"
              value={"Submit"}
              className="default-theme-button rounded bg-red-400 border py-1 px-3 text-black mt-3 "
            />
          </div>
        </form>
      </Rodal>
      <Rodal
        visible={visibleShare}
        onClose={() => {
          setVisibleShare(!visibleShare);
        }}
        animation={"zoom"}
        customStyles={{
          minWidth: "350px",
          height: "auto",
          width: "20%",
          bottom: "auto",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <div className="p-2">
          <p className="">Are you agree to deactivate?</p>
          <div className="flex justify-end items-center mt-3">
            <button
              className="text-sm  bg-red-500 text-white py-1 px-3 rounded"
              onClick={() => {
                setVisibleShare(!visibleShare);
              }}
            >
              Cancel
            </button>
            <button
              className="ml-2 text-sm  bg-green-500 text-white py-1 px-3 rounded"
              onClick={() => userDeactived()}
            >
              Deactivate
            </button>
          </div>
        </div>
      </Rodal>

      <Rodal
        visible={visiblePhotoModal}
        onClose={() => setVisiblePhotoModal(!visiblePhotoModal)}
        customStyles={{
          height: "auto",
          bottom: "auto",
          top: "40%",
          transform: "translateY(-50%)",
        }}
      >
        <h3 className="text-base border-b py-1">Update profile photo</h3>
        <hr />
        <div className="py-3">
          <input type="file" onChange={(e) => setAvatar(e.target.files[0])} />
          <br />
          <button
            type="button"
            disabled={isLoading && true}
            onClick={updatePhoto}
            className={`flex justify-center items-center gap-2  transition ease-in duration-200  rounded  border py-1 px-3 text-white text-sm mt-3 cursor-pointer ${
              isLoading ? "bg-green-300" : "bg-green-500"
            }`}
          >
            {isLoading && <BiLoader size={20} />}
            Update
          </button>
        </div>
      </Rodal>
      <div className="grid grid-cols-12 gap-8 pb-8 border-b">
        <div className="col-span-12 lg:col-span-3">
          <div className="overflow-hidden lg:h-64  w-full relative group">
            {customerInfo?.user_data?.profile?.image ? (
              <img
                src={
                  profileImg ||
                  `${baseUrl.img}${customerInfo?.user_data?.profile?.image}`
                }
                alt=""
                className="w-full h-full object-cover"
              />
            ) : (
              <img
                onError={({ currentTarget }) =>
                  showDummyImage({ currentTarget })
                }
                src="https://placehold.co/304x256"
                alt=""
                className="w-full h-full object-cover"
              />
            )}

            <div className="h-full w-full absolute top-0 left-0 flex items-center justify-center bg-gray-100 opacity-0 group-hover:opacity-100">
              <FcUpload
                onClick={() => setVisiblePhotoModal(true)}
                size={35}
                className="cursor-pointer text-white"
              />
            </div>
          </div>
        </div>

        <div className="col-span-12 lg:col-span-9">
          <div>
            <div className="flex justify-between">
              <div>
                <h5 className="flex items-center dark:text-white text-base lg:text-2xl font-bold text-slate-700">
                  {customerInfo?.user_data?.name}
                </h5>
                <div className="flex items-center">
                  <p className="dark:text-white">
                    {customerInfo?.user_data?.username}
                  </p>
                  {customerInfo?.user_data?.email_verified_at ? (
                    <p
                      className="ml-4 text-xs px-2 py-1 bg-green-400 text-white"
                      style={{
                        clipPath:
                          "polygon(10% 0, 100% 0, 100% 100%, 10% 100%, 0 50%)",
                      }}
                    >
                      Verified
                    </p>
                  ) : (
                    <button
                      onClick={handleGetCode}
                      className="ml-4 text-xs px-2 ps-3 py-1 bg-red-500 text-white flex items-center"
                      style={{
                        clipPath:
                          "polygon(10% 0, 100% 0, 100% 100%, 10% 100%, 0 50%)",
                      }}
                    >
                      Verify now <GiClick size={18} className="ml-1 move" />
                    </button>
                  )}
                </div>
              </div>
              <button
                className="dark:text-black"
                onClick={() => navigate("/profile/update")}
              >
                <CiEdit size={25} className="dark:text-white" />
              </button>
            </div>
            <div className="my-7 grid grid-cols-2 lg:grid-cols-3 bg-white dark:bg-slate-800 gap-4">
              <div className="flex items-center">
                <div className="w-8 h-8 p-2 lg:p-0 md:p-0 xl:p-0 bg-blue-100 dark:bg-blue-500 flex items-center rounded text-blue-500 dark:text-white justify-center">
                  <BsEnvelopeOpen />
                </div>
                <div className="ml-2">
                  <h3 className="text-slate-900 dark:text-white text-sm lg:text-base">
                    Email Address
                  </h3>
                  <p className="text-slate-500 dark:text-white lg:text-sm md:text-sm text-xs ">
                    {customerInfo?.user_data?.username}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-8 h-8 bg-teal-100 dark:bg-teal-500 flex items-center rounded text-teal-500 dark:text-white justify-center">
                  <IoIdCardOutline className="" />
                </div>
                <div className="ml-2">
                  <h3 className="text-slate-900 dark:text-white text-sm lg:text-base">
                    Phone
                  </h3>
                  <p className="text-slate-500 dark:text-white lg:text-sm md:text-sm text-xs">
                    {customerInfo?.user_data?.phone}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-8 h-8 bg-sky-100 dark:bg-sky-500 flex items-center rounded text-sky-500 dark:text-white justify-center">
                  <BsGenderFemale className="" />
                </div>
                <div className="ml-2">
                  <h3 className="text-slate-900 dark:text-white text-sm lg:text-base">
                    Gender
                  </h3>
                  <p className="text-slate-500 dark:text-white lg:text-sm md:text-sm text-xs">
                    {customerInfo?.user_data?.profile?.gender?.name}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-8 h-8 bg-indigo-100 dark:bg-indigo-500 flex items-center rounded text-blue-500 dark:text-white justify-center">
                  <FaAddressCard className="" />
                </div>
                <div className="ml-2">
                  <h3 className="text-slate-900 dark:text-white text-sm lg:text-base">
                    Address
                  </h3>
                  <p className="text-slate-500 dark:text-white lg:text-sm md:text-sm text-xs p-1 md:p-0 lg:p-0 xl-0 ">
                    {customerInfo?.other?.shipping_address_count} Shipping
                    Address
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-8 h-8 bg-purple-100 dark:bg-purple-500 flex items-center rounded text-purple-500 dark:text-white justify-center">
                  <FaAddressCard className="" />
                </div>
                <div className="ml-2">
                  <h3 className="text-slate-900 dark:text-white text-sm lg:text-base">
                    Orders
                  </h3>
                  <p className="text-slate-500 dark:text-white lg:text-sm md:text-sm text-xs">
                    {customerInfo?.other?.order_count} orders
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-8 h-8 bg-green-100 dark:bg-green-500 flex items-center rounded text-green-500 dark:text-white justify-center">
                  <FaAddressCard />
                </div>
                <div className="ml-2">
                  <h3 className="text-slate-900 dark:text-white text-sm lg:text-base">
                    Order History
                  </h3>
                  <p className="text-slate-500 dark:text-white lg:text-sm md:text-sm text-xs">
                    {customerInfo?.other?.completed_order_count} items
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              className="bg-gray-100 mr-2 py-1 px-3"
              onClick={() => {
                setVisibleShare(!visibleShare);
              }}
            >
              Deactivate
            </button>
            <button
              className="bg-red-500 text-white mr-2 py-1 px-3"
              onClick={logOut}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBanner;
