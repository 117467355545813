import React from "react";
import ProductView from "./ProductView";
import BreadCumb from "../BreadCumb/BreadCumb";
import ProductDetailsInfo from "../ProductDetailsInfo/ProductDetailsInfo";
import SectionHeading2 from "../SectionHeadings/SectionHeading2/SectionHeading2";
import { BsStarFill } from "react-icons/bs";
import useStore from "../../Hooks/useStore";
const ProductDetailsBanner = () => {
  const {
    productDetails,
  } = useStore().data;
  // breadcrumb data
  const navigationData = [
    {
      page: "Home",
      route: "/",
    },
    {
      page: "Products",
      route: "/products",
    },
    {
      page: "Details",
      route: "#",
    },
  ];
  return (
    <section className="product-details-banner product-container-color p-0">
      <div className="container pt-6">
        <SectionHeading2
          data={{ heading: "Product Details", paragraph: null }}
        />
        <BreadCumb data={navigationData} />
        <ProductView/>
        <div className="grid grid-cols-12 gap-2 bg-white dark:bg-gray-800">
          <div className="col-span-12 lg:col-span-3 order-2 lg:order-1">
            <div className="p-2">
              <div className="bg-dark rounded p-2 text-light">
                <h6 className="dark:text-white">Average Ratings</h6>
                <p className="text-xs dark:text-white">Product average rating given by our customers</p>
                <div className="pt-2">
                  <h2 className="text-xl dark:text-white mb-8"><span className="text-2xl font-bold">{Math.round(productDetails?.product_review_rating?.length> 0 ? productDetails?.product_review_rating[0]?.avg_review_rating : 0)}</span>/5</h2>
                  <div className="flex items-center mb-2">
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-yellow-500"/>
                  </div>
                  <div className="flex items-center mb-2">
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-slate-300"/>
                  </div>
                  <div className="flex items-center mb-2">
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-slate-300"/>
                    <BsStarFill className="mr-1 text-slate-300"/>
                  </div>
                  <div className="flex items-center mb-2">
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-slate-300"/>
                    <BsStarFill className="mr-1 text-slate-300"/>
                    <BsStarFill className="mr-1 text-slate-300"/>
                  </div>
                  <div className="flex items-center mb-2">
                    <BsStarFill className="mr-1 text-yellow-500"/>
                    <BsStarFill className="mr-1 text-slate-300"/>
                    <BsStarFill className="mr-1 text-slate-300"/>
                    <BsStarFill className="mr-1 text-slate-300"/>
                    <BsStarFill className="mr-1 text-slate-300"/>
                  </div>
                </div>

                
                
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-9 order-1 lg:order-2">
            <div className="p-2">
              <ProductDetailsInfo />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDetailsBanner;
