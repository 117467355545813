import React from "react";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import SideImage from "../../SideImage/SideImage";
import { baseUrl } from "../../../Hooks/Helper";
const NewArrival = ({ data,site_banners,flash }) => {
  
  const text = "Learn about the newest items that redefine style and innovation. Our new selection, which has been carefully chosen for you as well, will expand the choices you have. Shop right away to embrace the future of fashion and stay on top of trends!"
  return (
    <section className="py-8 lg:py-16">
      <div className="container">
        <div className="grid grid-cols-12 gap-4 mt-4">
        <SideImage image={site_banners?.new_arrival_image1 ? `${baseUrl.img}/${site_banners?.new_arrival_image1}` : 'https://placehold.co/243x306'} title={'New Arrival'} text={text} background={'bg-white dark:bg-gray-800'}/>
          <div className="col-span-12 lg:col-span-9">
            <div className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-1 lg:gap-4">
              {data?.slice(0, 8).map((product, i) => (
                <div key={i}>
                  <ProductCard product={product} flash={product.is_on_sale===1 ? flash : null} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewArrival;
