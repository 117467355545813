import React from "react";
import { useNavigate } from "react-router-dom";
import ProductCard2 from "../../Cards/ProductCard2/ProductCard2";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import CoutDown from "../../CountDowns/CoutDown/CoutDown";

const FlashSale7 = ({ data, flash }) => {
  const navigate = useNavigate();

  const goAllPage = () => {
    navigate("/products");
  };
  const pageHeading = {
    heading: "Don't Wait Shop Now",
    paragraph: "Flash Sale Delights Await!",
    action: goAllPage,
    show: data?.length > 7 ? true : false,
  };
  const endDate = new Date(flash?.end_date);
  return (
    <section className="my-8 lg:my-16">
      {data?.length > 0 && (
        <div className="container">
          <SectionHeading2 data={pageHeading} />
          <div className="w-full lg:w-3/12 mt-4">
            <CoutDown endDate={endDate} />
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-3">
            {data
              ?.slice(0, 12)
              ?.reverse()
              ?.map((product, i) => (
                <div key={i}>
                  <ProductCard2
                    product={product}
                    flash={flash ? flash : null}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default FlashSale7;
