import React, { useState } from "react";
import { LuMailOpen } from "react-icons/lu";
import { ToastContainer } from "react-toastify";
import { baseUrl, errorNotify, successNotify } from "../../../Hooks/Helper";
import Button from "../../Button/Button";
const Subscription4 = () => {
  const [email, setEmail] = useState("");

  const handlesubs = async () => {
    if (email.length < 7 || !email.includes("@")) {
      errorNotify("Email is required");
      return;
    }
    const formData = new FormData();
    formData.append("email", email);
    const res = await fetch(`${baseUrl.url}/subscribe`, {
      method: "POST",
      headers: {
        Authorization: baseUrl.token,
      },
      body: formData,
    });
    const resData = await res.json();
    if (resData.status) {
      successNotify("Email subscribed successfully");
      setEmail("");
    } else {
      errorNotify(resData?.errors[0]);
    }
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={50000}
        hideProgressBar={false}
        className="shadow-none"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className="py-8 lg:py-16">
        <div className="container">
          <div className=" border border-slate-200">
            <div className="grid grid-cols-12 p-2 lg:p-8">
              <div className="lg:col-span-2 col-span-12">
                <div className="flex gap-4 items-center">
                  <LuMailOpen size={35} className="dark:text-white" />
                  <div>
                    <h4 className=" text-base dark:text-white">Experience </h4>
                    <h4 className=" text-base dark:text-white">The Difference</h4>
                  </div>
                </div>
              </div>
              <div className="col-span-1 lg:border-l lg:border-l-slate-200"></div>
              <div className="lg:col-span-4 col-span-12">
                <div className="flex items-center justify-start mt-2">
                  <p className=" font-normal text-base dark:text-white">
                  Subscribe to Our Newsletter for a Fresh Perspective on News and Events
                  </p>
                </div>
              </div>
              <div className="lg:col-span-5 col-span-12">
                <div className="border p-1 rounded  w-full mt-2">
                  <div className="grid grid-cols-12 items-center">
                    <div className="col-span-8">
                      <input
                        placeholder="Your Email..."
                        type="text"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className=" bg-transparent text-start items-center h-full ml-3 focus:outline-none dark:text-white"
                      />
                    </div>
         
                      {/* <button
                        className="bg-orange-500   hover:bg-orange-300 py-1 px-4 lg:py-2 dark:text-black text-white w-full border-1 rounded"
                        onClick={handlesubs}
                      >
                        Subscribe
                      </button> */}
                          <Button  size={"md"} title={"Subscribe"} func={handlesubs} classes={'col-span-4'}/>
              
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscription4;
