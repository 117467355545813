import React, { useEffect, useState } from "react";
import SectionHeading2 from "../SectionHeadings/SectionHeading2/SectionHeading2";
import {
  baseUrl,
  errorNotify,
  getFromCookie,
  successNotify,
} from "../../Hooks/Helper";
import useStore from "../../Hooks/useStore";
import { ToastContainer } from "react-toastify";
import {
  fetchAllwishList,
  setUpdateWishlist,
} from "../../Redux/Slices/Wishlist/wishlistSlice";
import { useDispatch, useSelector } from "react-redux";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import AllProductLoader from "../Loaders/AllProductLoader/AllProductLoader";
import { BsShare } from "react-icons/bs";
import BreadCumb from "../BreadCumb/BreadCumb";
import Button from "../Button/Button";
const WishlistContainer = () => {
  const data = {
    heading: "Wishlist",
    paragraph: "Product of wishlist",
  };
  const dispatch = useDispatch();
  const isLogin = getFromCookie("isLogin");
  const { custommerToken, update, setUpdate, customerInfo} = useStore().data;
  const { wishlist, updates, loading } = useSelector(
    (state) => state?.wishlist
  );
  const [selectedWishlist, setSelectedWishlist] = useState([]);
  const verified = customerInfo?.user_data?.email_verified_at
  
  useEffect(() => {
    if (!custommerToken ||  !verified) {
      return;
    }
    dispatch(fetchAllwishList({ custommerToken }));
  }, [dispatch, custommerToken, updates, update, verified]);
  //WISHLIST DATA
  useEffect(() => {
    setSelectedWishlist(wishlist?.length > 0 && wishlist[0]);
  }, [wishlist]);

  const deletewish = async (id) => {
    if (custommerToken && custommerToken?.length > 20) {
      const res = await fetch(`${baseUrl.url}/user/wish-delete/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `bearer ${custommerToken}`,
        },
      });
      if (res.ok) {
        successNotify("wishlist deleted successfully!");
        setUpdate(!update);
        dispatch(setUpdateWishlist());
      }
    }
  };
  const convertWishToCart = async (id) => {
    if (custommerToken && custommerToken?.length > 20) {
      const res = await fetch(`${baseUrl.url}/user/convert-to-cart/${id}`, {
        method: "GET",
        headers: {
          Authorization: `bearer ${custommerToken}`,
        },
      });
      if (res.ok) {
        successNotify("Your wishlist products is added to cart!");
        setUpdate(!update);
        dispatch(setUpdateWishlist());
      }
    }
  };
  const [visible, setVisible] = useState(false);
  const [wishListId, setWishListId] = useState();
  const [emaildata, setEmailData] = useState({});
  const changeText = (e) => {
    setEmailData({
      ...emaildata,
      [e.target.name]: e.target.value,
    });
  };
  const shareWishList = async () => {
    const postData = {
      email: emaildata.email,
    };
    if(!postData?.email){
      errorNotify("Email is required")
      return
    }

    if (isLogin !== undefined || isLogin !== null) {
      const res = await fetch(
        `${baseUrl.url}/user/wish-list-send/${wishListId}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `bearer ${custommerToken}`,
          },
          body: JSON.stringify(postData),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        setVisible(!visible);

        successNotify("Wishlist shared successfully");
      } else {
        resData.errors.map((err) => errorNotify(err));
      }
    }
  };

  if (loading) {
    return <AllProductLoader />;
  }

  const navigationData = [
    {
      page: "Home",
      route: "/",
    },
    {
      page: "Wishlist",
      route: "/wishlist",
    },
    {
      page: "Items",
      route: "#",
    },
  ];


  return (
    <section className="py-8 lg:py-16">
      <Rodal
        visible={visible}
        onClose={() => setVisible(!visible)}
        animation={"fade"}
        customStyles={{
          minWidth: "400px",
          height: "auto",
          width: "25%",
          bottom: "auto",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <div className="rodal-width p-2 text-slate-800">
          <h2 className="text-base">Share Wishlist</h2>
          <div>
            <div className="mt-2">
              {/* <label className="text-sm " htmlFor="">
                Email
              </label> */}
              <input
                onChange={(e) => changeText(e)}
                name="email"
                className="w-full focus:outline-none bg-gray-200 p-2 rounded"
                placeholder="Email Address"
                type="email"
              />
              <Button size={"md"} classes={"mt-4"} func={shareWishList} title={"Submit"}/>
            </div>
          </div>
        </div>
      </Rodal>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="container">
        <div className="bg-white p-4 dark:bg-gray-800">
          <SectionHeading2 data={data} />
          <BreadCumb data={navigationData} />
          {wishlist?.length > 0 ? (
            <div className="grid grid-cols-12 gap-0 mt-10">
              <div className="col-span-12 lg:col-span-3">
                <h1 className="text-lg text-gray-700 dark:text-white">
                  Name
                </h1>
                <div className="border-r border-t">
                  {wishlist?.map((wish, i) => {
                    return (
                      <div
                        key={i}
                        className={`w-full mb-2 hover:bg-gray-200 hover:dark:bg-gray-600 p-2 group ${selectedWishlist?.id === wish?.id && "bg-gray-200 dark:bg-gray-600"}`}
                      >
                        <div
                          className={`text-left dark:text-white flex gap-1 items-center justify-between cursor-pointer `}
                        >
                          <div className="">
                            <button
                              onClick={() => {
                                setSelectedWishlist(wish);
                              }}
                            >
                              {i + 1} . {wish?.title}
                              <span className="ml-3 text-xs text-gray-500 dark:text-white">
                                {wish?.items?.length} items
                              </span>
                            </button>
                          
                          </div>
                          <span
                              className={`group-hover:opacity-100 text-xs text-red-600 ml-1 ${selectedWishlist?.id === wish?.id ? "opacity-100" : "opacity-0"}`}
                              onClick={() => deletewish(wish?.id)}
                            >
                              Remove
                            </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-span-12 lg:col-span-9">
                <h1 className="text-lg text-gray-700 dark:text-white">
                 Items
                </h1>
                <div className="border-t pl-2 overflow-auto">
                  <div className="my-1 flex gap-4">
                    <button
                      className="text-red-400 text-sm"
                      onClick={() => convertWishToCart(selectedWishlist?.id)}
                    >
                      Add to Cart
                    </button>
                    <button
                            className="flex gap-1 items-center text-sky-600 text-sm"
                            onClick={() => {
                              setVisible(!visible);
                              setWishListId(selectedWishlist?.id);
                            }}
                          >
                           <BsShare/> Share
                          </button>
                     
                  </div>
                  {selectedWishlist?.items?.length > 0 && (
                    <table className="table-auto w-full ">
                      <thead className="w-full">
                        <tr>
                          <th className="text-left">
                            <span className="text-sm lg:text-base dark:text-white text-center">
                              {" "}
                              Product
                            </span>
                          </th>

                          <th className="text-left">
                            <span className="text-sm lg:text-base dark:text-white text-center">
                              {" "}
                              Price
                            </span>
                          </th>
                          <th className="lg:text-left">
                            <span className="text-sm lg:text-base dark:text-white text-center">
                              {" "}
                              Status
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="w-full">
                        {selectedWishlist?.items?.map((item, i) => {
                          return (
                            <tr key={i} className="w-full">
                              <td className="text-sm text-slate-700 pl-2 flex dark:text-white text-center whitespace-nowrap">
                                <img
                                  className="w-12 h-12 rounded bg-slate-100 text-center"
                                  src={`${baseUrl.img}${item?.product_combination.product?.thumbnail_image}`}
                                  alt="wish"
                                />
                                <div className="ml-2">
                                  <p className="lg:text-base text-sm text-left">
                                    {item?.product_combination?.product?.name?.slice(
                                      0,
                                      55
                                    )}
                                  </p>
                                  {item?.product_combination?.attribute_values.map(
                                    (attVal, j) => attVal?.attribute?.name !== "default" &&  <p key={j} className="text-left text-xs font-medium">{attVal?.attribute?.name}: {attVal.name}</p>
                                  )}
                                </div>
                              </td>
                              <th className="text-left">
                                <span className="text-sm lg:text-base dark:text-white text-center">
                                  {" "}
                                  {item?.product_combination.selling_price}
                                </span>
                              </th>
                              <th className="text-left">
                                <span className="text-sm lg:text-base dark:text-white text-left">
                                  {" "}
                                  {item?.product_combination.inventory_sum_stock_quantity > 0 ? (
                                    <p className="text-green-500 font-bold">
                                      In Stock
                                    </p>
                                  ) : (
                                    <p className="text-red-500 font-bold">
                                      out of Stock
                                    </p>
                                  )}
                                </span>
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              {!loading && (
                <div>
                  <p className="dark:text-white flex items-center justify-center">
                    No item in your wishlist
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default WishlistContainer;