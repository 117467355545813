import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../../Hooks/useStore";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  baseUrl,
  convertedDate,
  errorNotify,
  successNotify,
} from "../../../Hooks/Helper";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllcustomerOrderDetails } from "../../../Redux/Slices/Customer/customerOrderDetailsSlice";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import Rating from "react-rating";
import { AiFillStar } from "react-icons/ai";
import { FiStar } from "react-icons/fi";
import { BsShieldCheck } from "react-icons/bs";
import Button from "../../Button/Button";
const OrderDetails = () => {
  const { id } = useParams();
  const [images, setImages] = useState(null);
  const [reviewText, setReviewText] = useState("");
  const [rating, setRating] = useState(0);
  const [orderId, setOrderId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const [givenReview, setGivenReview] = useState([]);

  const orderInfo = useSelector(
    (state) => state.customerOrderDetails.customerOrderDetails
  );
  const { showDummyImage, custommerToken, customerInfo } = useStore().data;
  useEffect(() => {
    if (!custommerToken) {
      return;
    }
    dispatch(fetchAllcustomerOrderDetails({ id, custommerToken }));
  }, [dispatch, id, custommerToken, update]);

  const addReview = async (e) => {
    e.preventDefault();
    console.log("ratings", rating);

    if (!custommerToken) {
      return;
    }
    const formData = new FormData();
    formData.append("rating", rating);
    formData.append("review", reviewText);
    formData.append("order_item_id", orderId);
    if (images) {
      for (const file of images) {
        formData.append("multiple_image[]", file);
      }
    }

    const res = await fetch(`${baseUrl.url}/user/user-product-review`, {
      method: "POST",
      headers: {
        Authorization: `bearer ${custommerToken}`,
      },
      body: formData,
    });
    const resData = await res.json();
    if (resData.success === false) {
      errorNotify(resData.errors[0]);
    } else {
      setUpdate(!update);
      successNotify("Your review posted successfully");
    }
    setShowAddModal(false);
    e.target.reset();
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Add Review Modal */}
      <Rodal
        visible={showAddModal}
        enterAnimation="flip"
        animation="slideRight"
        onClose={() => {
          setShowAddModal(!showAddModal);
        }}
        customStyles={{
          height: "auto",
          width: window?.innerWidth < 640 ? "90%" : window?.innerWidth < 1024 ? "70%" : "30%",
          bottom: "auto",
          top: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <h5 className="text-lg border-b py-3">Add Review for this product</h5>
        <form onSubmit={addReview}>
          <Rating
            onChange={(rate) => setRating(rate)}
            placeholderRating={rating}
            emptySymbol={
              <img
                alt=""
                style={{ height: "35px" }}
                src={require("../../../assets/icons/grey-star.png")}
                className="icon"
              />
            }
            placeholderSymbol={
              <img
                alt=""
                style={{ height: "35px" }}
                src={require("../../../assets/icons/yellow-star.jpg")}
                className="icon"
              />
            }
            fullSymbol={
              <img
                alt=""
                style={{ height: "35px" }}
                src={require("../../../assets/icons/yellow-star.jpg")}
                className="icon"
              />
            }
          />

          <input
            className="block my-4"
            type="file"
            multiple
            onChange={(e) => setImages(e.target.files)}
          />
          <label htmlFor="">Your review</label>
          <textarea
            placeholder="Start from here"
            onChange={(e) => setReviewText(e.target.value)}
            rows={5}
            className="w-full focus:outline-none border p-3 rounded"
          />
          {/* <button className="bg-yellow-500 w-1/2 p-2 rounded">
            Submit
          </button> */}
          <Button submit={true} size={"md"} classes={"mt-3"} title={"Submit"}/>
        </form>
      </Rodal>
          {/* View Review Modal */}
      <Rodal
        visible={showReviewModal}
        enterAnimation="flip"
        animation="slideRight"
        onClose={() => {
          setShowReviewModal(!showReviewModal);
        }}
        customStyles={{
          width: window?.innerWidth < 640 ? "90%" : window?.innerWidth < 1024 ? "70%" : "30%",
          height: "auto",
          bottom: "auto",
          top: "40%",
          transform: "translateY(-50%)",
        }}
      >
        <div className="p-4">
          {givenReview?.length > 0 && (
            <div className="p-4 bg-gray-50">
              {givenReview?.map((rev, j) => {
                const { rating, review, images, created_at } = rev;
                const date = convertedDate(created_at);
                return (
                  <div key={j} className="grid grid-cols-12 mb-5">
                    <div className="col-span-12">
                      <p className="text-gray-800">{date}</p>
                      <div className="flex">
                        <div className="h-8 w-8 rounded-full overflow-hidden">
                          <img
                            src={`${baseUrl.img}${customerInfo?.user_data?.profile?.image}`}
                            alt=""
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="ml-2">
                          <h2>{customerInfo?.user_data?.name}</h2>
                          <p className="flex items-center text-xs text-gray-600">
                            <BsShieldCheck
                              className="mr-1 text-green-500"
                              size={14}
                            />
                            Verified user
                          </p>
                        </div>
                      </div>
                      <div className="ml-10 mt-1">
                        <span className="flex items-center">
                          {Array.from({ length: 5 }).map((_, i) => {
                            if (i <= parseInt(rating)) {
                              return (
                                <AiFillStar
                                  key={i}
                                  className="text-yellow-500"
                                />
                              );
                            }
                            return (
                              <FiStar key={i} className="text-yellow-500" />
                            );
                          })}
                        </span>
                        <i className="text-gray-500 text-sm">{review}</i>
                        <div className="mt-4 grid grid-cols-3 w-[200px]">
                          {images?.map((img, k) => {
                            return (
                              <div
                                key={k}
                                className="border overflow-hidden rounded"
                              >
                                <img
                                  src={`${baseUrl.img}/${img?.image}`}
                                  className="w-full h-16"
                                  alt=""
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                 
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Rodal>
      <div className="mb-3">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="p-3 bg-slate-50 dark:bg-slate-900 shadow">
            <h6 className="dark:text-white border-b pb-3 mb-3">
              Shipping Address
            </h6>
            <div>
              <div>
                <div className="flex items-center">
                  <p className=" dark:text-white font-medium">Name: </p>
                  <p className="dark:text-white ml-2">
                    {orderInfo?.user?.name}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className=" dark:text-white font-medium">Country:</p>
                  <p className="dark:text-white ml-2">
                    {
                      orderInfo?.delivery_address?.upazila?.district?.division
                        ?.country?.name
                    }
                  </p>
                </div>
                <div className="flex items-center">
                  <p className=" dark:text-white font-medium">Division:</p>
                  <p className=" dark:text-white ml-2">
                    {
                      orderInfo?.delivery_address?.upazila?.district?.division
                        ?.name
                    }
                  </p>
                </div>
                <div className="flex items-center">
                  <p className=" dark:text-white font-medium">District:</p>
                  <p className="dark:text-white ml-2">
                    {orderInfo?.delivery_address?.upazila?.district?.name}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className=" dark:text-white font-medium whitespace-nowrap">Sub District:</p>
                  <p className="dark:text-white ml-2">
                    {orderInfo?.delivery_address?.upazila?.name}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className=" dark:text-white font-medium whitespace-nowrap">Delivery Address:</p>
                  <p className="dark:text-white ml-2">
                    {orderInfo?.delivery_address?.address}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className=" dark:text-white font-medium whitespace-nowrap">Phone number:</p>
                  <p className="dark:text-white ml-2">
                    {orderInfo?.delivery_address?.phone_no}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3 bg-slate-50 dark:bg-slate-900 shadow">
            <h6 className="dark:text-white border-b pb-3 mb-3">
              Shipping informations
            </h6>
            <div>
              <div>
                <div className="flex items-center">
                  <p className="dark:text-white font-medium">Date:</p>
                  <p className="ml-2 dark:text-white">
                    {convertedDate(orderInfo?.created_at)}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="dark:text-white font-medium whitespace-nowrap">Delivery method :</p>
                  <p className="dark:text-white ml-2">
                    {orderInfo?.delivery_method?.name}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="dark:text-white font-medium whitespace-nowrap">Payment method :</p>
                  <p className="dark:text-white ml-2">
                    {orderInfo?.payment_method?.name}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="dark:text-white font-medium whitespace-nowrap">Sub Total:</p>
                  <p className="dark:text-white ml-2">
                    ৳ {orderInfo?.sub_total_amount?.toFixed(2)}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="dark:text-white font-medium whitespace-nowrap">Shipping Charge:</p>
                  <p className="dark:text-white ml-2">
                    ৳ {orderInfo?.delivery_cost?.toFixed(2)}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="dark:text-white font-medium whitespace-nowrap">Additional Charge:</p>
                  <p className="dark:text-white ml-2">
                    ৳ {orderInfo?.additional_charges?.toFixed(2)}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="dark:text-white font-medium whitespace-nowrap">Discount:</p>
                  <p className="dark:text-white ml-2">
                    ৳ {orderInfo?.promo_discount?.toFixed(2)}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="dark:text-white font-medium whitespace-nowrap">Total Amount:</p>
                  <p className="dark:text-white ml-2">
                    ৳ {orderInfo?.total_amount?.toFixed(2)}
                  </p>
                </div>
                <div className="flex items-start">
                  <p className="dark:text-white font-medium whitespace-nowrap">Admin Remarks:</p>
                  <p className="dark:text-white ml-2">
                    {orderInfo?.merchant_remarks ? (
                      <p>{orderInfo?.merchant_remarks}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </p>
                </div>

                <p className="font-medium dark:text-white">
                  Status :{" "}
                  <span
                    className={`${
                      orderInfo?.status?.name === "Confirmed"
                        ? "text-green-500"
                        : `${
                            orderInfo?.status?.name === "Pending"
                              ? "text-yellow-500"
                              : "text-red-500"
                          }`
                    }`}
                  >
                    {orderInfo?.status?.name}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3 my-3">
        <h6 className="font-medium py-1 dark:text-white">Order Items</h6>
        <div className="p-3">
          {orderInfo?.items?.map((item, i) => {
            const {
              combination,
              product_price,
              product_quantity,
              total_price,
              is_reviewed,
              reviews,
              id,
            } = item;

            return (
              <div key={i}>
                <div className="grid grid-cols-12 gap-2 py-4 border-b">
                  <div className="col-span-3 lg:col-span-1">
                    <img
                      onError={({ currentTarget }) =>
                        showDummyImage({ currentTarget })
                      }
                      src={`${baseUrl.img}${combination?.product?.thumbnail_image}`}
                      className="w-full lg:w-16 h-20 lg:h-16 bg-slate-100"
                      alt=""
                    />
                  </div>
                  <div className="col-span-9 lg:col-span-7">
                    <p className="font-medium text-blue-500">
                      {combination?.product?.name}
                    </p>
                    <div>

                                {combination?.attribute_values?.map(
                                (value, j) =>
                                  value?.name !== "default" && (
                                    <p
                                      key={j}
                                      className="text-xs font-medium dark:text-white text-left lg:ml-2"
                                    >
                                      {value?.attribute?.name}: {value?.name}
                                    </p>
                                  )
                              )}
                            </div>
                    <p className="text-sm dark:text-white">
                      ৳ {product_price} X {product_quantity} | Total ={" "}
                      {total_price}
                    </p>
                  </div>
              
                  <div className="col-span-12 lg:col-span-4 flex lg:justify-end">
                   <div>
                   {orderInfo?.status?.name === "Delivered" &&
                    is_reviewed === 0 ? (
                      <Button size={"sm"} title={"Add Review"} func={() => {
                        setOrderId(id);
                        setShowAddModal(true);
                      }}/>
                    ) : is_reviewed === 1 ? (
                      <button
                        onClick={() => {
                          setGivenReview(reviews);
                          setShowReviewModal(!showReviewModal);
                        }}
                        className="px-2 py-1 text-sm bg-blue-400 text-white rounded w-fit cursor-pointer"
                      >
                        View review
                      </button>
                    ) : null}
                   </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
