import React from "react";
import { baseUrl } from "../../../Hooks/Helper";
const Sponsor9 = ({ data }) => {
  return (
    <section className="py-8 lg:py-16">
      <div className="container">
        <div>
          <h1 className="text-2xl dark:text-white">
            <span className="text-black font-bold dark:text-white ">
              Champions of Our Cause
            </span>{" "}
          </h1>
          <h1>Our Dedicated Sponsors</h1>
        </div>
        <div className="border-t border-b mt-1 ">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-8 items-center gap-2 mt-2 mb-2">
            {data?.length > 0 ? (
              data.map((sponsor, i) => (
                <div className="bg-white w-full h-[126px] mx-auto" key={i}>
                  <img
                    src={
                      sponsor?.image
                        ? `${baseUrl.img}/${sponsor.image}`
                        : "https://placehold.co/100x48"
                    }
                    alt="slider"
                    className="w-auto h-full mx-auto"
                  />
                </div>
              ))
            ) : (
              <div className="bg-white w-full h-[126px]">
                <img
                  src="https://placehold.co/200x126"
                  alt="placeholder"
                  className="w-auto h-full mx-auto"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sponsor9;
