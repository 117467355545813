import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useStore from "../Hooks/useStore";
import Loader2 from "../Components/Loaders/Loader2/Loader2";
const ProtectedRoute = ({ children }) => {
  const { custommerToken, userLoader } = useStore().data;
  const location = useLocation();

 
  if (userLoader) {
    return <Loader2 />;
  }
  if (!custommerToken && custommerToken?.length<10) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};

export default ProtectedRoute;
