import React from "react";
import ProductContainer4 from "../../ProductContainers/ProductContainer4/ProductContainer4";
import SectionHeading3 from "../../SectionHeadings/SectionHeading3/SectionHeading3";
// import ProductContainer4 from "../../ProductContainer4/ProductContainer4";

const PopularProducts3 = ({ data ,flash}) => {

  return (
    <section className="py-8 lg:py-16">
      <div className="container mb-3">
        <SectionHeading3 title={'In The Limelight'} text={'Trendsetting Products Worth Trying'} />
      </div>
      <ProductContainer4 data={data} flash={flash} />
    </section>
  );
};

export default PopularProducts3;
