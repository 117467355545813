import React from 'react';
import { TfiAngleLeft,TfiAngleRight } from "react-icons/tfi";
import { useSelector } from 'react-redux';
const Paginate = (props) => {
    const pageData = props?.paginateFunction
    const {paginate} = useSelector((state) => state?.allProducts)

    return (
        <div className='custom_paginate'>
            <ul className='flex items-center'>
                {
                   paginate?.length > 0 && paginate?.map((page,i)=>{
                        const {label,url,active} = page;
                        return <li key={i} className='mr-2'><button onClick={()=>pageData(url,label)} className={`w-8 h-8 flex items-center justify-center rounded-full ${active ? 'bg-red-400' : 'text-dark bg-white shadow dark:bg-slate-800 dark:text-white'}`}>{(i===0) ? <TfiAngleLeft/> : (i===paginate?.length-1) ? <TfiAngleRight/>: label}</button></li>
                    })
                }
            </ul>
        </div>
    );
};

export default Paginate;