import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  baseUrl,
  errorNotify,
  getFromCookie,
  saveInCookie,
  successNotify,
} from "../../Hooks/Helper";
import useStore from "../../Hooks/useStore";
import "./Login.css";
import { ToastContainer } from "react-toastify";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import Design01 from "../../Components/Designs/Design01/Design01";
import { BiLoader } from "react-icons/bi";

const Login = () => {
  const { setting, setCustommerToken, setUpdate, update, custommerToken,darkMode } =
    useStore().data;
  const { setCartPopUp } = useStore().store;
  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [captchaImg, setCaptchaImg] = useState(null);
  const [key, setKey] = useState(null);

  const [showResetModal, setShowResetModal] = useState(false);
  const [resetToken, setResetToken] = useState();
  const [resetData, setResetData] = useState({
    code: "",
    password: "",
    confirm_password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // Navigate to home page
  useEffect(() => {
    if (custommerToken?.length > 20) {
      navigate("/");
    }
  }, [navigate, custommerToken]);

  const onSubmit = async (data) => {
    const user_unique_id = getFromCookie("user_unique_id");
    const formData = new FormData();
    formData.append("username", data.username);
    formData.append("password", data.password);
    if (captchaImg) {
      formData.append("captcha", data.captcha);
      formData.append("key", key);
    }
    let url = `${baseUrl.url}/user/login`;
    if (user_unique_id?.length > 5) {
      url = url + `?user_unique_id=${user_unique_id}`;
    }
   try{
    setIsLoading(true)
    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    const loginData = await res.json();
    
    if (res.ok) {
      successNotify('Login Successfully')
      setCustommerToken(loginData.data.token.customer_access_token);
      setUpdate(!update);
      navigate("/");
      saveInCookie("isLogin", 1);
      setCartPopUp(loginData.data.cart);
      setIsLoading(false)
      reset()
     
    } else {
      errorNotify(loginData.errors[0]);
      const captchaImg = loginData?.captcha?.img;
      if (captchaImg) {
        setCaptchaImg(captchaImg);
        setKey(loginData?.captcha?.key);
      } else {
        setCaptchaImg(null);
        setError("");
      }
    } 

   }
   catch(error){
    console.error(error);
   }
   finally{
    setIsLoading(false)
   }
  };

  const handleRefreshCaptcha = async () => {
    try {
      const res = await fetch(`${baseUrl.url}/captcha`, {
        method: "GET",
        headers: {
          Authorization: baseUrl.token,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        const captchaImg = resData?.captcha?.img;
        if (captchaImg) {
          setCaptchaImg(captchaImg);
          setKey(resData?.captcha?.key);

      }

    }
   } catch (err) {
      errorNotify(err?.message);
    }
  };
// new comment
  const handleReset = async () => {
    const username = document.getElementById("username").value;
    const postData = {
      username: username,
    };
    if (username) {
      try {
        const res = await fetch(`${baseUrl.url}/user/reset-password`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(postData),
        });

        const resData = await res.json();

        if (res.ok) {
          const { reset_token } = resData?.data;
          setResetToken(reset_token);
          setShowResetModal(true);
          setTimeout(() => {}, 400);
        } else {
        }
      } catch (error) {}
    } else {
      errorNotify("Please provide your email");
    }
  };
  const handleChangeResetData = (e) => {
    setResetData({ ...resetData, [e.target.name]: e.target.value });
  };
  const handleResetSubmit = async (e) => {
    e.preventDefault();
    for (let i in resetData) {
      if (!resetData[i]) {
        if (i === "code") {
          errorNotify("otp is required");
          return;
        } else if (i === "password") {
          errorNotify("New password is required");
          return;
        } else if (i === "confirm_password") {
          errorNotify("Confirm password is required");
          return;
        }
      }
    }

    if (resetData?.password === resetData?.confirm_password) {
      resetData.token = resetToken;
      try {
        const res = await fetch(`${baseUrl.url}/user/confirm-password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(resetData),
        });

        const resData = await res.json();
        if (res.ok) {
          const { reset_token } = resData;
          successNotify("Password reset successfully");
          setResetToken(reset_token);
            setShowResetModal(!showResetModal);
        } else {
          errorNotify(resData?.errors[0]);
        }
      } catch (error) {
        errorNotify(error?.message);
      }
    } else {
      errorNotify("Two password didn't match");
    }
  };
  return (
    <div className="login-page">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Reset Password Modal */}
      <Rodal
        visible={showResetModal}
        enterAnimation="flip"
        animation="slideRight"
        onClose={() => setShowResetModal(!showResetModal)}
        customStyles={{
          height: "auto",
          bottom: "auto",
          top: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <form onSubmit={handleResetSubmit}>
          <div>
            <input
              name="code"
              onChange={(e) => handleChangeResetData(e)}
              className="w-full border p-2 rounded focus:outline-none"
              placeholder="Otp"
            />
          </div>
          <div className="my-3">
            <input
              name="password"
              onChange={(e) => handleChangeResetData(e)}
              type="password"
              className="w-full border p-2 rounded focus:outline-none"
              placeholder="New Password"
            />
          </div>
          <div className="my-3">
            <input
              name="confirm_password"
              onChange={(e) => handleChangeResetData(e)}
              type="password"
              className="w-full border p-2 rounded focus:outline-none"
              placeholder="Confirm Password"
            />
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="border p-2 rounded bg-gray-700 text-white"
            >
              Submit
            </button>
          </div>
        </form>
      </Rodal>

      <div className={`flex items-center h-screen justify-center p-1`}>
        <div className={`shadow-lg p-3 w-[450px] bg-white dark:bg-slate-800 relative`}>
          <Design01/>
          <div className={`block`}>
            <Link to={"/"} className=" w-full flex justify-center">
              {
                setting?.dark_logo || setting?.logo ?  <img
                className={"w-56"}
                src={
                  darkMode
                    ? `${baseUrl.img}${setting?.dark_logo}`
                    : `${baseUrl.img}${setting?.logo}`
                }
                alt={"website logo"}
              /> :''
              }
           
            </Link>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-3">
            <div className="mb-4 w-full">
              <label
                className="text-color font4 mb-1 block dark:text-white"
                htmlFor=""
              >
                Email Address
              </label>
              <input
                placeholder="usermail@mail.com"
                {...register("username", { required: true })}
                type="text"
                id="username"
                className="dark:bg-slate-900 w-full p-2 bg-slate-50 text-sm focus:outline-none rounded-lg dark:text-white"
              />
            </div>
            <div className="mb-4 w-full">
              <label
                className="text-color font4 mb-1 block dark:text-white"
                htmlFor=""
              >
                Password
              </label>
              <input
                placeholder="Your password here"
                {...register("password", { required: true })}
                type="password"
                className="dark:bg-slate-900 w-full p-2 bg-slate-50 text-sm focus:outline-none rounded-lg dark:text-white"
              />
            </div>
            {/* captcha */}
            {captchaImg && (
              <div>
                <div className="border py-3 px-1 rounded flex items-end">
                  <img className="w-full h-16" src={captchaImg} alt="captcha" />
                  <button
                    onClick={handleRefreshCaptcha}
                    className="ml-2 bg-gray-800 text-white rounded p-1"
                  >
                    Refresh
                  </button>
                </div>

                <div className="my-2">
                  <div>
                    <input
                      type="text"
                      className="dark:bg-slate-900 w-full p-2 bg-slate-50 text-sm focus:outline-none rounded-lg"
                      placeholder="captcha"
                      {...register("captcha", {
                        required: {
                          value: captchaImg && true,
                          message: "Captcha is Required",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
            )}
            <button
              className={`flex justify-center items-center gap-2 bg-red-400 w-full p-2 rounded-lg transition ease-in duration-200 border text-black text-sm  cursor-pointer ${
                isLoading ? "bg-green-300" : "bg-green-500"
              }`}
              type="submit"
              disabled={isLoading && true}
            >
               {isLoading && <BiLoader size={20} />}
              Login
            </button>
          </form>
          <p className="text-sm px-3 text-slate-700 text-center dark:text-white">
            Forgot password?
            <span
              className="cursor-pointer underline text-blue-500"
              onClick={handleReset}
            >
              {" "}
              Reset password
            </span>
          </p>

          {error?.length > 0 && (
            <div className="mt-3 p-2 md:p-3 rounded">
              <p className="m-0">
                <span className="fw-bold text-red-500">Error</span> :{" "}
                <span className="font4 error-text">{error}</span>
              </p>
            </div>
          )}
          <p className="text-center text-color text-sm text-slate-700 mt-3 leading-5 dark:text-white">
            First time here? Register 
            <Link to="/register" className="text-blue-500 underline ml-1">
              here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
