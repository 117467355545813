
import React from 'react'
import useStore from '../../../Hooks/useStore';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css/bundle";
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../../Hooks/Helper';
import SectionHeading2 from '../../SectionHeadings/SectionHeading2/SectionHeading2';
const Category7 = ({categories}) => {
  const navigate = useNavigate()
  const {showDummyImage,setCategoryId} = useStore().data

  const goPage = (id)=>{
      navigate("/products")
      setCategoryId(id)
  }
  const data = {
      heading:"Top Categories",
      paragraph:"Exploring Categories for the Perfect Find"
  }
  return (
    <section className='dark:bg-slate-900 py-8 lg:py-16'>
    <div className="container">
    <SectionHeading2 data={data}/>
        <div className="relative mt-3">
            <Swiper
                slidesPerView={4}
                spaceBetween={0}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    320: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                    },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                },
                1024: {
                    slidesPerView: 6,
                    spaceBetween: 0,
                },
              1280: {
                    slidesPerView: 9,
                    spaceBetween: 0,
                },
                }}
                
                className="mySwiper"
                modules={[Autoplay]}
            >
                {
                    categories?.map((item,i)=><SwiperSlide key={i}>
                    <div onClick={()=>goPage(item?.id)} className='bg-white dark:bg-slate-800'>
                        <div className='category-img-container'>
                        {item?.image ? (
                      <img
                        onError={({ currentTarget }) =>
                          showDummyImage({ currentTarget })
                        }
                        src={`${baseUrl.img}/${item?.image}`}
                        alt=""
                        className="w-full"
                      />
                    ) : (
                      <img
                        onError={({ currentTarget }) =>
                          showDummyImage({ currentTarget })
                        }
                        src={require("../../../assets/product-default-img/dummy.png")}
                        alt=""
                        className="w-full"
                      />
                    )}

                        </div>
                        <div className='px-2 text-center pb-2'>
                            <div className="single-category-container">
                                <h6 className='dark:text-white whitespace-nowrap text-sm'>{item?.name}</h6>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>)
                }
                
            </Swiper>
            {/* <Swiper
                slidesPerView={4}
                spaceBetween={0}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                320: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                    },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                },
                1024: {
                    slidesPerView: 9,
                    spaceBetween: 0,
                },
                1280: {
                    slidesPerView: 9,
                    spaceBetween: 0,
                },
                }}
                className="slider-category"
                modules={[Autoplay]}
            >
                {
                    categories?.slice(0,categories?.length).reverse()?.map((item,i)=><SwiperSlide key={i}>
                    <div onClick={()=>goPage(item?.id)} className='bg-white dark:bg-slate-800'>
                        <div className='category-img-container'>
                        <img onError={({ currentTarget }) => showDummyImage({ currentTarget })} src={`${baseUrl.img}/${item?.image}` || require('../../../assets/product-default-img/dummy.png')} alt="" className="w-full" />
                        </div>
                        <div className='px-2 text-center pb-2'>
                            <div className="single-category-container">
                                <h6 className='dark:text-white whitespace-nowrap'>{item?.name}</h6>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>)
                }
                
            </Swiper> */}
        </div>




    </div>
</section>
  )
}

export default Category7