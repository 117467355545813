import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { baseUrl, errorNotify, successNotify } from "../../../Hooks/Helper";
import Button from "../../Button/Button";

const Subscription5 = ({ data }) => {
  const [email, setEmail] = useState("");
  const handlesubs = async () => {
    if (email.length < 7 || !email.includes("@")) {
      errorNotify("Email is required");
      return;
    }
    const formData = new FormData();
    formData.append("email", email);
    const res = await fetch(`${baseUrl.url}/subscribe`, {
      method: "POST",
      headers: {
        Authorization: baseUrl.token,
      },
      body: formData,
    });
    const resData = await res.json();
    if (resData.status) {
      successNotify("Email subscribed successfully");
      setEmail("");
    } else {
      errorNotify(resData?.errors?.map((err) => err));
    }
  };
  return (
    <div className=" py-3 pb-0 lg:py-2 bg-white dark:bg-gray-950">
      <ToastContainer
        position="bottom-right"
        autoClose={50000}
        hideProgressBar={false}
        className="shadow-none"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="container">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 lg:col-span-8">
            <h4 className="m-0 text-2xl font-bold dark:text-white ">
              Unlock Insider Insights
            </h4>
            <p className="text-black text-sm dark:text-white">
              Stay Informed with Our Newsletter.
            </p>

            <div className="border p-1 rounded w-full md:w-3/4 mt-2">
              <div className="grid grid-cols-12 items-center">
                <div className="col-span-8">
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    value={email}
                    placeholder="Enter email address"
                    className=" bg-transparent text-start items-center h-full ml-3 focus:outline-none dark:text-white"
                  />
                </div>
                {/* <div className="col-span-4">
                  <button
                    className="bg-[#ffa21f] py-1 lg:py-2 text-white w-full border-1 rounded"
                    onClick={handlesubs}
                  >
                    Subscribe
                  </button>
                </div> */}
                <Button  size={"md"} title={"Subscribe"} func={handlesubs} classes={'col-span-4'}/>
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4 hidden lg:block mt-2 lg:mt-0">
            <div className="w-[448px] h-[261px]">
            {data?.newsletter_image ? (
              <img
                src={`${baseUrl.img}/${data?.newsletter_image}`}
                alt=""
                className="w-auto h-full"
              />
            ) : (
              <img
                src={"https://placehold.co/448x261"}
                alt=""
                className="w-full h-full object-cover"
              />
            )}
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription5;
