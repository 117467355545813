import React from "react";
import { baseUrl } from "../../../Hooks/Helper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import "swiper/css/bundle";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import { Link } from "react-router-dom";
import CoutDown from "../../CountDowns/CoutDown/CoutDown";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const FlashSale8 = ({ data, flash }) => {
  const data2 = {
    heading: "Limited-Time Combo",
    paragraph: "Unveiling Flash Sale Treasures",
    show:data?.length >7 ?true :false
  };
  const endDate = new Date(flash?.end_date);
  return (
    <section className="py-8 lg:py-16">
      {data?.length > 0 && (
        <div className="container">
          <SectionHeading2 data={data2} />
          <div className="w-full lg:w-3/12 mt-4">
            <CoutDown endDate={endDate} />
          </div>
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12 lg:col-span-12">
              <div className="p-3">
                <div>
                  <Swiper
                    slidesPerView={7}
                    spaceBetween={10}
                    breakpoints={{
                      "@0.00": {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      "@0.75": {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                      "@1.00": {
                        slidesPerView: 4,
                        spaceBetween: 40,
                      },
                      "@1.50": {
                        slidesPerView: 7,
                        spaceBetween: 50,
                      },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {data?.length > 0 &&
                      data?.map((product, i) => {
                        const {
                          id,
                          thumbnail_image,
                          name,
                          slug,
                          display_price,
                          previous_display_price,
                          inventories_sum_stock_quantity,
                          product_review_rating,
                        } = product;
                        const pricenow = previous_display_price - display_price;
                        const flashSalemoney =
                          (display_price * flash?.discount) / 100;
                        return (
                          <SwiperSlide key={i}>
                            <Link
                              to={`/details/${id}/${slug}`}
                              className="text-decoration-none flash-sale2 text-center block"
                            >
                             <div className="h-32 w-full">
                              <img
                                src={
                                  `${baseUrl.img}${thumbnail_image}` ||
                                  "https://placehold.co/146x128"
                                }
                                className="w-auto mx-auto h-full"
                                alt=""
                              />
                            </div>
                            <h6 className="text-sm leading-4 dark:text-sky-500 mt-3">
                            {name?.length > 15 ? name?.slice(0, 15) + "..." : name}
                            </h6>
                           

                              <div className="flex justify-between text-sm mt-2">
                                {flash ? (
                                  <p className="text-lg dark:text-white">
                                    ৳ {display_price - flashSalemoney}
                                  </p>
                                ) : (
                                  <p className="text-lg dark:text-white">
                                    ৳ {display_price}
                                  </p>
                                )}

                                {flash ? (
                                  <h6 className="dark:text-red-500">
                                    ৳{flashSalemoney} Off
                                  </h6>
                                ) : previous_display_price ? (
                                  <h6 className="dark:text-red-500">
                                    ৳{pricenow} Off
                                  </h6>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="flex items-center justify-between">
                                {inventories_sum_stock_quantity > 0 ? (
                                  <p className="text-green-500 font-normal text-xs text-start">
                                    In Stock
                                  </p>
                                ) : (
                                  <p className="text-red-500 font-normal text-xs text-start">
                                    Out of Stock
                                  </p>
                                )}
                                <p className="mb-0 flex items-center">
                                  {Array.from({ length: 5 }).map((_, i) => {
                                    if (
                                      i <
                                      parseInt(
                                        product_review_rating[0]
                                          ?.avg_review_rating
                                      )
                                    ) {
                                      return (
                                        <AiFillStar
                                          key={i}
                                          className="text-orange-500"
                                        />
                                      );
                                    }
                                    return (
                                      <AiOutlineStar
                                        key={i}
                                        className="text-gray-400"
                                      />
                                    );
                                  })}
                                </p>
                              </div>
                            </Link>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
              </div>
            </div>
            {/* <div className="col-span-12 lg:col-span-12">
              <div className="p-3">
                <div>
                  <Swiper
                    slidesPerView={7}
                    spaceBetween={10}
                    breakpoints={{
                      "@0.00": {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      "@0.75": {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                      "@1.00": {
                        slidesPerView: 4,
                        spaceBetween: 40,
                      },
                      "@1.50": {
                        slidesPerView: 7,
                        spaceBetween: 50,
                      },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {data?.length > 0 &&
                      data
                        ?.slice(0, data?.length)
                        ?.reverse()
                        ?.map((product, i) => {
                          const {
                            id,
                            thumbnail_image,
                            name,
                            display_price,
                            slug,
                            previous_display_price,
                            inventories_sum_stock_quantity,
                            product_review_rating,
                          } = product;
                          const pricenow =
                            previous_display_price - display_price;
                          const flashSalemoney =
                            (display_price * flash?.discount) / 100;
                          return (
                            <SwiperSlide key={i}>
                              <Link
                                to={`/details/${id}/${slug}`}
                                className="text-decoration-none flash-sale2 text-center block"
                              >
                                <div className="h-32">
                                  <img
                                    src={
                                      `${baseUrl.img}${thumbnail_image}` ||
                                      "https://placehold.co/146x128"
                                    }
                                    className="w-full h-full"
                                    alt=""
                                  />
                                </div>
                                <h6 className="text-sm leading-4 dark:text-sky-500">
                                  {name}
                                </h6>
                                <div className="flex justify-between text-sm mt-2">
                                  {flash ? (
                                    <p className="text-lg dark:text-white">
                                      ৳ {display_price - flashSalemoney}
                                    </p>
                                  ) : (
                                    <p className="text-lg dark:text-white">
                                      ৳ {display_price}
                                    </p>
                                  )}

                                  {flash ? (
                                    <h6 className="dark:text-red-500">
                                      ৳{flashSalemoney} Off
                                    </h6>
                                  ) : previous_display_price ? (
                                    <h6 className="dark:text-red-500">
                                      ৳{pricenow} Off
                                    </h6>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="flex items-center justify-between">
                                  {inventories_sum_stock_quantity > 0 ? (
                                    <p className="text-green-500 font-normal text-xs text-start">
                                      In Stock
                                    </p>
                                  ) : (
                                    <p className="text-red-500 font-normal text-xs text-start">
                                      Out of Stock
                                    </p>
                                  )}
                                  <p className="mb-0 flex items-center">
                                    {Array.from({ length: 5 }).map((_, i) => {
                                      if (
                                        i <
                                        parseInt(
                                          product_review_rating[0]
                                            ?.avg_review_rating
                                        )
                                      ) {
                                        return (
                                          <AiFillStar
                                            key={i}
                                            className="text-orange-500"
                                          />
                                        );
                                      }
                                      return (
                                        <AiOutlineStar
                                          key={i}
                                          className="text-gray-400"
                                        />
                                      );
                                    })}
                                  </p>
                                </div>
                              </Link>
                            </SwiperSlide>
                          );
                        })}
                  </Swiper>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </section>
  );
};

export default FlashSale8;
