import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Navbar6.css";
import { VscGitCompare, VscSearch } from "react-icons/vsc";
import { BsArrowRight, BsCart3, BsGrid } from "react-icons/bs";
import { RxCross1, RxHamburgerMenu } from "react-icons/rx";
import useStore from "../../../Hooks/useStore";
import { baseUrl, removeToken } from "../../../Hooks/Helper";
import { fetchCartList } from "../../../Redux/Slices/Cart/cartListSlice";
import { fetchAllcustomerInfo } from "../../../Redux/Slices/Customer/customerInfoSlice";
import { fetchAllcategories } from "../../../Redux/Slices/category/categoriesSlice";
import { useDispatch, useSelector } from "react-redux";
import { BsInstagram, BsPinterest } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import { FiLogOut, FiTwitter } from "react-icons/fi";
import { AiOutlineYoutube } from "react-icons/ai";
import { fetchAllwishList } from "../../../Redux/Slices/Wishlist/wishlistSlice";
import { IoMdHeartEmpty } from "react-icons/io";
import { TfiAngleDown } from "react-icons/tfi";
import { HiBars3 } from "react-icons/hi2";
import Button from "../../Button/Button";
const Navbar6 = ({ staticMenus,flash }) => {
  const navigate = useNavigate();
  const [navbarClass, setNavbarClass] = useState(" bg-white dark:bg-black");
  const [selectedTab, setSelectedTab] = useState("Menu");
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const {
    compare,
    custommerToken,
    secondaryToken,
    setProductName,
    productName,
    update,
    setUpdate,
    setViewAllFlashSell,
    setDiscount,
    setFeatureCat,
    setSubCategoryId,
    setCategoryId,
    setMaxPrice,
    setMinPrice,
    refreshAll,
    setRefreshAll,
    setCustommerToken,
    darkMode,
  } = useStore().data;

  const navItems = [
    {
      page: "Home",
      route: "/",
    },
    {
      page: "Products",
      route: "/products",
    },
  ];
  const location = useLocation().pathname;

  const dispatch = useDispatch();
  //profile
  // const { customerInfo } = useSelector((state) => state?.customerInfo);
  const profileUpdate = useSelector((state) => state?.customerInfo?.updates);

  useEffect(() => {
    if (custommerToken) {
      dispatch(fetchAllcustomerInfo(custommerToken));
    }
  }, [profileUpdate, dispatch, custommerToken]);

  //redux fetch
  const { setting } = useStore().data;
  const {
    facebook,
    instagram,
    linkedin,
    twitter,
    youtube,
    pinterest,
    dark_logo,
    logo,
    customerInfo,
  } = setting;

  //cart items
  const { cartItems, updates } = useSelector((state) => state?.cartItems);
  const allCategories = useSelector((state) => state?.categories?.categories);
  useEffect(() => {
    dispatch(fetchAllcategories());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchCartList({ custommerToken, secondaryToken }));
  }, [dispatch, updates, custommerToken, secondaryToken, update]);

  const { wishlist } = useSelector((state) => state?.wishlist);
  const verified = customerInfo?.user_data?.email_verified_at;
  useEffect(() => {
    if (!custommerToken || !verified) {
      return;
    }
    dispatch(fetchAllwishList({ custommerToken }));
  }, [dispatch, custommerToken, update, verified]);

  //menuToggole
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  //Logout
  const logOut = async () => {
    const res = await fetch(`${baseUrl.url}/user/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: `bearer ${custommerToken}`,
      },
    });
    if (res.ok) {
      setCustommerToken("");
      setUpdate(!update);
      // setAddresses([]);
    }
    removeToken(["isLogin", "customer_access_token", "remember_me_token"]);
    navigate("/");
  };
  //Handle search

  //Handle search with click search button

  //Sticky navbar
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const [stickynew, setStickyNew] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setNavbarClass(
        "fixed top-0 w-full z-50 bg-white dark:bg-gray-900 shadow"
      );
      setStickyNew(true);
    } else {
      setNavbarClass("bg-gray-100 dark:bg-gray-900");
    }
  };
  const newTabWithLink = (link) => {
    window.open(link);
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    setProductName(searchTerm);
    if (e.key === "Enter") {
      e.preventDefault();

      navigate("/products");
      setIsOpen(false);
    }
  };
  const handleKeySearch = () => {
    setProductName(productName);
    navigate("/products");
    setIsOpen(false);
  };
  return (
    <div>
      <div>
        {/* Headerdown */}
        <div className={`py-2 bg-white dark:bg-gray-800 `}>
          <div className="container mx-auto">
            <div className="grid grid-cols-12 items-center">
              <div className="col-span-1 sm:block lg:hidden">
                <div>
                  <RxHamburgerMenu
                    onClick={toggleMenu}
                    className={`cursor-pointer  focus:outline-none dark:text-white ${
                      isOpen && "hidden"
                    }`}
                    size={22}
                  />
                </div>
              </div>

              <div className="col-span-6 lg:col-span-3">
                <div
                  onClick={() => navigate("/")}
                  className="  h-[30px] cursor-pointer "
                >
                  {dark_logo || logo ? (
                    <img
                      className={"h-full w-auto"}
                      src={
                        darkMode
                          ? `${baseUrl.img}${dark_logo}`
                          : `${baseUrl.img}${logo}`
                      }
                      alt={"website logo"}
                    />
                  ) : (
                    <img
                      className={"h-full w-auto"}
                      src="https://placehold.co/160x34"
                      alt={"website logo"}
                    />
                  )}
                </div>
              </div>
              <div className="col-span-6 hidden lg:block ">
                <div className="flex gap-2 bg-white dark:bg-gray-900 rounded px-2 py-3 w-full items-center">
                  <VscSearch
                    size={18}
                    className="ml-3 text-black cursor-pointer dark:text-white"
                  />
                  <input
                    type="text"
                    placeholder="Search Product"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="form-control text-sm  text-black focus:outline-none bg-transparent w-full dark:text-white"
                  />
                </div>
              </div>
              <div className="col-span-5 lg:col-span-3">
                <ul className="flex justify-end m-0 gap-2 lg:gap-0">
                  <li className="px-1  lg:px-3 cursor-pointer relative  ">
                    <Link to="/compare">
                      {compare?.length > 0 && (
                        <div className="absolute -top-2 left-7 lg:left-10 bottom-3 w-4 h-4 text-center text-xs rounded-full bg-red-400 dark:text-white">
                          {compare?.length}
                        </div>
                      )}

                      <VscGitCompare
                        size={22}
                        className="ml-3 text-black dark:text-white"
                      />
                      <p className="text-xs text-black dark:text-white lg:block hidden xl:block">
                        Compare
                      </p>
                    </Link>
                  </li>

                  {custommerToken && custommerToken.length > 20 && (
                    <li className="px-1  lg:px-3 cursor-pointer relative  ">
                      <Link to="/wishlist">
                        {wishlist?.length > 0 && (
                          <div className="absolute -top-2 left-7 lg:left-10 bottom-3 w-4 h-4 text-center text-xs rounded-full bg-red-400 dark:text-white">
                            {wishlist?.length}
                          </div>
                        )}
                        <IoMdHeartEmpty
                          size={22}
                          className="ml-3 text-black dark:text-white"
                        />
                        <p className="text-xs text-black dark:text-white lg:block hidden xl:block">
                          wishlist
                        </p>
                      </Link>
                    </li>
                  )}

                  <li className="px-1  lg:px-3 cursor-pointer text-center ">
                    <div className="relative">
                      <div className="rounded-full cart flex items-center justify-end">
                        <Link to={"/cart"} className="relative">
                          {cartItems?.length > 0 && (
                            <div className="absolute -top-2 left-7 lg:left-7 bottom-3 w-4 h-4 text-center text-xs rounded-full bg-red-400">
                              {cartItems?.length}
                            </div>
                          )}
                          <BsCart3
                            size={22}
                            className="ml-3 text-black dark:text-white"
                          />
                          <p className="text-xs text-black dark:text-white ml-3 lg:block hidden xl:block">
                            Cart
                          </p>
                        </Link>

                        <div className="cart-hover-item bg-white dark:bg-slate-800">
                          {cartItems?.length > 0 ? (
                            <div>
                              {cartItems?.slice(0, 5)?.map((cart, i) => (
                                <div key={i} className="grid grid-cols-12 mb-2">
                                  <div className="col-span-3">
                                    <img
                                      className="dark:bg-slate-900 rounded-lg"
                                      style={{ height: "50px", width: "50px" }}
                                      src={`${baseUrl.img}${cart?.product_combination?.product?.thumbnail_image}`}
                                      alt="cart"
                                    />
                                  </div>
                                  <div className="col-span-9">
                                    <p
                                      style={{ lineHeight: "15px" }}
                                      className="p-0 m-0 fw-bold font-medium dark:text-white"
                                    >
                                      {cart?.product_combination?.product?.name}
                                    </p>
                                    <p className="dark:text-white">
                                      {cart?.product_quantity} X ৳
                                      {flash &&
                                    cart?.product_combination?.product
                                      ?.is_on_sale === 1 ? (
                                      <span>
                                        {(cart?.product_combination
                                          ?.selling_price -
                                          (cart?.product_combination
                                            ?.selling_price * flash.discount) /
                                            100).toFixed(2)}
                                      </span>
                                    ) : (
                                      <span>
                                        {
                                          cart?.product_combination
                                            ?.selling_price
                                        }
                                      </span>
                                    )}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="p-3 text-center">
                              <div
                                style={{ width: "60px" }}
                                className="mx-auto"
                              >
                                <img
                                  src={require("../../../assets/cart/no-item.png")}
                                  alt=""
                                  className="w-full"
                                />
                              </div>
                              <p className="dark:text-white">No item in cart</p>
                            </div>
                          )}
                          <div>
                            <Link className="block" to={"/cart"}>
                              <Button
                                classes={"w-full"}
                                size={"md"}
                                title={"View Cart"}
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              {isOpen && (
                <div
                  className={`nav-menu-layout6 container mx-auto ${
                    stickynew ? " z-10" : ""
                  }`}
                >
                  <RxCross1
                    onClick={() => setIsOpen(false)}
                    className="text-red-400 cursor-pointer float-right mr-2 mt-2"
                    size={18}
                  />

                  <div className="grid grid-cols-12 border   w-full h-10 mt-10 ">
                    <div className="col-span-9">
                      <input
                        type="text"
                        placeholder="Search Product..."
                        className="form-control text-sm  text-black font-normal  focus:outline-none bg-transparent w-full mt-3 ml-2 "
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div className="col-span-1 border-r border-gray-800"></div>
                    <div
                      className="col-span-2 bg-red-400 "
                      onClick={handleKeySearch}
                    >
                      <VscSearch
                        size={18}
                        className=" text-white cursor-pointer mt-3 ml-3"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="grid grid-cols-12 items-center cursor-pointer  py-4">
                      {["Menu", "Categories"].map((heading, i) => (
                        <div
                          key={i}
                          onClick={() => setSelectedTab(heading)}
                          className={`font-normal text-base  text-center border-b w-full  ${
                            selectedTab === heading
                              ? "text-red-400 col-span-6 border-red-400"
                              : "text-black col-span-6 border-white "
                          }`}
                        >
                          {heading}
                        </div>
                      ))}
                    </div>
                    <div className="grid grid-cols-12 items-center ">
                      {selectedTab === "Menu" && (
                        <div className="col-span-6">
                          <ul className={" "}>
                            {navItems.map((item, i) => (
                              <li
                                key={i}
                                className="items-center cursor-pointer"
                              >
                                {" "}
                                <span
                                  className="px-2 text-black font-normal"
                                  onClick={() => {
                                    navigate(item.route);
                                    setIsOpen(false);
                                  }}
                                >
                                  {item.page}
                                </span>
                              </li>
                            ))}

                            {staticMenus?.map(
                              (item, i) =>
                                item.static_menu_type.name === "Header" && (
                                  <li
                                    key={i}
                                    className="items-center cursor-pointer"
                                  >
                                    <button
                                      className={`  ${
                                        item.route === location
                                          ? "active-nav-menu "
                                          : "bg-transparent"
                                      }`}
                                      onClick={() => {
                                        navigate(`/page/${item.id}`);
                                        setViewAllFlashSell(0);
                                        setDiscount(0);
                                        setFeatureCat(0);
                                        setSubCategoryId(null);
                                        setMinPrice(null);
                                        setRefreshAll(!refreshAll);
                                        setMaxPrice(null);
                                        setCategoryId("");
                                        setProductName("");
                                        setIsOpen(false);
                                      }}
                                    >
                                      {item?.icon}{" "}
                                      <span className="px-2 text-black font-normal whitespace-nowrap">
                                        {item.menu_name}
                                      </span>
                                    </button>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      )}
                      {selectedTab === "Categories" && (
                        <div className="col-span-6">
                          <ul className={" "}>
                            {allCategories?.data?.map((item, i) => (
                              <li
                                key={i}
                                className="items-center cursor-pointer"
                              >
                                {" "}
                                <button
                                  className="px-2 text-black font-normal"
                                  onClick={(e) => {
                                    setCategoryId(item?.id);
                                    navigate("/products");
                                    setIsOpen(false);
                                  }}
                                >
                                  {item.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="text-center mb-5">
                    <h5 className="font-style1 font3">Follow us</h5>
                    <div className="flex items-center justify-center mt-1">
                      <div className="flex">
                        <button
                          style={{ height: "25px", width: "25px" }}
                          onClick={() => newTabWithLink(facebook)}
                          className="footer_icon_hover rounded-full mx-2 border-0 bg-blue-600 text-white flex items-center justify-center"
                        >
                          <FaFacebookF size={12} />
                        </button>
                        <button
                          style={{ height: "25px", width: "25px" }}
                          onClick={() => newTabWithLink(instagram)}
                          className="footer_icon_hover rounded-full mx-2 border-0 bg-red-600 text-white flex items-center justify-center"
                        >
                          <BsInstagram size={12} />
                        </button>
                        <button
                          style={{ height: "25px", width: "25px" }}
                          onClick={() => newTabWithLink(twitter)}
                          className="footer_icon_hover rounded-full mx-2 border-0 bg-cyan-600 text-white flex items-center justify-center"
                        >
                          <FiTwitter size={12} />
                        </button>
                        <button
                          style={{ height: "25px", width: "25px" }}
                          onClick={() => newTabWithLink(youtube)}
                          className="footer_icon_hover rounded-full mx-2 border-0 bg-red-600 text-white flex items-center justify-center"
                        >
                          <AiOutlineYoutube size={12} />
                        </button>
                        <button
                          style={{ height: "25px", width: "25px" }}
                          onClick={() => newTabWithLink(linkedin)}
                          className="footer_icon_hover rounded-full mx-2 border-0 bg-blue-600 text-white flex items-center justify-center"
                        >
                          <GrLinkedinOption size={12} />
                        </button>
                        <button
                          style={{ height: "25px", width: "25px" }}
                          onClick={() => newTabWithLink(pinterest)}
                          className="footer_icon_hover rounded-full mx-2 border-0 bg-red-600 text-white flex items-center justify-center"
                        >
                          <BsPinterest size={12} />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/*  */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* HeaderDown1 */}
      <div className={`${navbarClass} p-3 lg:p-0`}>
        <div className=" container">
          <div className="grid grid-cols-12 items-center gap-4">
            <div className="col-span-8 lg:col-span-2 relative group">
              <div className="block lg:hidden">
                {/* <RxHamburgerMenu
                  onClick={toggleMenu}
                  className={`cursor-pointer  focus:outline-none dark:text-white ${
                    isOpen && "hidden"
                  }`}
                  size={22}
                /> */}
              </div>
              <div className="bg-transparent md:bg-white dark:bg-transparent p-3 lg:flex items-center justify-between  hidden">
                <span className="flex items-center text-white">
                  <HiBars3
                    size={25}
                    className=" mr-2 text-black dark:text-white"
                  />

                  <span className=" text-gray-700 dark:text-white whitespace-nowrap text-sm xl:text-base">
                    All Categories
                  </span>
                </span>
                <TfiAngleDown className=" dark:text-white hidden lg:block" />
              </div>
              <div className=" absolute w-full xl:h-fit  bg-white shadow-xl z-50 opacity-0 invisible group-hover:visible group-hover:opacity-100 p-3 lg:block hidden">
                <ul>
                  {allCategories?.data?.map((cat, i) => {
                    const { name } = cat;
                    return (
                      <li className="flex items-center p-2 gap-2" key={i}>
                        <BsArrowRight className=" md:hidden xl:block" />
                        <button
                          className="font-bold whitespace-nowrap text-sm md:text-xs lg:text-xs xl:text-sm"
                          onClick={() => {
                            setCategoryId(cat?.id);
                            navigate("/products");
                          }}
                        >
                          {name}
                        </button>
                      </li>
                    );
                  })}
                  {allCategories?.data?.length > 10 && (
                    <li className="flex items-center p-2">
                      <BsGrid className="mr-4" />
                      <button
                        className="font-bold"
                        onClick={() => {
                          navigate("/products");
                        }}
                      >
                        {"View all categories"}
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="col-span-6 hidden lg:block ">
              <ul className={"hidden lg:flex md:flex items-center gap-4 "}>
                {navItems?.map((item, i) => (
                  <li key={i} className="mr-5">
                    <button
                      className={`border-0 d-flex align-items-center ${
                        item.route === location
                          ? "active-nav-menu "
                          : "bg-transparent"
                      }`}
                      onClick={() => {
                        navigate(item.route);
                        setViewAllFlashSell(0);
                        setDiscount(0);
                        setFeatureCat(0);
                        setSubCategoryId(null);
                        setMinPrice(null);
                        setRefreshAll(!refreshAll);
                        setMaxPrice(null);
                        setCategoryId("");
                        setProductName("");
                      }}
                    >
                      {item?.icon}{" "}
                      <span className="ml-2 dark:text-white whitespace-nowrap">
                        {item.page}
                      </span>
                    </button>
                  </li>
                ))}
                {staticMenus?.map((item, i) => {
                  if (item.static_menu_type.name === "Footer") {
                    return null;
                  }
                  return (
                    <li key={i} className="mr-5">
                      <button
                        className={`border-0 d-flex align-items-center ${
                          item.route === location
                            ? "active-nav-menu "
                            : "bg-transparent"
                        }`}
                        onClick={() => {
                          navigate(`/page/${item.id}`);
                          setViewAllFlashSell(0);
                          setDiscount(0);
                          setFeatureCat(0);
                          setSubCategoryId(null);
                          setMinPrice(null);
                          setRefreshAll(!refreshAll);
                          setMaxPrice(null);
                          setCategoryId("");
                          setProductName("");
                        }}
                      >
                        {item?.icon}{" "}
                        <span className="ml-2 dark:text-white whitespace-nowrap">
                          {item.menu_name}
                        </span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-span-4 lg:col-span-4">
              <ul className="flex items-center justify-end">
                {custommerToken?.length > 20 ? (
                  <li>
                    <ul className="flex items-center">
                      <li>
                        <button
                          onClick={logOut}
                          className="flex items-center text-sm dark:text-white"
                        >
                          <FiLogOut className="mr-1" />
                          Logout
                        </button>
                      </li>

                      <li className="ml-4">
                        <Link
                          className="text-sm dark:text-white"
                          to={"/profile"}
                        >
                          Profile
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li>
                    <Link to={"/login"} className="text-sm dark:text-white">
                      Login/Register
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar6;
