import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";

const initialState = {
  customerOrder: [],
  loading: false,
  err: null,
  updates: false,
};
export const fetchAllcustomerOrder = createAsyncThunk(
  "fetchcustomerOrder/customerOrder", async (custommerToken) => {
    const res = await fetch(`${baseUrl.url}/user/order-list`, {
      method: "GET",
      headers: {
        Authorization: `bearer ${custommerToken}`,
      },
    });
    const resData = await res.json();
    return resData.data
  }
);

const customerOrderSlice = createSlice({
  name: "customerOrder",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllcustomerOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllcustomerOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.customerOrder = action.payload;
        state.err = null;
      })
      .addCase(fetchAllcustomerOrder.rejected, (state, action) => {
        state.loading = false;
        state.customerOrder = [];
        state.err = action.payload;
      });
  },
});
export default customerOrderSlice.reducer;
