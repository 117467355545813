import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../Hooks/Helper";
import PageHeading from "../../Components/PageHeading/PageHeading";
import NoContent from "../../Pages/NoContent/NoContent";
import parser from "html-react-parser";
import Subscription2 from "../../Components/Subscriptions/Subscription2/Subscription2";
const Pages = () => {
  const { id } = useParams();
  const [content, setcontent] = useState({});


  useEffect(() => {
    const getContent = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/static-menu-content/${id}`, {
          method: "GET",
          headers: {
            Authorization: baseUrl.token,
          },
        });
        if (res.ok && res.status !== 204) {
          const resData = await res.json();
          setcontent(resData.data);
        }
      } catch (error) {}
    };
    getContent();
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <section className="py-8 lg:py-16">
        <div className="container">
          <div className="w-full lg:w-4/6 mx-auto">
            <div className="text-center">
              <PageHeading
        
                h2={content?.static_content?.title}
         
              />
            </div>
          </div>
          <div className="mt-6">
            {content.static_content?.description.length > 0 ? (
              <div className="dark:text-slate-300">
                {parser(`${content.static_content?.description}`)}
              </div>
            ) : (
              <NoContent />
            )}
          </div>
        </div>
      </section>
      <Subscription2 />
    </div>
  );
};

export default Pages;
