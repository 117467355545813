import React from "react";
import ProductCard from "../../Cards/ProductCard/ProductCard";
import SectionHeading3 from "../../SectionHeadings/SectionHeading3/SectionHeading3";

const DiscountProducts3 = ({data,flash}) => {

  return (
    <section className="">
     {
      data?.length>0 &&  <div className="container">
      <SectionHeading3 title={'Unbeatable Deals'} text={'Shop Discount Products Today!'} />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 lg:gap-4">
        {
          data?.map((product,j)=><ProductCard product={product} key={j} flash={product.is_on_sale===1 ? flash : null}/>)
        }
      </div>
    </div>
     }
    </section>
  );
};

export default DiscountProducts3;
