import React from 'react'
import { useNavigate } from 'react-router-dom';
import ProductCard2 from '../../Cards/ProductCard2/ProductCard2';
import SectionHeading2 from '../../SectionHeadings/SectionHeading2/SectionHeading2';

const NewArrival7 = ({data,flash}) => {
    const navigate = useNavigate()

    const goAllPage = ()=>{navigate("/products")};
    const pageHeading = {
        heading:"Unveiling Excellence",
        paragraph:"The Newest Arrivals in Town",
        action:goAllPage,
        show:data?.length >7 ?true :false
    }
  return (
    <section className='my-8 lg:my-16'>
            <div className="container">
                <SectionHeading2 data={pageHeading}/>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-3">
                    {
                        data?.slice(0,12)?.reverse()?.map((product,i)=><div key={i}>
                        <ProductCard2 product={product} flash={product.is_on_sale===1 ? flash : null}/>
                    </div>)
                    }
                </div>
            </div>
        </section>
  )
}

export default NewArrival7