import React from "react";
import { baseUrl } from "../../../Hooks/Helper";
import SideImage from "../../SideImage/SideImage";
import ProductCard from "../../Cards/ProductCard/ProductCard";
const FeatureProduct = ({ data, site_banners,flash }) => {

 
  const text =
    "Elevate your choices with our new collection. Stay ahead of trends – shop now and embrace the future of fashion!";
  return (
    <section className="py-8 lg:py-16">
      <div className="container">
        <div className="grid grid-cols-12 gap-4 mt-4">
          <SideImage
            image={site_banners?.featured_product_image ? `${baseUrl.img}/${site_banners?.featured_product_image}` : 'https://placehold.co/243x306'}
            title={"Featured Product"}
            text={text}
            background={"bg-white dark:bg-gray-800"}
          />
          <div className="col-span-12 lg:col-span-9">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4  gap-1 lg:gap-4">
              {data?.slice(0, 8).map((product, i) => (
              
                <div key={i}>
                  <ProductCard product={product} flash={product?.is_on_sale===1 ? flash : null}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureProduct;
