import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsCart3, BsSuitHeart } from "react-icons/bs";
import { HiBars3CenterLeft } from "react-icons/hi2";
import { BiGitCompare } from "react-icons/bi";
import useStore from "../../../Hooks/useStore";
import { baseUrl, removeToken } from "../../../Hooks/Helper";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartList } from "../../../Redux/Slices/Cart/cartListSlice";
import { IoCallOutline } from "react-icons/io5";
import { VscLocation } from "react-icons/vsc";
import { TfiEmail } from "react-icons/tfi";
import { fetchAllwishList } from "../../../Redux/Slices/Wishlist/wishlistSlice";
import "./NavBar5.css";
import Button from "../../Button/Button";
const NavBar5 = ({ staticMenus,flash }) => {
  const navigate = useNavigate();
  const {
    setting,
    custommerToken,
    compare,
    secondaryToken,
    customerInfo,
    setViewAllFlashSell,
    setDiscount,
    setFeatureCat,
    setSubCategoryId,
    setCategoryId,
    setMaxPrice,
    setMinPrice,
    refreshAll,
    setRefreshAll,
    setProductName,
    update,
    setUpdate,
    setCustommerToken,
    darkMode,
  } = useStore().data;
  const location = useLocation().pathname;
  const [sticky, setSticky] = useState(false);
  const { logo, dark_logo } = setting;
  const [toggleBar, setToggleBar] = useState(false);
  const dispatch = useDispatch();
  const { cartItems, updates } = useSelector((state) => state?.cartItems);
  useEffect(() => {
    dispatch(fetchCartList({ custommerToken, secondaryToken }));
  }, [dispatch, updates, custommerToken, secondaryToken, update]);

  const { wishlist } = useSelector((state) => state?.wishlist);
  const verified = customerInfo?.user_data?.email_verified_at;
  useEffect(() => {
    if (!custommerToken || !verified) {
      return;
    }
    if (custommerToken) {
      dispatch(fetchAllwishList({ custommerToken }));
    }
  }, [dispatch, custommerToken, update, verified]);

  const navItems = [
    {
      page: "Home",
      route: "/",
    },
    {
      page: "All products",
      route: "/products",
    },
  ];
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      navigate("/products");
    }
  };
  const logOut = async () => {
    const res = await fetch(`${baseUrl.url}/user/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: `bearer ${custommerToken}`,
      },
    });
    if (res.ok) {
      setCustommerToken("");
      setUpdate(!update);
      // setAddresses([]);
    }
    removeToken(["isLogin", "customer_access_token", "remember_me_token"]);
    navigate("/");
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setSticky(true);
      } else if (scrolled <= 200) {
        setSticky(false);
      }
    });
  }, []);

  return (
    <div className={`navBar ${sticky ? "sticky-nav" : ""}`}>
      {!sticky && (
        <div className="py-1">
          <div className="container">
            <div className="block lg:flex items-center justify-center">
              <span className="mx-2 flex items-center justify-center text-gray-700 dark:text-white text-xs">
                <IoCallOutline className="mr-1 text-red-400" /> {setting?.phone}
              </span>
              <span className="mx-2 flex items-center justify-center text-gray-700 dark:text-white text-xs">
                <VscLocation className="mr-1 text-red-400" /> {setting?.address}
              </span>
              <span className="mx-2 flex items-center justify-center text-gray-700 dark:text-white text-xs">
                <TfiEmail className="mr-1 text-red-400" /> {setting?.email}
              </span>
            </div>
          </div>
        </div>
      )}
      <div className={"bg-red-400 py-4 lg:py-2"}>
        <div className="container">
          <div className={"grid grid-cols-12 items-center gap-0 lg:gap-3"}>
            <div className="col-span-1 lg:col-span-2">
              <div className="hidden lg:block" onClick={()=>{navigate('/')}} style={{ height: "30px",  }}>
                {dark_logo || logo ? (
                  <img
                    className=" h-full w-auto"
                    
                    src={
                      darkMode
                        ? `${baseUrl.img}${dark_logo}`
                        : `${baseUrl.img}${logo}`
                    }
                    alt={"website logo"}
                  />
                ) : (
                  <img
                    className="flex"
                    style={{ height: "50px", width: "130px" }}
                    src="https://placehold.co/130x50"
                    alt={"website logo"}
                  />
                )}
              </div>
              <HiBars3CenterLeft
                onClick={() => setToggleBar(!toggleBar)}
                size={25}
                className="sm:block lg:hidden mr-2 text-white cursor-pointer"
              />
            </div>
            <div className="col-span-7 lg:col-span-2">
              <div
                // onKeyDown={handleSearchChange}
                className="flex items-center rounded overflow-hidden"
              >
                <input
                  type="text"
                  placeholder="Search Product..."
                  onChange={(e) => setProductName(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="p-1 bg-transparent text-sm text-black placeholder:text-white border-none focus:outline-none px-2"
                />
              </div>
            </div>
            <div
              className={`col-span-12 lg:col-span-6 2xl:col-span-5 order-2 lg:order-1 overflow-hidden ${
                toggleBar ? "h-fit" : "h-0 lg:h-fit"
              }`}
            >
              <ul
                className={"sm:block md:flex items-center roboto  pt-4 lg:pt-0"}
              >
                {navItems.map((item, i) => (
                  <li key={i} className=" mb-2 lg:mb-0">
                    <button
                      className={`border-0 flex items-center text-white mr-3 text-sm 2xl:text-base ${
                        item.route === location ? "font-bold" : ""
                      }`}
                      onClick={() => {
                        navigate(item.route);
                        setViewAllFlashSell(0);
                        setDiscount(0);
                        setFeatureCat(0);
                        setSubCategoryId(null);
                        setMinPrice(null);
                        setRefreshAll(!refreshAll);
                        setMaxPrice(null);
                        setCategoryId("");
                        setProductName("");
                        setToggleBar(false);
                      }}
                    >
                      {item?.icon}{" "}
                      <span className="mr-2" style={{ whiteSpace: "nowrap" }}>
                        {item.page}
                      </span>
                    </button>
                  </li>
                ))}
                {staticMenus?.map((item, i) => {
                  if (item.static_menu_type.name === "Footer") {
                    return null;
                  }
                  return (
                    <li key={i} className="mr-5 mb-2 lg:mb-0">
                      <button
                        className={`border-0 d-flex align-items-center text-white whitespace-nowrap text-sm 2xl:text-base `}
                        onClick={() => {
                          navigate(`/page/${item.id}`);
                          setToggleBar(false);
                        }}
                      >
                        <span className="lg:ml-2 dark:text-white">
                          {item.menu_name}
                        </span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className={
                "col-span-4 lg:col-span-2 2xl:col-span-3 order-1 lg:order-2"
              }
            >
              <div className="flex items-center gap-3 sm:gap-6 justify-end">
                <div className="col-span-3">
                  <div className="rounded-full flex items-center justify-end">
                    {custommerToken && custommerToken.length > 20 ? (
                      <Link
                        className="relative flex items-center text-white"
                        to={"/wishlist"}
                      >
                        <BsSuitHeart
                          className="text-white"
                          size={18}
                          onClick={() => {
                            setToggleBar(false);
                          }}
                        />
                        <div className="hidden sm:hidden  2xl:block ms-1">
                          <span className="text-xs">Wishlist</span>
                        </div>
                        {wishlist?.length > 0 && (
                          <div className="absolute custom-badge text-white">
                            {wishlist?.length}
                          </div>
                        )}
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="rounded-full flex items-center justify-end">
                    <Link
                      className="relative flex items-center text-white"
                      to={"/compare"}
                      onClick={() => {
                        setToggleBar(false);
                      }}
                    >
                      <BiGitCompare className="text-white" size={21} />
                      <div className="hidden sm:hidden  2xl:block ms-1">
                        <span className="text-xs">Compare</span>
                      </div>
                      {compare?.length > 0 && (
                        <div className="absolute custom-badge text-white">
                          {compare?.length}
                        </div>
                      )}
                    </Link>
                  </div>
                </div>

                <div className="col-span-3 ">
                  <div className="relative">
                    <div className="rounded-full cart relative flex items-center justify-end">
                      <Link
                        className="relative flex items-center text-white"
                        to={"/cart"}
                        onClick={() => {
                          setToggleBar(false);
                        }}
                      >
                        <BsCart3 className="text-white" size={20} />
                        <div className="hidden sm:hidden 2xl:block">
                          <span className="text-xs">Cart</span>
                        </div>
                        {cartItems?.length > 0 && (
                          <div className="absolute custom-badge bg-primary button-text-color">
                            {cartItems?.length}
                          </div>
                        )}
                      </Link>

                      <div className="cart-hover-item bg-white dark:bg-slate-800">
                        {cartItems?.length > 0 ? (
                          <div>
                            {cartItems?.slice(0, 5)?.map((cart, i) => (
                              <div key={i} className="grid grid-cols-12 mb-2">
                                <div className="col-span-3">
                                  <img
                                    className="dark:bg-slate-900 rounded-lg"
                                    style={{ height: "50px", width: "50px" }}
                                    src={`${baseUrl.img}${cart?.product_combination?.product?.thumbnail_image}`}
                                    alt="cart"
                                  />
                                </div>
                                <div className="col-span-9">
                                  <p
                                    style={{ lineHeight: "15px" }}
                                    className="p-0 m-0 fw-bold font-medium dark:text-white"
                                  >
                                    {cart?.product_combination?.product?.name}
                                  </p>
                                  <p className="dark:text-white">
                                    {cart?.product_quantity} X ৳
                                    {flash &&
                                    cart?.product_combination?.product
                                      ?.is_on_sale === 1 ? (
                                      <span>
                                         {(cart?.product_combination
                                          ?.selling_price -
                                          (cart?.product_combination
                                            ?.selling_price * flash.discount) /
                                            100).toFixed(2)}
                                      </span>
                                    ) : (
                                      <span>
                                        {
                                          cart?.product_combination
                                            ?.selling_price
                                        }
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="p-3 text-center">
                            <div style={{ width: "60px" }} className="mx-auto">
                              <img
                                src={require("../../../assets/cart/no-item.png")}
                                alt=""
                                className="w-full"
                              />
                            </div>
                            <p className="dark:text-white">No item in cart</p>
                          </div>
                        )}
                        <div>
                          <Link className="block" to={"/cart"}>
                            <Button
                              classes={"w-full"}
                              size={"md"}
                              title={"View Cart"}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-3">
                  {custommerToken && custommerToken.length > 20 ? (
                    <div className="rounded-full  flex items-center justify-end">
                      <Link
                        className="h-6 w-6 rounded-full overflow-hidden"
                        to={"/profile"}
                        onClick={() => {
                          setToggleBar(false);
                        }}
                      >
                        <img
                          src={`${baseUrl.img}${customerInfo?.user_data?.profile?.image}`}
                          className="w-full h-full"
                          alt=""
                        />
                      </Link>
                      <div className="hidden sm:hidden lg:block ms-2">
                        <button onClick={logOut} className="text-xs text-white">
                          Logout
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button className="py-1 px-2 border rounded-full text-white text-xs">
                      <Link to="/login" className="dark:text-white">
                        Login
                      </Link>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar5;
