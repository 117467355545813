import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { baseUrl } from "../../../Hooks/Helper";

const initialState = {
    customerInfo: {},
    loading: false,
    err: null,
    updates: false,
}
export const fetchAllcustomerInfo = createAsyncThunk("fetchcustomerInfo/customerInfo", async(custommerToken) =>{
    const res = await fetch(`${baseUrl.url}/user/me`, {
        method: 'GET',
        headers: {
            'Authorization':  `bearer ${custommerToken}`
        },
    })
    const resData = await res.json();
    return resData.data
})

const customerInfoSlice = createSlice({
    name: "customerInfo",
    initialState,
         reducers: {
            setUpdate: (state, action)=>{
                state.updates = !state.updates;
            }
        
    },
    extraReducers:(builder =>{
        builder.addCase(fetchAllcustomerInfo.pending, state =>{
            state.loading = true
        }).addCase(fetchAllcustomerInfo.fulfilled, (state, action) =>{
            state.loading = false
            state.customerInfo = action.payload
            state.err = null
        }).addCase(fetchAllcustomerInfo.rejected, (state, action) =>{
            state.loading = false
            state.customerInfo = {}
            state.err = action.payload
        })
    })



})
export  const {setUpdate} = customerInfoSlice.actions;
export default  customerInfoSlice.reducer;